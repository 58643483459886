<template>
  <div
    ref="target"
    class="toast rc-notification"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="row g-0 align-items-center">
      <div :class="`col-auto text-${config.type}`">
        <RcIcon :name="config.type" fontScale="270"></RcIcon>
      </div>
      <div class="col-9 ps-2">
        <div class="rc-notification-msg" v-html="config.message"></div>
      </div>
    </div>

    <button class="btn rc-btn-icon rc-btn-close" @click="toast.hide()">
      <span class="visually-hidden">{{ $t("common.close") }}</span>
      <RcIcon name="closeBold" scale=".65" :title="$t(`common.close`)"></RcIcon>
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Toast } from "bootstrap";
import RcIcon from "@/libvue/components/icons/Base.vue";

const target = ref(null);
const toast = ref(null);
const emit = defineEmits(["hide"]);

defineProps({
  config: {
    type: Object,
    required: true
  }
});

onMounted(() => {
  toast.value = new Toast(target.value);
  toast.value.show();

  toast.value._element.addEventListener("hide.bs.toast", () => {
    emit("hide");
  });
});
</script>

<style lang="scss" scoped>
.rc-notification {
  position: relative;
  max-width: 350px;
  padding: 16px 12px;

  &-msg {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.2;
    color: var(--black);
  }
  .rc-btn-close {
    position: absolute;
    top: 2px;
    right: 2px;
    color: var(--black);
    background-color: var(--bs-white);
    &:hover {
      color: var(--bs-gray);
    }
  }
}
</style>
