import { ifAuthenticated } from "#/stores/auth";
import i18n from "#/locales";

const routes = {
  path: "account",
  alias: "compte",
  name: "user.account.summary",
  component: () => import("#/pages/account/Summary.vue"),
  beforeEnter: ifAuthenticated,
  meta: {
    title: "pageMeta.my_profile.title",
    alias: [null, null, "compte"],
    breadcrumb: {
      back: false,
      crumbs: [
        {
          text: () => i18n.global.t("nav.home"),
          to: { name: "home.user" }
        },
        {
          text: () => i18n.global.t("nav.my_profile"),
          active: true
        }
      ]
    }
  }
};

export default routes;
