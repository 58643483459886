import axios from "axios";
import Config from "#/config";
import i18n from "#/locales";

const instance = axios.create({
  baseURL: Config.apiBase(),
  authRequired: true
});

instance.interceptors.request.use(
  (config) => {
    const token = config?.data?.refresh
      ? localStorage.getItem("refresh-token")
      : localStorage.getItem("user-token");

    if (token && config.authRequired) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers["Content-Language"] = i18n.global.locale;
    config.headers["Accept-Language"] = i18n.global.locale;
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
