<template>
  <!-- Question : Library Public (revoir les conditions) il me semble que ce n'est pas possible de créer un profil si library public.-->
  <!-- Class rc-unread est pour le point rouge-->

  <li id="dd-key" class="nav-item dropdown rc-menu-key rc-unread">
    <button
      class="btn btn-md btn-link rc-btn-icon-dd nav-link dropdown-toggle dropdown-toggle-no-caret"
      aria-expanded="false"
      data-bs-toggle="dropdown"
      :title="$t('nav.access_key.title')"
    >
      <div class="rc-btn-dropdown-my-account">
        <span class="visually-hidden">{{ $t("nav.access_key.title") }}</span>
        <RcIcon name="key" scale="1.53" />
      </div>
    </button>
    <ul class="dropdown-menu dropdown-menu-end" role="presentation">
      <li role="presentation">
        <h6 class="dropdown-header">{{ $t("nav.access_key.title") }}</h6>

        <div class="rc-menu-key--detail">
          <!--Active-->
          <template v-if="keyIsActive">
            <span class="rc-menu-key--intro">
              {{ $t("nav.access_key.status.active") }}
            </span>
            <span class="rc-menu-key--body">
              <ul class="rc-list-arrow mb-4">
                <li>{{ $t("nav.access_key.remote") }}</li>
                <li>{{ $t("nav.access_key.profile") }}</li>
              </ul>
              <router-link
                :to="toMarketCreateProfile"
                class="btn btn-primary d-block w-100 my-3"
                >{{ $t(`nav.create_profile`) }}</router-link
              >
              <span class="text-center mb-2 d-block">
                <a :href="$t('nav.url.help_access_key')">{{
                  $t("common.learn_more")
                }}</a>
              </span>
            </span>
          </template>
          <!--Expired soon-->
          <template v-if="keyExpiredSoon">
            <span class="rc-menu-key--intro">
              {{ $t("nav.access_key.status.expired_soon") }}
              {{ useTimeRelative(expireAt) }}.
            </span>
            <span class="rc-menu-key--body text-center">
              <span class="d-block">{{ $t("nav.access_key.activate") }}</span>

              <span class="d-block">
                <span class="rc-divider-or">
                  <span class="rc-divider-or--line"></span>
                  <span class="rc-divider-or--text">
                    {{ $t("common.or") }}
                  </span>
                  <span class="rc-divider-or--line"></span>
                </span>
                <span class="d-block">
                  {{ $t("nav.access_key.profile") }}

                  <router-link
                    :to="toMarketCreateProfile"
                    class="btn btn-primay mb-3 mt-4 d-block w-100"
                    >{{ $t(`nav.create_profile`) }}</router-link
                  >
                  <span class="text-center mb-2 d-block">
                    <a :href="$t('nav.url.help_access_key')">{{
                      $t("common.learn_more")
                    }}</a>
                  </span>
                </span>
              </span>
            </span>
          </template>
          <!--Expired-->
          <template v-if="keyHasExpired">
            <div class="rc-menu-key--intro">
              <span>{{ $t("nav.access_key.status.expired") }}</span>
            </div>
            <span class="rc-menu-key--body text-center">
              <span class="d-block">{{ $t("nav.access_key.reactivate") }}</span>

              <span class="d-block">
                <span class="rc-divider-or">
                  <span class="rc-divider-or--line"></span>
                  <span class="rc-divider-or--text">
                    {{ $t("common.or") }}
                  </span>
                  <span class="rc-divider-or--line"></span>
                </span>
                <span class="d-block">
                  {{ $t("nav.access_key.login") }}
                  <router-link
                    :to="toLogin"
                    class="btn btn-light mb-3 mt-4 d-block w-100"
                    >{{ $t(`auth.log.in`) }}</router-link
                  >
                  <span class="text-center mb-2 d-block">
                    <a :href="$t('nav.url.help_access_key')">{{
                      $t("common.learn_more")
                    }}</a>
                  </span>
                </span>
              </span>
            </span>
          </template>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
import RcIcon from "@/libvue/components/icons/Base.vue";
import { navMixin } from "#/routes/mixins.js";

import { useTimeRelative } from "@/libvue/composables/formats";

import moment from "moment";

export default {
  name: "RcAccessKey",
  setup() {
    return { useTimeRelative };
  },
  components: {
    RcIcon
  },
  mixins: [navMixin],
  props: {
    expireAt: {
      type: String,
      default: "2021-01-01T00:00:00Z"
    }
  },

  data() {
    return {
      now: moment().format()
    };
  },
  computed: {
    keyIsActive() {
      return !this.keyExpiredSoon && !this.keyHasExpired;
    },
    keyExpiredSoon() {
      return moment(this.expireAt).diff(this.now, "days") <= 7 &&
        !this.keyHasExpired
        ? true
        : false;
    },
    keyHasExpired() {
      return moment(this.expireAt).isBefore(this.now);
    }
  }
};
</script>

<style lang="scss" scoped></style>
