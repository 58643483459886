import axios from "@/libvue/api/axios.js";
import * as constants from "./actions";

function getDefaultState() {
  return {
    ageGroups: {
      data: null,
      status: null,
      errors: null
    },
    curriculas: {
      data: null,
      status: null,
      errors: null
    },
    curricula: {
      data: { courses: [] },
      status: null,
      errors: null
    },
    course: {
      data: { curriculum: { name: null } },
      status: null,
      errors: null
    },
    list: {
      data: { curriculum: { name: null } },
      status: null,
      errors: null
    }
  };
}

const getters = {
  getCurricula: (state) => (uid) => {
    return state.curriculas.data?.find((curri) => curri.uid == uid);
  },
  getTeamProvinceFromCurricula(state) {
    return [...new Set(state.curriculas.data?.map((c) => c.province))];
  },
  getCourse: (state) => (uid) => {
    return state.curricula.data?.courses?.find((course) => course.uid == uid);
  },
  currentCourse: (state) => state.course.data
};

const actions = {
  [constants.GET_CURRICULAS]: ({ commit, getters }, ageGroups) =>
    new Promise((resolve, reject) => {
      const team_uid = getters.getTeamUID;

      const url = ageGroups
        ? `teams/${team_uid}/curricula?${ageGroups}`
        : `teams/${team_uid}/curricula`;

      commit(constants.GET_CURRICULAS);
      axios
        .get(url)
        .then((response) => {
          commit(constants.GET_CURRICULAS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_CURRICULAS_ERROR, error);
          reject(error);
        });
    }),
  [constants.GET_CURRICULA]: ({ commit }, uid) =>
    new Promise((resolve, reject) => {
      commit(constants.GET_CURRICULA);
      axios
        .get(`curricula/${uid}`)
        .then((response) => {
          commit(constants.GET_CURRICULA_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_CURRICULA_ERROR, error);
          reject(error);
        });
    }),
  [constants.GET_COURSE]: ({ commit }, course_uid) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_COURSE);
      axios
        .get(`courses/${course_uid}`)
        .then((response) => {
          commit(constants.GET_COURSE_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_COURSE_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_COURSES]: ({ commit }, curriculaIds) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_COURSES);
      axios({ url: `courses?${curriculaIds}`, authRequired: false })
        .then((response) => {
          commit(constants.GET_COURSES_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_COURSES_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.GET_CURRICULAS]: (state) => {
    state.curriculas.status = "fetching";
    state.curriculas.errors = {};
  },
  [constants.GET_CURRICULAS_SUCCESS]: (state, { data }) => {
    state.curriculas.status = "success";
    state.curriculas.errors = {};
    state.curriculas.data = data.results;
  },
  [constants.GET_CURRICULAS_ERROR]: (state, err) => {
    state.curriculas.status = "error";
    state.curriculas.errors = err;
  },
  [constants.GET_CURRICULA]: (state) => {
    state.curricula.status = "fetching";
    state.curricula.errors = {};
    state.curricula.data.courses = [];
  },
  [constants.GET_CURRICULA_SUCCESS]: (state, { data }) => {
    state.curricula.status = "success";
    state.curricula.errors = {};
    state.curricula.data = {
      ...data,
      courses: [
        ...data.courses
          .map((c) => ({
            ...c,
            html: c.grade
              ? `${c.name} <div class="rc-sub-title">${c.grade}</div>`
              : c.name
          }))
          .sort((a, b) => {
            return a.grade
              ? `${a.name} ${a.grade}`.localeCompare(`${b.name} ${b.grade}`)
              : a.name.localeCompare(b.name);
          })
      ]
    };
  },
  [constants.GET_CURRICULA_ERROR]: (state, err) => {
    state.curricula.status = "error";
    state.curricula.errors = err;
  },
  [constants.GET_COURSE]: (state) => {
    state.course.status = "fetching";
    state.course.errors = {};
  },
  [constants.GET_COURSE_SUCCESS]: (state, { data }) => {
    state.course.status = "success";
    state.course.errors = {};
    state.course.data = data;
  },
  [constants.GET_COURSE_ERROR]: (state, err) => {
    state.course.status = "error";
    state.course.errors = err;
  },
  [constants.GET_COURSES]: (state) => {
    state.list.status = "fetching";
    state.list.errors = {};
  },
  [constants.GET_COURSES_SUCCESS]: (state, { data }) => {
    state.list.status = "success";
    state.list.errors = {};
    state.list.data = data;
    state.list.data.results = state.list.data.results.sort((a, b) => {
      return a.grade
        ? `${a.name} ${a.grade}`.localeCompare(`${b.name} ${b.grade}`)
        : a.name.localeCompare(b.name);
    });
  },
  [constants.GET_COURSES_ERROR]: (state, err) => {
    state.list.status = "error";
    state.list.errors = err;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
