import axios from "@/libvue/api/axios.js";
import * as constants from "./actions";

function getDefaultState() {
  return {
    list: {
      data: { results: [] },
      status: null,
      error: null
    },
    detail: {
      data: null,
      status: null,
      error: null
    }
  };
}

const actions = {
  [constants.GET_SUBSCRIBERS]: ({ commit }, params) =>
    new Promise((resolve, reject) => {
      commit(constants.GET_SUBSCRIBERS);

      axios({ url: "subscribers", params, authRequired: false })
        .then((response) => {
          commit(constants.GET_SUBSCRIBERS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_SUBSCRIBERS_ERROR, error);
          reject(error);
        });
    })
};

const mutations = {
  [constants.GET_SUBSCRIBERS]: (state) => {
    state.list.status = "fetching";
    state.list.error = null;
  },
  [constants.GET_SUBSCRIBERS_SUCCESS]: (state, { data }) => {
    state.list.status = "success";
    state.list.data = data;
    state.list.error = null;
  },
  [constants.GET_SUBSCRIBERS_ERROR]: (state, err) => {
    state.list.status = "error";
    state.list.data = null;
    state.list.error = err.response ? err.response.data : err;
  },
  [constants.SET_SUBSCRIBER]: (state, data) => {
    state.detail.status = "success";
    state.detail.errors = {};
    state.detail.data = data;
  },

  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState,
  actions,
  mutations
};
