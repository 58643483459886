import { each } from './each.js';

export const serialize = function (data) {
  var query = [];
  each(data, function (value, key) {
    if ('string' !== typeof value) {
      value = JSON.stringify(value);
    }
    query.push(key + '=' + encodeURIComponent(value));
  });
  return query.join('&');
};
