import { ifAuthenticated, customizeHome } from "#/stores/auth";
import i18n from "#/locales";
import store from "#/stores";

const routes = [
  {
    path: "my-home",
    alias: "mon-accueil",
    name: "home.perso",
    beforeEnter: customizeHome,
    component: () => import("#/pages/catalog/HomeUser.vue"),
    meta: {
      title: "pageMeta.home.my_title",
      alias: [null, null, "mon-accueil"],
      class: "rc-page-home-user"
    }
  },
  {
    path: "guest-home",
    alias: "accueil-invite",
    name: "home.guest",
    beforeEnter: customizeHome,
    component: () => import("#/pages/catalog/HomeUser.vue"),
    meta: {
      title: "pageMeta.home.guest_title",
      alias: [null, null, "accueil-invite"],
      class: "rc-page-home-user"
    }
  },
  {
    path: "home",
    alias: "accueil",
    name: "home.user",
    beforeEnter: customizeHome,
    component: () => import("#/pages/catalog/HomeUser.vue"),
    meta: {
      title: "pageMeta.home.title",
      alias: [null, null, "accueil"],
      class: "rc-page-home-user"
    }
  },
  {
    path: "home/preview/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    alias:
      "accueil/apercu/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "home.user.preview",
    beforeEnter: ifAuthenticated,
    component: () => import("#/pages/catalog/HomeUser.vue"),
    meta: {
      title: "pageMeta.home.title",
      alias: [null, null, "accueil", "apercu", null],
      class: "rc-page-home-user"
    }
  },
  {
    path: "age-groups",
    alias: "groupes-age",
    name: "age.group.list",
    beforeEnter: ifAuthenticated,
    component: () => import("#/pages/catalog/AgeGroupList.vue"),
    meta: {
      title: "pageMeta.ageGroups.title",
      alias: [null, null, "groupes-age"],
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          }
        ]
      }
    }
  },
  {
    path: "age-group",
    alias: "groupe-age",
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: ":uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
        name: "age.group.detail",
        component: () => import("#/pages/catalog/ItemList.vue"),
        meta: {
          alias: [null, null, "groupe-age", null],
          breadcrumb: {
            back: false,
            crumbs: [
              {
                text: () => i18n.global.t("nav.home"),
                to: { name: "home.user" }
              },

              {
                text: (route) => route.params.uid,
                active: true
              }
            ]
          },
          filter: { facet: "age_groups", key: "uid" }
        }
      }
    ]
  },
  {
    path: "catalog",
    name: "catalog.item.list",
    alias: "catalogue",
    beforeEnter: ifAuthenticated,
    component: () => import("#/pages/catalog/ItemList.vue"),
    meta: {
      alias: [null, null, "catalogue"],
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => "latest",
            active: true
          }
        ]
      }
    }
  },
  {
    path: "catalog/search",
    name: "catalog.item.search",
    alias: "catalogue/recherche",
    beforeEnter: ifAuthenticated,
    component: () => import("#/pages/catalog/ItemList.vue"),
    props: (route) => ({ query: route.query.q }),
    meta: {
      alias: [null, null, "catalogue", "recherche"],
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => "search",
            active: true
          }
        ]
      }
    }
  },
  {
    path: "catalog/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    alias:
      "catalogue/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.item.detail",
    component: () => import("#/pages/catalog/ItemDetail.vue"),
    props: (route) => ({ in: route.query.in, out: route.query.out }),

    meta: {
      alias: [null, null, "catalogue", null],
      breadcrumb: {
        back: true,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => {
              if (store.getters.getItemDetail.group_item?.group_type)
                return i18n.global.tc(
                  "catalog.group_type." +
                    store.getters.getItemDetail.group_item?.group_type +
                    ".title",
                  2
                );
            },
            fnTo: () => {
              return {
                name: "catalog.group.type",
                params: {
                  type: store.getters.getItemDetail.group_item?.group_type
                }
              };
            }
          },
          {
            text: () => {
              return store.getters.getItemDetail.group_item?.name;
            },

            fnTo: () => {
              return {
                name: "catalog.group.slug",
                params: {
                  type: store.getters.getItemDetail.group_item?.group_type,
                  slug: store.getters.getItemDetail.group_item?.slug
                }
              };
            }
          },
          {
            text: (route) => route.params.uid,
            active: true
          }
        ]
      },
      redirectToLang: "home.user",
      redirectAfterLogin: true
    }
  },
  {
    path: "catalog/embed/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    alias:
      "catalogue/embed/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.item.embed",
    component: () => import("#/pages/catalog/ItemEmbed.vue"),
    props: (route) => ({ in: route.query.in, out: route.query.out }),

    meta: {
      alias: [null, null, "catalogue", "embed", null],
      layout: "embed",
      redirectToLang: "home.user",
      redirectAfterLogin: true
    }
  },
  {
    path: "catalog/categories",
    name: "catalog.categories.list",
    alias: "catalogue/categories",
    beforeEnter: ifAuthenticated,
    component: () => import("#/pages/catalog/CategoriesList.vue"),
    meta: {
      alias: [null, null, "catalogue", "categories"],
      title: "pageMeta.categories.title",
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => "categories",
            active: true
          }
        ]
      }
    }
  },
  {
    path: "catalog/categories/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    alias:
      "catalogue/categories/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.category.detail",
    component: () => import("#/pages/catalog/ItemList.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      alias: [null, null, "catalogue", "categories", null],
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => i18n.global.t("common.categories"),
            to: { name: "catalog.categories.list" }
          },
          {
            text: (route) => route.params.uid,
            active: true
          }
        ]
      },
      filter: { facet: "categories", key: "uid" }
    }
  },
  {
    path: "catalog/curricula",
    alias: "catalogue/curricula",
    name: "catalog.curricula.list",
    beforeEnter: ifAuthenticated,
    component: () => import("#/pages/catalog/CurriculaList.vue"),

    meta: {
      alias: [null, null, "catalogue", "curricula"],
      title: "pageMeta.curricula.title",
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => "Curricula",
            active: true
          }
        ]
      }
    }
  },
  {
    path: "catalog/curricula/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    alias:
      "catalogue/curricula/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.curricula.detail",
    component: () => import("#/pages/catalog/ItemList.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      alias: [null, null, "catalogue", "curricula", null],
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => i18n.global.t("common.curriculum"),
            to: { name: "catalog.curricula.list" }
          },
          {
            text: (route) => route.params.uid,
            active: true
          }
        ]
      },
      filter: { facet: "curricula", key: "uid" },
      redirectToLang: "catalog.curricula.list"
    }
  },
  {
    path: "catalog/channels",
    name: "catalog.channels.list",
    alias: "catalogue/chaines",
    beforeEnter: ifAuthenticated,
    component: () => import("#/pages/catalog/ChannelsList.vue"),
    meta: {
      alias: [null, null, "catalogue", "chaines"],
      title: "pageMeta.channels.title",
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => i18n.global.t("common.channels"),
            active: true
          }
        ]
      }
    }
  },
  {
    path: "catalog/channels/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    alias:
      "catalogue/chaines/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.channels.detail",
    beforeEnter: ifAuthenticated,
    component: () => import("#/pages/catalog/ItemList.vue"),
    meta: {
      alias: [null, null, "catalogue", "chaines", null],
      class: "rc-channel",
      breadcrumb: {
        back: false,
        class: "dark",
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => i18n.global.t("common.channels"),
            to: { name: "catalog.channels.list" }
          },
          {
            text: (route) => route.params.uid,
            active: true
          }
        ]
      },
      filter: { facet: "owner", key: "uid" },
      redirectToLang: "catalog.channels.list"
    }
  },
  {
    path: "catalog/subscribe-channels/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    alias:
      "catalogue/abonnement-chaines/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.channels.subscribe.detail",
    component: () => import("#/pages/catalog/SubscribeChannelItemList.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      alias: [null, null, "catalogue", "abonnement-chaines", null],
      class: "rc-channel",
      breadcrumb: {
        back: false,
        class: "dark",
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => i18n.global.t("common.channels"),
            to: { name: "catalog.channels.list" }
          },
          {
            text: (route) => route.params.uid,
            active: true
          }
        ]
      },
      redirectToLang: "catalog.channels.list"
    }
  },
  {
    path: "catalog/channels/request",
    name: "catalog.channels.request",
    alias: "catalogue/chaines/requete",
    beforeEnter: ifAuthenticated,
    component: () => import("#/pages/catalog/ChannelRequest.vue"),
    meta: {
      alias: [null, null, "catalogue", "chaines", "requete"],
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          }
        ]
      }
    }
  },

  //Group items (type)
  {
    path: "catalog/group/:type",
    alias: "catalogue/groupe/:type",
    name: "catalog.group.type",
    beforeEnter: ifAuthenticated,
    component: () => import("#/pages/catalog/GroupTypeList.vue"),
    meta: {
      alias: [null, null, "catalogue", "groupe", null],

      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => "Groupe type",
            active: true
          }
        ]
      }
    }
  },
  //Group items (slug)
  {
    path: "catalog/group/:type/:slug",
    alias: "catalogue/groupe/:type/:slug",
    name: "catalog.group.slug",
    beforeEnter: ifAuthenticated,
    component: () => import("#/pages/catalog/GroupSlugList.vue"),
    meta: {
      alias: [null, null, "catalogue", "groupe", null, null],
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: (route) =>
              i18n.global.tc(
                "catalog.group_type." + route.params.type + ".title",
                2
              ),
            fnTo: (route) => {
              return {
                name: "catalog.group.type",
                params: { type: route.params.type }
              };
            }
          },
          {
            text: (route) => route.params.slug,
            active: true
          }
        ]
      },

      fnRedirectToLang: (route) => {
        return {
          name: "catalog.group.type",
          params: {
            locale: i18n.global.locale,
            type: route.params.type
          }
        };
      }
    }
  },
  {
    path: "catalog/themes",
    name: "catalog.themes.list",
    alias: "catalogue/themes",
    beforeEnter: ifAuthenticated,
    component: () => import("#/pages/catalog/ItemList.vue"),
    meta: {
      alias: [null, null, "catalogue", "themes", null],
      title: "pageMeta.themes.title",
      class: "rc-theme",
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => i18n.global.t("common.themes"),
            active: true
          }
        ]
      }
    }
  },
  {
    path: "catalog/themes/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    alias:
      "catalogue/themes/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.themes.detail",
    beforeEnter: ifAuthenticated,
    component: () => import("#/pages/catalog/ItemList.vue"),

    meta: {
      alias: [null, null, "catalogue", "themes", null],
      breadcrumb: {
        back: false,
        class: "dark",
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => i18n.global.t("common.themes"),
            to: { name: "catalog.themes.list" }
          },
          {
            text: (route) => route.params.uid,
            active: true
          }
        ]
      },
      redirectToLang: "catalog.themes.list"
    }
  },
  {
    path: "catalog/featured/:slug",
    alias: "catalogue/mis-en-vedette/:slug",
    name: "catalog.lineup.detail",
    component: () => import("#/pages/catalog/ItemList.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      alias: [null, null, "catalogue", "mis-en-vedette", null],
      breadcrumb: {
        back: false,
        class: "dark",
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: (route) => route.params.uid,
            active: true
          }
        ]
      },
      redirectToLang: "home.user"
    }
  },
  {
    path: "catalog/show/:show",
    alias: "catalogue/emission/:show",
    name: "catalog.show.detail",
    component: () => import("#/pages/catalog/ItemList.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      alias: [null, null, "catalogue", "emission", null],
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: (route) => route.params.show,
            active: true
          }
        ]
      },
      filter: { facet: "show", key: "show" }
    }
  },
  {
    path: "catalog/subject/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:subject",
    alias:
      "catalogue/sujet/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:subject",
    name: "catalog.subject.detail",
    component: () => import("#/pages/catalog/ItemList.vue"),
    beforeEnter: ifAuthenticated,

    meta: {
      alias: [null, null, "catalogue", "sujet", null, null],
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: (route) => route.params.subject,
            active: true
          }
        ]
      },
      filter: { facet: "subjects", key: "subject" },
      redirectToLang: "home.user"
    }
  },
  {
    path: "catalog/courses/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    alias:
      "catalogue/cours/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.courses.detail",
    component: () => import("#/pages/catalog/ItemList.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      alias: [null, null, "catalogue", "cours", null],
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => i18n.global.t("common.curriculum"),
            to: { name: "catalog.curricula.list" }
          },
          {
            text: () => store.getters.currentCourse.curriculum.name,
            to: {
              name: "catalog.curricula.detail",
              params: {}
            }
          },
          {
            text: (route) => route.params.uid,
            active: true
          }
        ]
      },
      filter: { facet: "courses", key: "uid" },
      redirectToLang: "home.user"
    }
  },
  {
    path: "catalog/our-suggestions",
    alias: "catalogue/choix-de-lequipe",
    name: "catalog.featured.detail",
    component: () => import("#/pages/catalog/ItemList.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      alias: [null, null, "catalogue", "choix-de-lequipe", null],
      title: "pageMeta.featured.title",
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          }
        ]
      },
      filter: { facet: "featured", key: "featured" }
    }
  },
  {
    path: "catalog/recommended-for-you",
    alias: "catalogue/recommande-pour-vous",
    name: "catalog.recommended.detail",
    component: () => import("#/pages/catalog/ItemList.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      alias: [null, null, "catalogue", "recommande-pour-vous", null],
      title: "pageMeta.recommended.title",
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          }
        ]
      }
    }
  }
];

export default routes;
