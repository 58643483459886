import { ifAuthenticated } from '#/stores/auth'
import i18n from '#/locales'

const routes = [
  {
    path: 'bookmarks',
    alias: 'favoris',
    name: 'user.favorites',
    component: () => import('#/pages/favorites/List.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      alias: [null, null, 'favoris'],
      class: '',
      breadcrumb: {
        title: 'pageMeta.favorites.title',
        back: false,
        class: '',
        crumbs: [
          {
            text: () => i18n.global.t('nav.home'),
            to: { name: 'home.user' }
          },
          {
            text: () => i18n.global.t('catalog.favorites.my'),
            active: true
          }
        ]
      }
    }
  }
]
export default routes
