import { createI18n } from "vue-i18n";

import * as siteEn from "./en.json";
import * as siteFr from "./fr.json";

const messages = { en: siteEn, fr: siteFr };

export const initialLocale = () => {
  let initialLang = "en";
  const defaultLang = "en";

  let prefLang = localStorage.getItem("pref-lang");

  // swith old storage value
  if (prefLang == "en-US") {
    localStorage.setItem("pref-lang", "en");
    prefLang = "en";
  } else if (prefLang == "fr-CA") {
    localStorage.setItem("pref-lang", "fr");
    prefLang = "fr";
  }

  const navLang = navigator.language;

  if (prefLang) {
    initialLang = prefLang;
  } else {
    initialLang = navLang.includes("fr") ? "fr" : defaultLang;
  }

  document.documentElement.setAttribute("lang", initialLang);

  return initialLang;
};

export const i18n = createI18n({
  warnHtmlMessage: false,
  warnHtmlInMessage: "off",
  allowComposition: true,
  locale: import.meta.env.VUE_APP_I18N_LOCALE || initialLocale(),
  fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages
});

export default i18n;
