import axios from "@/libvue/api/axios.js";
import * as constants from "./actions";

function getDefaultState() {
  return {
    list: {
      data: { results: [] },
      status: "",
      errors: {}
    },
    post: {
      data: {},
      status: "",
      errors: {}
    }
  };
}

const getters = {};

const actions = {
  [constants.GET_CATALOG_EXPORTS]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;

      commit(constants.GET_CATALOG_EXPORTS);
      axios
        .get(`users/${user_uid}/catalog-exports`)
        .then((response) => {
          commit(constants.GET_CATALOG_EXPORTS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_CATALOG_EXPORTS_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.POST_CATALOG_EXPORT]: (
    { commit, dispatch, rootState },
    payload
  ) => {
    return new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;

      commit(constants.POST_CATALOG_EXPORT);

      axios
        .post(`users/${user_uid}/catalog-exports/create`, payload)
        .then((response) => {
          commit(constants.POST_CATALOG_EXPORT_SUCCESS, response);
          dispatch(constants.GET_CATALOG_EXPORTS);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.POST_CATALOG_EXPORT_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.CATALOG_EXPORTS_APPEND_NEXT]: ({ commit, rootState }, params) => {
    return new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;

      commit(constants.CATALOG_EXPORTS_APPEND_NEXT);

      axios
        .get(`users/${user_uid}/catalog-exports`, { params })
        .then((response) => {
          commit(constants.CATALOG_EXPORTS_APPEND_NEXT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.CATALOG_EXPORTS_APPEND_NEXT_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.GET_CATALOG_EXPORTS]: (state) => {
    state.list.status = "fetching";
    state.list.errors = {};
  },
  [constants.GET_CATALOG_EXPORTS_SUCCESS]: (state, { data }) => {
    state.list.status = "success";
    state.list.errors = {};
    state.list.data = data;
  },
  [constants.GET_CATALOG_EXPORTS_ERROR]: (state, err) => {
    state.list.status = "error";
    state.list.errors = err;
  },
  [constants.POST_CATALOG_EXPORT]: (state) => {
    state.post.status = "fetching";
    state.post.errors = {};
  },
  [constants.POST_CATALOG_EXPORT_SUCCESS]: (state, { data }) => {
    state.post.status = "success";
    state.post.errors = {};
    state.post.data = data;
  },
  [constants.POST_CATALOG_EXPORT_ERROR]: (state, err) => {
    state.post.status = "error";
    state.post.errors = err;
  },
  [constants.CATALOG_EXPORTS_APPEND_NEXT]: (state) => {
    state.list.status = "fetching";
    state.list.errors = {};
  },
  [constants.CATALOG_EXPORTS_APPEND_NEXT_SUCCESS]: (state, { data }) => {
    state.list.status = "success";
    state.list.errors = {};
    state.list.data.results = [...state.list.data.results, ...data.results];
    state.list.data.count = data.count;
    state.list.data.next = data.next;
    state.list.data.current = data.current;
    state.list.data.next_page_number = data.next_page_number;
  },
  [constants.CATALOG_EXPORTS_APPEND_NEXT_ERROR]: (state, err) => {
    state.list.status = "error";
    state.list.errors = err;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
