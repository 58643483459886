<template>
  <!-- Skinny Footer : Shared playlist and Onboarding -->
  <footer role="contentinfo">
    <RcSiteBannerCTA />
    <div class="rc-footer rc-skinny-footer">
      <div class="container">
        <h2 class="visually-hidden">{{ $t('a11y.nav.footer') }}</h2>
        <div class="rc-footer-bottom">
          <div class="row align-content-between">
            <div class="col-lg-12 col-xl-6">
              <ul>
                <li>
                  <router-link :to="toAbout">{{ $t('nav.about_brand') }}</router-link>
                </li>
                <li>
                  <a :href="$t('nav.url.help_desk')">{{ $t('nav.help_desk') }}</a>
                </li>
                <li>
                  <a :href="$t('nav.url.privacy_policy')">{{ $t('nav.privacy_policy') }}</a>
                </li>
                <li>
                  <a :href="$t('nav.url.terms_of_use')">{{ $t('nav.terms_of_use') }}</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-12 col-xl-6">
              {{ $t('common.all_rights_reserved') }}
              <span>&copy; CBC/Société Radio‑Canada {{ year }}.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { navMixin } from '#/routes/mixins'
import RcSiteBannerCTA from '#/layout/components/SiteBannerCTA.vue'

export default {
  name: 'RcSiteFooterSkinny',
  components: {
    RcSiteBannerCTA
  },
  mixins: [navMixin],
  computed: {
    year() {
      const today = new Date()
      return today.getFullYear()
    }
  }
}
</script>
