import i18n from "#/locales";
import { toCorrectLogin } from "#/stores/auth";

const routes = [
  {
    path: "login",
    alias: "connexion",
    component: () => import("#/pages/auth/Login.vue"),
    beforeEnter: toCorrectLogin,
    props: true,
    name: "auth.login",
    meta: {
      title: "pageMeta.login.title",
      alias: [null, null, "connexion"],
      layout: "skinny"
    }
  },
  {
    path: "login/pin",
    alias: "connexion/numero-identification",
    component: () => import("#/pages/auth/LoginPin.vue"),
    name: "auth.login.pin",
    meta: {
      title: "pageMeta.pin.title",
      alias: [null, null, "connexion", "numero-identification"],
      layout: "skinny"
    }
  },
  {
    path: "access-denied",
    name: "403",
    alias: "acces-interdit",
    component: () => import("#/pages/auth/AccessDenied.vue"),
    meta: {
      alias: [null, null, "acces-interdit"],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.global.t("nav.home"),
            to: { name: "home.user" }
          },
          {
            text: () => "access denied",
            active: true
          }
        ]
      }
    }
  }
];

export default routes;
