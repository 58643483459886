import i18n from "#/locales";
import store from "#/stores";
import Config from "#/config";

// apps pages
import MarketRoutes from "./marketRoutes";
import AuthRoutes from "./authRoutes";
import UserRoutes from "./userRoutes";
import OnboardingRoutes from "./onboardRoutes";
import CatalogRoutes from "./catalogRoutes";
import FavoritesRoutes from "./favoritesRoutes";
import PlaylistsRoutes from "./playlistsRoutes";
import SubscribersRoutes from "./subscribersRoutes";
import DashboardRoutes from "./dashboardRoutes";

import {
  AUTH_REFRESH,
  AUTH_VERIFY,
  AUTH_GET_SESSION_TOKEN,
  AUTH_GET_IP_TOKEN,
  AUTH_LOGOUT
} from "@/libvue/apps/auth/actions";

import { redirectByRole } from "#/stores/auth";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "homeNoLang",
      redirect: () => ({
        name: "home",
        params: { locale: i18n.global.locale }
      })
    },
    {
      path: "/:locale(en|fr)",
      children: [
        ...AuthRoutes,
        ...CatalogRoutes,
        ...MarketRoutes,
        ...OnboardingRoutes,
        ...FavoritesRoutes,
        ...PlaylistsRoutes,
        ...SubscribersRoutes,
        ...DashboardRoutes,
        UserRoutes
      ]
    },
    {
      path: "/:pathMatch(.*)*",
      name: "404",
      redirect: () => ({
        name: "not.found",
        params: { locale: i18n.global.locale }
      })
    }
  ],

  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const pos = savedPosition ? savedPosition : { top: 0 };
        resolve(pos);
      }, 400);
    });
  }
});

// Use this to load data upon refresh or login
router.beforeEach((to, from, next) => {
  if (!to.meta?.layout) {
    to.meta.layout = "default";
  }

  // adding app name for gtm purpose
  to.meta.gtmAdditionalEventData = {
    app: {
      name: Config.decodedHost
    }
  };
  // set locale from route param
  if (to.params.locale) {
    localStorage.setItem("pref-lang", to.params.locale);
    i18n.global.locale = to.params.locale;
  }
  // set document title from route param
  if (to.meta.title) {
    document.title = i18n.global.t(`${to.meta.title}`);
  } else {
    document.title = i18n.global.t("pageMeta.default.title");
  }
  let promise = "";
  // refresh token if it exists in local storage
  if (
    !store.getters.getProfile.user_uid &&
    localStorage.getItem("refresh-token")
  ) {
    promise = AUTH_REFRESH;
  }
  // try session token
  else if (
    !from.name &&
    !store.getters.getProfile.user_uid &&
    !localStorage.getItem("refresh-token")
  ) {
    promise = AUTH_GET_SESSION_TOKEN;
  }

  promise
    ? store
        .dispatch(promise)
        .then((res) => {
          const localData = res.data ? res.data : res;
          if (
            store.getters.getProfile.agreement_version !=
            Config.agreement.version
          ) {
            return store.dispatch(AUTH_LOGOUT);
          }
          if (
            !localData.auth.team_uid &&
            redirectByRole(localData.auth).name !== to.name
          ) {
            const redirect = {
              ...redirectByRole(localData.auth),
              params: { locale: i18n.global.locale },
              query: { redirect: to.fullPath }
            };
            next(redirect);
          }

          next();
        })
        .catch(() => {
          store
            .dispatch(AUTH_GET_IP_TOKEN)
            .then(() => next())
            .catch(() => {
              if (localStorage.getItem("user-token")) {
                store
                  .dispatch(AUTH_VERIFY)
                  .then(() => next())
                  .catch(() => next());
              } else next();
            });
        })
    : next();
});

router.afterEach((to) => {
  /*
  Translate current route url by getting the routes meta.alias if it exists.
  */

  if (i18n.global.locale == "fr" && to.meta.alias) {
    let result = [];
    const params = to.fullPath.split(to.path).pop();
    const pathSplit = to.path.split("/");

    to.meta.alias.forEach((term, index) => {
      if (term) {
        result.push(term);
      } else {
        result.push(pathSplit[index]);
      }
    });

    let resultPath = result.join("/");

    if (params) {
      resultPath = resultPath + params;
    }

    if (resultPath !== to.fullPath) {
      history.replaceState({}, null, resultPath);
    }
  }
});

export default router;
