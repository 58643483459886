// import app from "#/app";
import store from "#/stores";
import Config from "#/config";
import i18n from "#/locales";

export function ifNotAuthenticated(to, from, next) {
  if (!store.getters.isAuthenticated || !store.getters.hasAcceptedAgreements) {
    next();
    return;
  }
  const redirect = {
    name: "home",
    params: { locale: i18n.global.locale }
  };
  next(redirect);
}

export function ifAuthenticated(to, from, next) {
  if (store.getters.isAuthenticated) {
    return next();
  } else {
    next({
      name: "auth.login",
      params: { locale: i18n.global.locale },
      query: { redirect: to.fullPath }
    });
  }
}

export function customizeHome(to, from, next) {
  if (store.getters.isAuthenticated) {
    switch (to.name) {
      case "home.perso":
        store.getters.canRecommend || store.getters.canRecommendCategories
          ? next()
          : next({ name: "home.user", params: { locale: i18n.global.locale } });
        break;
      case "home.user":
        store.getters.canRecommend || store.getters.canRecommendCategories
          ? next({ name: "home.perso", params: { locale: i18n.global.locale } })
          : store.getters.roleIsGuest
            ? next({
                name: "home.guest",
                params: { locale: i18n.global.locale }
              })
            : next();
        break;
      case "home.guest":
        store.getters.roleIsGuest
          ? next()
          : next({ name: "home.user", params: { locale: i18n.global.locale } });
        break;
    }
  } else {
    return next({
      name: "auth.login",
      params: { locale: i18n.global.locale },
      query: { redirect: to.fullPath }
    });
  }
}

export function ifUserKnown(to, from, next) {
  if (store.getters.isAuthenticated) {
    const redirect = {
      ...redirectByRole(store.state.auth.user),
      params: { locale: i18n.global.locale }
    };
    // app.$Progress.finish();
    next(redirect);
  } else {
    next();
  }
}

export function toCorrectLogin(to, from, next) {
  const acceptedHostNames = [
    "localhost",
    "qa.curio.ca",
    "uat.curio.ca",
    "beta.curio.ca",
    "curio.dev.camr.io",
    "curio-dev.non-prod.camr.io",
    "curio-qa.non-prod.camr.io",
    Config.decodedHost
  ];

  if (
    store.getters.getBaseUrl &&
    !acceptedHostNames.includes(window.location.hostname)
  ) {
    window.location = `https://${Config.decodedHost}/${i18n.global.locale}/login`;
  }
  next();
}

export function redirectByRole(auth) {
  const redirect = Config.auth.redirects.afterLoginSuccess[auth.role]
    ? Config.auth.redirects.afterLoginSuccess[auth.role]
    : Config.auth.redirects.afterLoginSuccess["default"];

  return redirect;
}

export function ifUserIsAdmin(to, from, next) {
  if (store.getters.isAuthenticated && store.getters.roleIsAdmin) {
    return next();
  }
  next({
    name: "403",
    params: { locale: i18n.global.locale }
  });
}

export function ifUserCanViewReports(to, from, next) {
  if (
    store.getters.isAuthenticated &&
    (store.getters.roleIsAdmin || store.getters.roleIsLibraryUser)
  ) {
    return next();
  }
  next({
    name: "403",
    params: { locale: i18n.global.locale }
  });
}
