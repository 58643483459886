import axios from "@/libvue/api/axios.js";
import * as constants from "./actions";

function getDefaultState() {
  return {
    list: {
      data: { results: [] },
      status: "",
      errors: {}
    }
  };
}

const getters = {};

const actions = {
  [constants.GET_ORG_REPORTS]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      const organisation_uid = rootState.auth.user.organisation_uid;

      commit(constants.GET_ORG_REPORTS);

      axios
        .get(`organisations/${organisation_uid}/reports`)
        .then((response) => {
          commit(constants.GET_ORG_REPORTS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_ORG_REPORTS_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.ORG_REPORTS_APPEND_NEXT]: ({ commit, rootState }, params) => {
    return new Promise((resolve, reject) => {
      const organisation_uid = rootState.auth.user.organisation_uid;

      commit(constants.ORG_REPORTS_APPEND_NEXT);

      axios
        .get(`organisations/${organisation_uid}/reports`, { params })
        .then((response) => {
          commit(constants.ORG_REPORTS_APPEND_NEXT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.ORG_REPORTS_APPEND_NEXT_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.GET_ORG_REPORTS]: (state) => {
    state.list.status = "fetching";
    state.list.errors = {};
  },
  [constants.GET_ORG_REPORTS_SUCCESS]: (state, { data }) => {
    state.list.status = "success";
    state.list.errors = {};
    state.list.data = data;
  },
  [constants.GET_ORG_REPORTS_ERROR]: (state, err) => {
    state.list.status = "error";
    state.list.errors = err;
  },

  [constants.ORG_REPORTS_APPEND_NEXT]: (state) => {
    state.list.status = "fetching";
    state.list.errors = {};
  },
  [constants.ORG_REPORTS_APPEND_NEXT_SUCCESS]: (state, { data }) => {
    state.list.status = "success";
    state.list.errors = {};
    state.list.data.results = [...state.list.data.results, ...data.results];
    state.list.data.count = data.count;
    state.list.data.next = data.next;
    state.list.data.current = data.current;
    state.list.data.next_page_number = data.next_page_number;
  },
  [constants.ORG_REPORTS_APPEND_NEXT_ERROR]: (state, err) => {
    state.list.status = "error";
    state.list.errors = err;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
