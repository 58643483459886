<template>
  <button class="btn rc-btn-lang locale-changer btn-link" @click="to">
    {{ langs[$i18n.locale]["display"] }}
  </button>
</template>

<script>
export default {
  name: "RcLocaleSwitch",
  data() {
    return {
      langs: {
        fr: { lang: "en", display: "English" },
        en: { lang: "fr", display: "Français" }
      }
    };
  },
  methods: {
    to() {
      localStorage.setItem("pref-lang", this.langs[this.$i18n.locale]["lang"]);
      this.$i18n.locale = this.langs[this.$i18n.locale]["lang"];
      document.documentElement.setAttribute("lang", this.$i18n.locale);

      if (this.$route.meta.title) {
        document.title = this.$t(`${this.$route.meta.title}`);
      }

      const newRoute = {
        ...this.$route,
        params: {
          ...this.$route.params,
          locale: this.$i18n.locale
        }
      };

      if (this.$route.meta.redirectToLang) {
        this.$router.replace({
          name: this.$route.meta.redirectToLang,
          params: { locale: this.$i18n.locale }
        });
      } else if (this.$route.meta.fnRedirectToLang) {
        this.$router.replace(this.$route.meta.fnRedirectToLang(this.$route));
      } else {
        this.$router.replace(newRoute);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.rc-btn-lang {
  padding: 0;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
</style>
