export const GET_CATALOG_EXPORTS = "GET_CATALOG_EXPORTS";
export const GET_CATALOG_EXPORTS_SUCCESS = "GET_CATALOG_EXPORTS_SUCCESS";
export const GET_CATALOG_EXPORTS_ERROR = "GET_CATALOG_EXPORTS_ERROR";

export const POST_CATALOG_EXPORT = "POST_CATALOG_EXPORT";
export const POST_CATALOG_EXPORT_SUCCESS = "POST_CATALOG_EXPORT_SUCCESS";
export const POST_CATALOG_EXPORT_ERROR = "POST_CATALOG_EXPORT_ERROR";

export const CATALOG_EXPORTS_APPEND_NEXT = "CATALOG_EXPORTS_APPEND_NEXT";
export const CATALOG_EXPORTS_APPEND_NEXT_SUCCESS =
  "CATALOG_EXPORTS_APPEND_NEXT_SUCCESS";
export const CATALOG_EXPORTS_APPEND_NEXT_ERROR =
  "CATALOG_EXPORTS_APPEND_NEXT_ERROR";
