<template>
  <div
    v-if="hasBannerCTA && (roleIsGuest || !isAuthenticated)"
    :class="cssCTA ? cssCTA : ''"
  >
    <transition name="fade" mode="out-in">
      <div v-if="typeCTA == 'subscription'">
        <div class="rc-banner-cta bg-teal">
          <div class="container">
            <div class="row flex-column-reverse flex-md-row">
              <div class="col-md-6 rc-col-max-width">
                <h2
                  v-if="routeName == 'market.channels'"
                  v-html="$t('footer.subscription.banner.title_channels')"
                ></h2>
                <h2 v-else v-html="$t('footer.subscription.banner.title')"></h2>

                <a
                  :href="$t('nav.url.contact_us')"
                  class="btn btn-dark btn-xl mt-4"
                  @click.prevent=""
                  data-bs-toggle="modal"
                  data-bs-target="#externalLinkModal"
                  >{{ $t("footer.subscription.banner.cta") }}</a
                >
              </div>
              <div class="col-md-6 col-xl-5 text-end">
                <div class="rc-wrapper-antelope"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="rc-banner-cta" :class="cssCTA">
          <div class="container">
            <div class="row flex-column-reverse flex-md-row">
              <div class="col-md-6 rc-col-max-width">
                <h2 v-html="$t('footer.get_started.banner.title')"></h2>
                <p class="rc-intro--xl">
                  {{ $t("footer.get_started.banner.text") }}
                </p>
                <router-link :to="toLogin" class="btn btn-primary btn-lg">{{
                  $t("footer.get_started.banner.cta")
                }}</router-link>
              </div>
              <div class="col-md-6 text-end">
                <div class="rc-wrapper-dolphin"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { navMixin } from "#/routes/mixins";

export default {
  name: "RcSiteBannerCTA",
  mixins: [navMixin],

  computed: {
    ...mapGetters(["roleIsGuest", "isAuthenticated"]),

    hasBannerCTA() {
      return this.$route.meta.bannerCTA;
    },
    typeCTA() {
      return this.$route.meta.bannerCTA.type;
    },
    cssCTA() {
      return this.$route.meta.bannerCTA.cssClass;
    },
    routeName() {
      return this.$route.name;
    }
  }
};
</script>
<style lang="scss" scoped></style>
