import { ifAuthenticated } from '#/stores/auth'

const routes = [
  {
    path: 'onboarding',
    alias: 'inscription',
    beforeEnter: ifAuthenticated,
    name: 'onboard',
    component: () => import('#/pages/onboard/Onboarding.vue'),
    meta: {
      title: 'pageMeta.login.title',
      alias: [null, null, 'inscription'],
      layout: 'skinny'
    }
  },
  {
    path: 'school-not-found',
    alias: 'ecole-inconnue',
    name: 'schoolNotFound',
    props: (route) => ({ email: route.params.email }),
    component: () => import('#/pages/onboard/SchoolNotFound.vue'),
    meta: {
      title: 'pageMeta.schoolNotFound.title',
      alias: [null, null, 'ecole-inconnue'],
      layout: 'skinny'
    }
  },
  {
    path: 'find-your-school',
    alias: 'trouve-ton-ecole',
    name: 'findSchool',
    component: () => import('#/pages/onboard/FindSchool.vue'),
    meta: {
      title: 'pageMeta.findYourSchool.title',
      alias: [null, null, 'trouve-ton-ecole'],
      layout: 'skinny'
    }
  },
  {
    path: 'access-point',
    alias: 'point-acces',
    name: 'findAccessPoint',
    component: () => import('#/pages/onboard/FindAccessPoint.vue'),
    meta: {
      title: 'pageMeta.findAccessPoint.title',
      alias: [null, null, 'point-acces'],
      layout: 'skinny'
    }
  }
]

export default routes
