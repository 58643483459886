import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";

export const toaster = {
  install: (app) => {
    const items = ref([]);

    const showItem = function (item) {
      items.value.push({
        type: item.type,
        message: item.message,
        uid: uuidv4()
      });
    };

    const notify = {
      success: (message) => {
        showItem({ type: "success", message });
      },
      danger: (message) => {
        showItem({ type: "danger", message });
      },
      warning: (message) => {
        showItem({ type: "warning", message });
      },
      info: (message) => {
        showItem({ type: "info", message });
      },
      primary: (message) => {
        showItem({ type: "primary", message });
      },
      secondary: (message) => {
        showItem({ type: "secondary", message });
      },
      light: (message) => {
        showItem({ type: "light", message });
      },
      dark: (message) => {
        showItem({ type: "dark", message });
      },
      getItems: () => items.value,

      removeItem: (item) => {
        items.value = items.value.filter((i) => i.uid !== item.uid);
      }
    };
    app.config.globalProperties.$notify = notify;
    app.provide("notify", notify);
  }
};
