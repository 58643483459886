<script setup>
import { computed, watch, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import i18n from "#/locales";
import RcAppProgess from "@/libvue/components/pageLoader/AppProgress.vue";
import RcPageLoader from "@/libvue/components/pageLoader/PageLoader.vue";
import RcToaster from "@/libvue/plugins/toaster/Toaster.vue";
import LayoutDefault from "#/layout/Default.vue";
import RcExternalLinkModal from "@/libvue/components/modal/ExternalLinkModal.vue";
import LayoutSkinny from "#/layout/Skinny.vue";
import LayoutEmbed from "#/layout/Embed.vue";
import { useRouterProgress } from "@/libvue/composables/routerProgress.js";

import { ROOT_UPDATE_PAGE_LOADED } from "#/stores/root";
import { GET_ACTIVE_HOMEPAGES } from "@/libvue/apps/catalog/homepages/actions";
import { GET_CATEGORIES } from "@/libvue/apps/catalog/categories/actions";
import { GET_NAV_LINEUP } from "@/libvue/apps/catalog/lineups/actions";
import { GET_CURRICULAS } from "@/libvue/apps/courses/actions";
import { GET_NEWS_LETTER } from "@/libvue/apps/newsletter/actions";

import "@fontsource/cabin/700.css";
import "@fontsource/radio-canada/400.css";
import "@fontsource/radio-canada/500.css";
import "@fontsource/radio-canada/700.css";

import {
  GET_TEAM_AGE_GROUPS,
  GET_AGE_GROUPS,
  GET_CHANNELS,
  GET_THEMES
} from "@/libvue/apps/catalog/items/actions";

import { USER_TEAMS_REQUEST } from "@/libvue/apps/team/actions";

const route = useRoute();
const router = useRouter();
const progress = useRouterProgress(router);
const store = useStore();

import { inject } from "vue";

const notify = inject("notify");

// methods
const updateBodyClasses = () => {
  const isUserOnATouchDevice = navigator.userAgent.match(
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i
  );
  document
    .querySelector("body")
    .classList.add(isUserOnATouchDevice ? "touch" : "no-touch");
};

const setRouteFocus = () => {
  const el = document.getElementById("rcMainWrapper");
  if (el) el.focus();
};

// computed
const layout = computed(() => {
  const _map = {
    embed: LayoutEmbed,
    skinny: LayoutSkinny,
    default: LayoutDefault
  };

  const l = route.meta.layout ? _map[route.meta.layout] : LayoutDefault;
  return l;
});

const routeClass = computed(() => {
  return route?.meta?.class || "";
});

const getTeamUID = computed(() => {
  return store.getters.getTeamUID;
});

const getTeamTypeAudience = computed(() => {
  return store.getters.getTeamTypeAudience;
});

// watchers
watch(
  () => getTeamUID.value,
  () => {
    if (getTeamUID.value) {
      Promise.all([
        store.dispatch(GET_TEAM_AGE_GROUPS),
        store.dispatch(GET_CATEGORIES),
        store.dispatch(GET_CURRICULAS),
        store.dispatch(GET_CHANNELS),
        store.dispatch(GET_THEMES),
        store.dispatch(GET_ACTIVE_HOMEPAGES),
        store.dispatch(USER_TEAMS_REQUEST),
        store.dispatch(GET_NAV_LINEUP, `nav-themes-${i18n.global.locale}`)
      ]).then(() => store.dispatch(GET_AGE_GROUPS, getTeamTypeAudience.value));
    }
  }
);
watch(
  () => i18n.global.locale,
  (to, from) => {
    if (from && from !== to && getTeamUID.value) {
      store
        .dispatch(GET_TEAM_AGE_GROUPS)
        .then(() => store.dispatch(GET_AGE_GROUPS, getTeamTypeAudience.value));
      store.dispatch(GET_CATEGORIES);
      store.dispatch(GET_CURRICULAS);
      store.dispatch(GET_CHANNELS);
      store.dispatch(GET_THEMES);
      store.dispatch(GET_ACTIVE_HOMEPAGES);
      store.dispatch(GET_NEWS_LETTER);
      store.dispatch(USER_TEAMS_REQUEST);
      store.dispatch(GET_NAV_LINEUP, `nav-themes-${i18n.global.locale}`);
    }
  }
);

// excute methods
updateBodyClasses();
store.dispatch(ROOT_UPDATE_PAGE_LOADED, false);

router.beforeEach((to, from, next) => {
  store.dispatch(ROOT_UPDATE_PAGE_LOADED, false);
  next();
});
router.afterEach((to, from) => {
  store.dispatch(ROOT_UPDATE_PAGE_LOADED, true);
  nextTick(() => setRouteFocus());

  // check if previous page was an error page
  // trigger toast notification to user
  // ex:  "/en/400?code=social-fail&msg="Domain not allowed"&provider=google

  if (from.name == "market.status.code") {
    notify.danger(
      from.query.msg
        ? this.$t(`static.loginError.${from.query.msg}`)
        : "An error occured"
    );
  }
});
</script>

<template>
  <RcPageLoader>
    <RcToaster />
    <RcAppProgess :progress="progress" />
    <RcExternalLinkModal />
    <component :key="layout" :is="layout" :class="routeClass" />
  </RcPageLoader>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>
