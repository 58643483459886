<template>
  <header role="banner">
    <!--'Skinny Nav' : Shared Playlist and Onboarding-->
    <nav
      :aria-label="$t(`a11y.nav.main`)"
      class="navbar rc-main-navigation rc-skinny-nav navbar-light navbar-expand"
    >
      <div class="container">
        <div class="rc-nav-brand">
          <router-link :to="toHome" class="nav-brand">
            <img
              src="/src/assets/img/logos/lg_curio.svg"
              :alt="$t(`nav.logo_go_to_home`)"
          /></router-link> 
         
        </div>
        <!--RIGHT :  Language & Get Started || User Account-->
        <ul class="rc-nav-right navbar-nav">
          <li class="nav-item rc-lang">
            <RcLanguage />
          </li>

          <!-- For Get Started (subscriber)-->
          <template
            v-if="
              pageName == 'subscribers.started' ||
              pageName == 'market.createProfile'
            "
          >
            <!-- Login -->

            <li class="nav-item">
              <router-link
                :to="toLogin"
                class="nav-link btn rc-btn-sign-in btn-light"
                >{{ $t("auth.log.in") }}</router-link
              >
            </li>
          </template>
          <!-- For Shared Playlist-->
          <template v-if="pageName == 'public.playlists.detail'">
            <!-- Guest -->
            <template v-if="roleIsGuest">
              <!--desktop-->
              <li class="nav-item" v-if="!orgIsPublic">
                <router-link
                  :to="toMarketCreateProfile"
                  class="nav-link btn-light btn rc-btn-sign-in d-none d-lg-inline"
                  >{{ $t(`nav.create_profile`) }}</router-link
                >
              </li>

              <!--mobile-->
              <li
                class="nav-item dropdown rc-menu-user d-lg-none"
                v-if="!orgIsPublic"
              >
                <button
                  id="rcBtnMenuAccountGuest"
                  class="btn btn-md btn-link rc-btn-icon-dd nav-link dropdown-toggle dropdown-toggle-no-caret"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  :title="$t('nav.my_account')"
                  aria-controls="rcMenuAccountGuest"
                >
                  <div class="rc-btn-dropdown-my-account">
                    <span class="visually-hidden">{{
                      $t("nav.my_account")
                    }}</span>
                    <rc-icon name="userCircle" scale="1.53"> </rc-icon>
                  </div>
                </button>

                <ul
                  id="rcMenuAccountGuest"
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="rcBtnMenuAccountGuest"
                >
                  <li>
                    <router-link
                      :to="toMarketCreateProfile"
                      class="dropdown-item btn-light btn"
                    >
                      <span>{{ $t("nav.create_profile") }}</span></router-link
                    >
                  </li>

                  <li role="presentation">
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <router-link :to="toLogin" class="dropdown-item">
                      <span class="rc-item-icon-text">{{
                        $t("auth.log.in")
                      }}</span
                      ><rc-icon name="logIn" scale="1"></rc-icon
                    ></router-link>
                  </li>
                </ul>
              </li>
            </template>
            <!-- Signed In -->
            <template v-else>
              <!-- My account -->

              <li class="nav-item dropdown rc-menu-user">
                <button
                  class="btn btn-md btn-link rc-btn-icon-dd nav-link dropdown-toggle dropdown-toggle-no-caret"
                  id="rcBtnMenuAccount"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  :title="$t('nav.my_account')"
                  aria-controls="rcMenuAccount"
                >
                  <div class="rc-btn-dropdown-my-account">
                    <span class="visually-hidden">{{
                      $t("nav.my_account")
                    }}</span>
                    <rc-icon name="userCircle" scale="1.53"> </rc-icon>
                  </div>
                </button>
                <ul
                  id="rcMenuAccount"
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="rcBtnMenuAccount"
                >
                  <li  v-if="profile.user_uid">
                    <router-link
                      :to="{
                        name: 'user.favorites',
                        params: { locale: $i18n.locale }
                      }"
                      class="dropdown-item"
                    >
                      <span class="rc-item-icon-text"
                        ><rc-icon name="bookmarkSmall" scale="1.125"></rc-icon
                        >{{ $t("catalog.favorites.my") }}</span
                      ></router-link
                    >
                  </li>
                  <li
                    
                    v-if="roleIsTeacher || roleIsLibraryUser || roleIsAdmin"
                  >
                    <router-link
                      :to="{
                        name: 'user.playlists',
                        params: { locale: $i18n.locale }
                      }"
                      class="dropdown-item"
                    >
                      <span class="rc-item-icon-text"
                        ><rc-icon name="playlist" scale="1"></rc-icon
                        >{{ $t("catalog.playlists_my") }}</span
                      ></router-link
                    >
                  </li>
                  <li role="presentation">
                    <hr class="dropdown-divider" />
                  </li>
                  <li  v-if="profile.user_uid">
                    <router-link
                      :to="{
                        name: 'user.account.summary',
                        params: { locale: $i18n.locale }
                      }"
                      class="dropdown-item"
                    >
                      {{ $t("nav.my_profile") }}</router-link
                    >
                  </li>
                  <!-- reports -->
                  <li
                   
                    v-if="roleIsAdmin || roleIsLibraryUser"
                  >
                    <router-link :to="toReports" class="dropdown-item">
                      <span v-if="roleIsAdmin">{{ $t("common.reports") }}</span>
                      <span v-else>{{
                        $t("catalog.metadata")
                      }}</span></router-link
                    >
                  </li>

                  <li role="presentation">
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <button
                      type="button"
                      class="dropdown-item"
                      @click="logoutHandler()"
                    >
                      <span class="rc-item-icon-text">{{
                        $t("auth.log.out")
                      }}</span
                      ><rc-icon name="logOut" scale="1"></rc-icon>
                    </button>
                  </li>
                </ul>
              </li>
            </template>
          </template>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import config from "#/config";
import { mapGetters, mapState } from "vuex";

import { navMixin } from "#/routes/mixins";

import RcLanguage from "@/libvue/components/i18n/LocaleSwitch.vue";
import RcIcon from "@/libvue/components/icons/Base.vue";
import { AUTH_LOGOUT } from "@/libvue/apps/auth/actions";

export default {
  name: "RcSiteHeaderSkinny",
  components: {
    RcLanguage,
    RcIcon
  },

  data() {
    return {
      config: config
    };
  },

  mixins: [navMixin],

  computed: {
    ...mapGetters([
      "roleIsTeacher",
      "roleIsLibraryUser",
      "roleIsStudent",
      "roleIsGuest",
      "roleIsAdmin",
      "orgIsPublic"
    ]),
    ...mapState({
      profile: (state) => state.auth.user
    }),

    pageName() {
      return this.$route.name;
    }
  },
  methods: {
    logoutHandler() {
      this.$store.dispatch(AUTH_LOGOUT);
    }
  }
};
</script>
<style lang="scss" scoped></style>
