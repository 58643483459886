import axios from "@/libvue/api/axios.js";
import * as constants from "./actions";

function getDefaultState() {
  return {
    list: {
      data: { results: [] },
      status: "",
      errors: {}
    },
    detail: {
      data: null,
      status: "",
      errors: {}
    },
    nav: {
      data: {},
      status: "",
      errors: {}
    }
  };
}

const getters = {
  getLineup: (state) => (slug) => {
    return state.detail.data?.slug == slug ? state.detail.data : null;
  }
};

const actions = {
  [constants.GET_LINEUP]: ({ commit }, slug) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_LINEUP);
      axios({ url: `lineups/${slug}`, authRequired: false })
        .then((response) => {
          commit(constants.GET_LINEUP_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_LINEUP_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_NAV_LINEUP]: ({ commit }, slug) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_NAV_LINEUP);
      axios({ url: `lineups/${slug}`, authRequired: false })
        .then((response) => {
          commit(constants.GET_NAV_LINEUP_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_NAV_LINEUP_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.GET_LINEUP]: (state) => {
    state.detail.status = "fetching";
    state.detail.errors = {};
  },
  [constants.GET_LINEUP_SUCCESS]: (state, { data }) => {
    state.detail.status = "success";
    state.detail.errors = {};
    state.detail.data = data;
  },
  [constants.GET_LINEUP_ERROR]: (state, err) => {
    state.detail.status = "error";
    state.detail.errors = err;
  },
  [constants.GET_NAV_LINEUP]: (state) => {
    state.nav.status = "fetching";
    state.nav.errors = {};
  },
  [constants.GET_NAV_LINEUP_SUCCESS]: (state, { data }) => {
    state.nav.status = "success";
    state.nav.errors = {};
    state.nav.data = data;
  },
  [constants.GET_NAV_LINEUP_ERROR]: (state, err) => {
    state.nav.status = "error";
    state.nav.errors = err;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
