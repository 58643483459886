import { each } from './utils/each.js';
import { extend } from './utils/extend.js';
import { parseParams } from './utils/parse-params.js';
import { serialize } from './utils/serialize.js';
import Emitter from 'component-emitter';

export default (function (env) {
  var previousDistributionAds = env.DistributionAds || undefined;

  function Client(props) {
    if (this instanceof Client === false) {
      return new Client(props);
    }
    this.configure(props);

    // Set up event handling
    if (Client.debug) {
      this.on('error', Client.log);
    }
    this.emit('ready');
    Client.emit('client', this);
  }

  if (previousDistributionAds && typeof previousDistributionAds.resources === 'undefined') {
    Client.legacyVersion = previousDistributionAds;
  }

  Emitter(Client);
  Emitter(Client.prototype);

  extend(Client, {
    debug: false,
    enabled: true,
    loaded: false,
    version: '__VERSION__'
  });

  // Set or extend helpers
  Client.helpers = Client.helpers || {};

  // Set or extend resources
  Client.resources = Client.resources || {};
  extend(Client.resources, {
    base: '{protocol}://{host}/{basePath}',
    events: '{protocol}://{host}/{basePath}/events'
  });

  // Set or extend utils
  Client.utils = Client.utils || {};
  extend(Client.utils, {
    each: each,
    extend: extend,
    parseParams: parseParams,
    serialize: serialize
  });

  Client.extendLibrary = function (target, source) {
    var previous = previousDistributionAds || source;
    if (isDefined(previous) && isDefined(previous.resources)) {
      each(previous, function (value, key) {
        if (typeof value === 'object') {
          target[key] = target[key] || {};
          extend(target[key], value);
        } else {
          target[key] = target[key] || value;
        }
      });
      extend(target.prototype, previous.prototype);
    }
    return target;
  };

  Client.log = function (str) {
    if (Client.debug && typeof console === 'object') {
      console.log('[DistributionAds]', str);
    }
  };

  Client.noConflict = function () {
    if (typeof env.DistributionAds !== 'undefined') {
      env.DistributionAds = Client.legacyVersion || previousDistributionAds;
    }
    return Client;
  };

  Client.ready = function (fn) {
    if (Client.loaded) {
      fn();
    } else {
      Client.once('ready', fn);
    }
  };

  Client.prototype.configure = function (obj) {
    var config = obj || {};
    this.config = this.config || {
      projectId: undefined,
      writeKey: undefined,
      host: undefined,
      protocol: 'https',
      requestType: 'jsonp',
      resources: extend({}, Client.resources)
    };

    // IE<10 request shim
    if (typeof window !== 'undefined' && window.navigator && window.navigator.userAgent && window.navigator.userAgent.indexOf('MSIE') > -1) {
      this.config.protocol = document.location.protocol.replace(':', '');
    }

    if (config.host) {
      config.host = config.host.replace(/(^\w+:|^)\/\//, '').replace(/.*?:\/\//g, '');
    }

    extend(this.config, config);
    return this;
  };

  Client.prototype.masterKey = function (str) {
    if (!arguments.length) return this.config.masterKey;
    this.config.masterKey = str ? String(str) : null;
    return this;
  };

  Client.prototype.resources = function (obj) {
    if (!arguments.length) return this.config.resources;
    var self = this;
    if (typeof obj === 'object') {
      each(obj, function (value, key) {
        self.config.resources[key] = value ? value : null;
      });
    }
    return self;
  };

  Client.prototype.url = function (name) {
    var args = Array.prototype.slice.call(arguments, 1),
      baseUrl = this.config.base || '{protocol}://{host}/',
      path;
    if (name && typeof name === 'string') {
      if (this.config.resources[name]) {
        path = this.config.resources[name];
      } else {
        path = baseUrl + '/' + name;
      }
    } else {
      path = baseUrl;
    }
    each(this.config, function (value, key) {
      if (typeof value !== 'object') {
        path = path.replace('{' + key + '}', value);
      }
    });

    each(args, function (arg, i) {
      if (typeof arg === 'string' && arg !== 'undefined') {
        path += '/' + arg;
      } else if (typeof arg === 'object') {
        path += '?';
        each(arg, function (value, key) {
          path += key + '=' + value + '&';
        });
        path = path.slice(0, -1);
      }
    });

    return path;
  };

  domReady(function () {
    Client.loaded = true;
    Client.emit('ready');
  });

  function domReady(fn) {
    if (Client.loaded || typeof document === 'undefined') {
      fn();
      return;
    }
    // Firefox 3.5 shim
    if (document.readyState == null && document.addEventListener) {
      document.addEventListener(
        'DOMContentLoaded',
        function DOMContentLoaded() {
          document.removeEventListener('DOMContentLoaded', DOMContentLoaded, false);
          document.readyState = 'complete';
        },
        false
      );
      document.readyState = 'loading';
    }
    testDom(fn);
  }

  function testDom(fn) {
    if (/in/.test(document.readyState)) {
      setTimeout(function () {
        testDom(fn);
      }, 9);
    } else {
      fn();
    }
  }

  function isDefined(target) {
    return typeof target !== 'undefined';
  }

  function isUndefined(target) {
    return typeof target === 'undefined';
  }

  return Client;
}.call(this, typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {}));
