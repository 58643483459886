import { ifAuthenticated } from '#/stores/auth'
import i18n from '#/locales'

const routes = [
  {
    path: 'my-playlists',
    alias: 'mes-listes-de-lecture',
    name: 'user.playlists',
    component: () => import('#/pages/playlists/List.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'pageMeta.playlists.title',
      alias: [null, null, 'mes-listes-de-lecture'],
      class: '',
      breadcrumb: {
        back: false,
        class: '',
        crumbs: [
          {
            text: () => i18n.global.t('nav.home'),
            to: { name: 'home.user' }
          },
          {
            text: () => i18n.global.t('catalog.playlists_my'),
            active: true
          }
        ]
      }
    }
  },
  {
    path: 'my-playlists/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
    alias:
      'mes-listes-de-lecture/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
    name: 'user.playlists.detail',
    component: () => import('#/pages/playlists/Detail.vue'),
    beforeEnter: ifAuthenticated,

    meta: {
      alias: [null, null, 'mes-listes-de-lecture', null],
      //title:"titre de la playlist ?"
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t('nav.home'),
            to: { name: 'home.user' }
          },
          {
            text: () => i18n.global.t('catalog.playlists_my'),
            to: {
              name: 'user.playlists'
            }
          },
          {
            text: (route) => route.params.uid,
            active: true
          }
        ]
      }
    }
  },
  {
    path: 'playlists/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
    alias: 'listes-de-lecture/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
    name: 'public.playlists.detail',
    component: () => import('#/pages/playlists/DetailPublic.vue'),
    beforeEnter: ifAuthenticated,

    meta: {
      alias: [null, null, 'listes-de-lecture', null],
      layout: 'skinny',
      bannerCTA: {
        type: 'getStarted',
        cssClass: 'bg-plum'
      }
    }
  }
]
export default routes
