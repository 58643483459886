import Config from "#/config";

const navMixin = {
  computed: {
    toHome() {
      return { name: "home", params: { locale: this.$i18n.locale } };
    },
    toHomeUser() {
      return { name: "home.user", params: { locale: this.$i18n.locale } };
    },
    toLogin() {
      return { name: "auth.login", params: { locale: this.$i18n.locale } };
    },
    toGetStarted() {
      return {
        name: "subscribers.started",
        params: { locale: this.$i18n.locale }
      };
    },
    toMarketContent() {
      return { name: "market.content", params: { locale: this.$i18n.locale } };
    },
    toMarketSubscription() {
      return {
        name: "market.subscription",
        params: { locale: this.$i18n.locale }
      };
    },
    toMarketExperience() {
      return {
        name: "market.experience",
        params: { locale: this.$i18n.locale }
      };
    },
    toMarketChannels() {
      return {
        name: "market.channels",
        params: { locale: this.$i18n.locale }
      };
    },
    toMarketCreateProfile() {
      return {
        name: "market.createProfile",
        params: { locale: this.$i18n.locale }
      };
    },
    // toMarketDesignSystem() {
    //   return {
    //     name: "market.designSystem",
    //     params: { locale: this.$i18n.locale }
    //   };
    // },

    toAbout() {
      return { name: "market.about", params: { locale: this.$i18n.locale } };
    },
    toOnboarding() {
      return {
        name: "onboard",
        params: { locale: this.$i18n.locale }
      };
    },
    toAgeGroupList() {
      return {
        name: "age.group.list",
        params: { locale: this.$i18n.locale }
      };
    },
    toCurriculaList() {
      return {
        name: "catalog.curricula.list",
        params: { locale: this.$i18n.locale }
      };
    },
    toCategoriesList() {
      return {
        name: "catalog.categories.list",
        params: { locale: this.$i18n.locale }
      };
    },
    toCatalogList() {
      return {
        name: "catalog.item.list",
        params: { locale: this.$i18n.locale }
      };
    },
    toRecommendedDetail() {
      return {
        name: "catalog.recommended.detail",
        params: { locale: this.$i18n.locale }
      };
    },
    toChannelsList() {
      return {
        name: "catalog.channels.list",
        params: { locale: this.$i18n.locale }
      };
    },
    toThemesList() {
      return {
        name: "catalog.themes.list",
        params: { locale: this.$i18n.locale }
      };
    },
    toFeaturedDetail() {
      return {
        name: "catalog.featured.detail",
        params: { locale: this.$i18n.locale }
      };
    },
    toSchoolNotFound() {
      return {
        name: "schoolNotFound",
        params: { locale: this.$i18n.locale }
      };
    },
    toFindSchool() {
      return {
        name: "findSchool",
        params: { locale: this.$i18n.locale }
      };
    },
    toFindAccessPoint() {
      return {
        name: "findAccessPoint",
        params: { locale: this.$i18n.locale }
      };
    },

    extToSupport() {
      return `https://${Config.decodedHost}/${this.$i18n.locale}/support`;
    },

    toDashboard() {
      return {
        name: "dashboard.summary",
        params: { locale: this.$i18n.locale }
      };
    },

    toReports() {
      return {
        name: "dashboard.reports",
        params: { locale: this.$i18n.locale }
      };
    }
  }
};

export { navMixin };
