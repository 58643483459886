import { ref } from "vue";

export function useRouterProgress(router) {
  const progress = ref(0);
  const timer = ref(null);
  const finished = ref(false);

  router.beforeEach(() => {
    clearInterval(timer);
    timer.value = setInterval(() => {
      if (!finished.value) {
        progress.value = Math.round(Math.random() * 50);
      }
    }, 100);
  });

  router.afterEach(() => {
    progress.value = 100;
    finished.value = true;
    setTimeout(() => {
      progress.value = 0;
    }, 200);
  });

  return progress;
}
