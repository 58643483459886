const DEVELOPMENT = "development";
const QA = "qa";
const REMOTE = "remote";
const PRODUCTION = "production";
const TRACE = "trace";
const ERROR = "error";

import { Buffer } from "buffer";

const ENCODEDHOST = Buffer.from("Y3VyaW8uY2E=", "base64");
const DECODEDHOST = ENCODEDHOST.toString(); // curio.ca

const CONFIG = {
  ENVIRONMENT: (process.env.NODE_ENV || "development").trim(),
  VERSION: "dev",
  decodedHost: DECODEDHOST,
  site: {
    name: "Curio",
    qa: "curio.nonprod-dapp.camr.io",
    remote: "curio.nonprod-dapp.camr.io",
    prod: "curio.ca",
    production: "curio.ca",
    uat: "curio.preprod-dapp.camr.io"
  },
  api: {
    host: "curio.nonprod-dapp.camr.io", // defaults to local dev settings
    use_ssl: false,
    refresh_interval: 30000, // 5 min
    base: "/api/v2",
    token: "auth/token",
    refresh: "auth/refresh",
    verify: "auth/verify",
    ip_token: "auth/ip-token",
    session_token: "auth/session-token"
  },
  social: {
    google: {
      APP_CLIENT_ID:
        "419131545053-2t6f1er6s7no7k027oiij2ocvlb8qqug.apps.googleusercontent.com"
    }
  },
  auth: {
    redirects: {
      homeUser: { name: "home.user" },
      logout: { name: "home" },
      login: { name: "auth.login" },
      agreement: { name: "auth.agreement" },

      // redirect users after login depending on role if role else default
      afterLoginSuccess: {
        user: { name: "onboard" },
        staff: { name: "onboard" },
        sysadmin: { name: "onboard" },
        default: { name: "home.user" }
      }
    }
  },
  catalog: {
    enabled: false,
    isPreviewPaneEnabled: false,
    displayOwnerLogo: false,
    defaultSortOrder: "-modified_on",
    savedFilters: false,
    public: true,
    catalogs: []
  },
  team: {
    enabled: true
  },
  agreement: {
    version: "2024-09-01",
    path: ""
  },
  license: {
    version: 1,
    path: ""
  },
  supportDeskUrl: "https://curio.ca/r/support",
  contactDeskUrl: "https://curio.ca/r/contact",
  kaltura: {
    targetId: "kaltura_player_862971668",
    partnerId: 1307701,
    uiConfId: 46816003
  },
  helpScout: {
    localeIds: {
      fr: "1449e7fa-0e08-410c-8af5-ad43130ebb93",
      en: "b8f85539-fe89-472c-88cd-16fd9631fefa"
    }
  },
  plugins: {
    VueGtm: {
      id: "GTM-N2CS3JK"
    }
  },

  isDevelopment() {
    return (
      typeof CONFIG.ENVIRONMENT === undefined ||
      CONFIG.ENVIRONMENT === DEVELOPMENT
    );
  },
  isQA() {
    return typeof CONFIG.ENVIRONMENT !== undefined && CONFIG.ENVIRONMENT === QA;
  },
  isRemote() {
    return (
      typeof CONFIG.ENVIRONMENT !== undefined && CONFIG.ENVIRONMENT === REMOTE
    );
  },
  isProduction() {
    return (
      typeof CONFIG.ENVIRONMENT !== undefined &&
      CONFIG.ENVIRONMENT === PRODUCTION
    );
  },
  API() {
    const API = CONFIG.api;
    if (!CONFIG.isDevelopment()) {
      API.host = CONFIG.site[this.ENVIRONMENT];
      API.use_ssl = true;
    }
    return API;
  },
  appVersion() {
    return CONFIG.VERSION;
  },
  siteProtocol() {
    if (CONFIG.API().use_ssl) {
      return "https://";
    }
    return "https://";
  },
  siteName() {
    return CONFIG.site.name;
  },
  apiHost() {
    if (CONFIG.isProduction()) {
      return "";
    } else {
      return `${CONFIG.siteProtocol()}${CONFIG.API().host}`;
    }
  },
  apiAdminUrl() {
    return `${CONFIG.apiHost()}/admin`;
  },
  apiBase() {
    // provide to Axios BaseUrl
    return CONFIG.apiHost() + CONFIG.API().base;
  },
  seoBase() {
    // provide to Axios BaseUrl
    return `${CONFIG.apiHost()}/seo`;
  },
  apiToken() {
    // relative to BaseUrl
    return CONFIG.API().token;
  },
  apiRefresh() {
    // relative to BaseUrl
    return CONFIG.API().refresh;
  },
  apiVerify() {
    // relative to BaseUrl
    return CONFIG.API().verify;
  },
  apiIPToken() {
    // relative to BaseUrl
    return CONFIG.API().ip_token;
  },
  apiSessionToken() {
    // relative to BaseUrl
    return CONFIG.API().session_token;
  },
  apiRefreshInterval() {
    return CONFIG.API().refresh_interval;
  },
  websocketProtocol() {
    if (CONFIG.API().use_ssl) {
      return "wss://";
    }
    return "ws://";
  },
  websocketHost() {
    return `${CONFIG.websocketProtocol()}${CONFIG.API().host}/ws`;
  },
  logLevel() {
    if (CONFIG.isDevelopment()) {
      return TRACE;
    }
    return ERROR;
  },
  i18n() {
    return true;
  }
};

export default CONFIG;
