<template>
  <div>
    <li v-for="m in listMyTeams" :key="m.team.uid">
      <button
        class="dropdown-item"
        @click="onTeamClick(m.team)"
        :class="{ active: m.team.uid === profile.team_uid }"
      >
        <div class="rc-team-name">{{ m.team.name }}</div>

        <div v-if="options.role" class="text-secondary">
          <div v-if="m.role.includes('admin')">
            {{ $t("team.admin") }}
          </div>

          <div v-if="m.role.includes('student')">
            {{ $t("common.student") }}
          </div>

          <div v-else-if="m.role.includes('teacher')">
            {{ $t("common.teacher") }}
          </div>

          <div v-else-if="m.role.includes('library_user')">
            {{ $t("common.librarian") }}
          </div>

          <div v-else-if="m.role.includes('guest')">
            {{ $t("common.guest") }}
          </div>

          <div v-else-if="!m.role.includes('admin')">
            {{ m.role }}
          </div>
        </div>
      </button>
    </li>
  </div>
</template>

<script>
import { teamMixin } from "@/libvue/apps/team/mixins";
import store from "#/stores";

export default {
  name: "RcJoinedTeams",
  mixins: [teamMixin],
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          role: true
        };
      }
    }
  },
  computed: {
    listMyTeams() {
      return this.teamsUserIsJoined.slice(0, 5);
    }
  },
  mounted() {
    if (this.profile.user_uid) {
      Promise.all([store.dispatch("USER_TEAMS_REQUEST")]);
    }
  }
};
</script>

<style lang="scss" scoped></style>
