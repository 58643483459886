import axios from "@/libvue/api/axios.js";
import * as constants from "./actions";

function getDefaultState() {
  return {
    detail: {
      data: {},
      status: null,
      error: null
    }
  };
}

const actions = {
  [constants.GET_NEWS_LETTER]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_NEWS_LETTER);
      axios({ url: `newsletter`, authRequired: false })
        .then((response) => {
          commit(constants.GET_NEWS_LETTER_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_NEWS_LETTER_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.GET_NEWS_LETTER]: (state) => {
    state.detail.status = "fetching";
    state.detail.errors = {};
  },
  [constants.GET_NEWS_LETTER_SUCCESS]: (state, { data }) => {
    state.detail.status = "success";
    state.detail.errors = {};
    state.detail.data = data;
  },
  [constants.GET_NEWS_LETTER_ERROR]: (state, err) => {
    state.detail.status = "error";
    state.detail.errors = err;
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
