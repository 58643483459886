import { ifUserCanViewReports } from '#/stores/auth'
import i18n from '#/locales'

const routes = [
  {
    path: 'reports',
    alias: 'rapports',
    name: 'dashboard.reports',
    beforeEnter: ifUserCanViewReports,
    component: () => import('#/pages/dashboard/Reports.vue'),
    meta: {
      title: 'pageMeta.reports.title',
      alias: [null, null, 'rapports'],
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => i18n.global.t('nav.home'),
            to: { name: 'home.user' }
          }
        ]
      }
    }
  }
]
export default routes
