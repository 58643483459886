<template>
  <RcModal
    :id="modalId"
    headerClass="border-bottom-0 justify-content-end"
    footerClass="mt-4"
    hideTitle
    @show="onShow"
  >
    <template #modal-body>
      <h2 class="text-center h3" :id="`${modalId}_label`">
        {{ msg }}
      </h2></template
    >
    <template #modal-footer>
      <div class="row justify-content-between align-items-center w-100">
        <div class="col-8">
          <div class="form-check">
            <input
              id="ckb_dontRemind"
              v-model="hideWarning"
              type="checkbox"
              class="form-check-input"
            /><label class="form-check-label" for="ckb_dontRemind">
              {{ $t("common.dont_remind_me") }}</label
            >
          </div>
        </div>
        <div class="col text-end">
          <button class="btn btn-primary" @click="handleOk()">
            {{ $t(`common.continue`) }}
          </button>
        </div>
      </div>
    </template>
  </RcModal>
</template>

<script>
import Config from "#/config";
import RcModal from "@/libvue/components/modal/Modal.vue";

export default {
  name: "RcExternalLinkModal",

  components: {
    RcModal
  },

  data() {
    return {
      modalId: "externalLinkModal",
      href: null,
      localHide: localStorage.getItem("external-link-warning")
        ? localStorage.getItem("external-link-warning")
        : false
    };
  },
  computed: {
    msg() {
      return this.$t("common.leave_website", { name: Config.site.name });
    },
    hideWarning: {
      get: function () {
        return this.localHide;
      },
      set: function (value) {
        this.localHide = value;
        localStorage.setItem("external-link-warning", value);
      }
    }
  },
  methods: {
    onShow(e) {
      this.href = e.relatedTarget.href;

      if (this.hideWarning) {
        e.preventDefault();
        window.open(this.href, "_self");
      }
    },
    handleOk() {
      window.open(this.href, "_self");
    }
  }
};
</script>
