export const iconMap = {
  activity:
    "<path d='M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z'/>",
  antenna:
    "<path d='M5.34,5.49a2,2,0,0,1,0-2.8.56.56,0,0,1,.76,0,.54.54,0,0,1,0,.76.91.91,0,0,0,0,1.28.53.53,0,0,1-.38.91A.55.55,0,0,1,5.34,5.49Zm4.55,0a.54.54,0,0,0,.38.15.57.57,0,0,0,.38-.15,2,2,0,0,0,0-2.8.56.56,0,0,0-.76,0,.54.54,0,0,0,0,.76.91.91,0,0,1,0,1.28A.54.54,0,0,0,9.89,5.49ZM4,6.82a.55.55,0,0,0,.38-.16.54.54,0,0,0,0-.76,2.56,2.56,0,0,1,0-3.62.54.54,0,0,0-.76-.76,3.65,3.65,0,0,0,0,5.14A.59.59,0,0,0,4,6.82Zm7.59-5.3a.54.54,0,0,0,0,.76,2.56,2.56,0,0,1,0,3.62.54.54,0,0,0,0,.76.52.52,0,0,0,.75,0,3.62,3.62,0,0,0,0-5.14A.53.53,0,0,0,11.6,1.52ZM14,.4a.54.54,0,0,0-.75,0,.54.54,0,0,0,0,.76,4.15,4.15,0,0,1,.31,5.51.52.52,0,0,0,0,.7.53.53,0,0,0,.8,0A5.21,5.21,0,0,0,14,.4ZM12.84,15.25a.58.58,0,0,1,0,.19h0a.31.31,0,0,1-.05.1l0,0,0,0a.46.46,0,0,1-.07.07l0,0-.09,0-.05,0-.14,0H3.68a.54.54,0,0,1-.51-.72L7.49,3.19a.54.54,0,0,1,1,0l4.31,11.87a.4.4,0,0,1,0,.15ZM5.62,11.46l3.47-2L6.84,8.12Zm.73.82,5.05,2L9.92,10.24ZM7.21,7.1l2,1.2L8,4.94ZM4.44,14.71H9.53L5.09,12.93ZM1.54,4.06A4.11,4.11,0,0,1,2.75,1.14a.54.54,0,0,0,0-.76A.53.53,0,0,0,2,.38,5.2,5.2,0,0,0,1.6,7.3a.54.54,0,0,0,.8,0,.54.54,0,0,0,0-.71A4.13,4.13,0,0,1,1.54,4.06Z'>",
  arrowDown:
    "<path d='M6.94,1.28V12.14L3.09,8.29a1.06,1.06,0,0,0-1.51,0,1.05,1.05,0,0,0,0,1.5l5.68,5.68a1.05,1.05,0,0,0,1.5,0l5.68-5.68a1.06,1.06,0,0,0-1.51-1.5L9.08,12.14V1.28a1.07,1.07,0,0,0-2.14,0Z'/>",
  arrowLeft:
    "<path d='M14.72,6.93H3.87L7.72,3.08a1.06,1.06,0,0,0,0-1.51,1.05,1.05,0,0,0-1.5,0L.54,7.25a1.05,1.05,0,0,0,0,1.5l5.68,5.68a1.05,1.05,0,0,0,1.5,0,1.06,1.06,0,0,0,0-1.51L3.87,9.07H14.72a1.07,1.07,0,0,0,0-2.14Z'/>",
  arrowRight:
    "<path d='M1.29,9.07H12.15L8.3,12.92a1.06,1.06,0,0,0,0,1.51,1.05,1.05,0,0,0,1.5,0l5.68-5.68a1.07,1.07,0,0,0,0-1.5L9.8,1.57a1.05,1.05,0,0,0-1.5,0,1.06,1.06,0,0,0,0,1.51l3.85,3.85H1.29a1.07,1.07,0,0,0,0,2.14Z'/>",
  arrowUp:
    "<path d='M9.07,14.72V3.86l3.86,3.85a1.06,1.06,0,0,0,1.51-1.5L8.76.53a1.06,1.06,0,0,0-1.51,0L1.58,6.21a1.05,1.05,0,0,0,0,1.5,1.06,1.06,0,0,0,1.51,0L6.94,3.86V14.72a1.07,1.07,0,0,0,2.13,0Z'/>",
  arrowUpDown:
    "<path d='M6.93,9.29a.67.67,0,0,1,0,.93L4.09,13.07a.68.68,0,0,1-.94,0L.31,10.22a.65.65,0,0,1,0-.93.67.67,0,0,1,.93,0L3,11V3.4a.66.66,0,1,1,1.32,0V11L6,9.29A.67.67,0,0,1,6.93,9.29Zm8.76-3.51L12.85,2.93a.67.67,0,0,0-.93,0L9.07,5.78a.67.67,0,0,0,0,.93.7.7,0,0,0,.47.19A.68.68,0,0,0,10,6.71L11.72,5V12.6a.66.66,0,0,0,1.32,0V5l1.72,1.73a.66.66,0,0,0,.93,0A.65.65,0,0,0,15.69,5.78Z'/>",
  bell: "<path d='M15,13,15,12.9a9.45,9.45,0,0,1-1.58-5.23V7.32a5.38,5.38,0,0,0-3.41-5A2,2,0,0,0,8.62.7.63.63,0,0,0,7.94.14.62.62,0,0,0,7.39.7,2,2,0,0,0,6.05,2.32a5.39,5.39,0,0,0-3.43,5v.35A9.37,9.37,0,0,1,1,12.9l-.12.18a.64.64,0,0,0,.17.87.68.68,0,0,0,.35.1h2.7a2.2,2.2,0,0,0,4.33,0h6.09A.62.62,0,0,0,15,13Zm-8.72,1.6a1,1,0,0,1-.88-.57H7.18a1,1,0,0,1-.88.57Zm-1-1.81H2.55A10.63,10.63,0,0,0,3.87,7.67V7.32a4.13,4.13,0,0,1,8.26,0h0v.35a10.73,10.73,0,0,0,1.31,5.14Z'/>",
  bookmark:
    "<path d='M13.08,16a.56.56,0,0,1-.31-.09L8,12.86l-4.77,3a.6.6,0,0,1-.89-.51V.85A.58.58,0,0,1,2.92.27H13.08a.58.58,0,0,1,.58.58V15.37a.57.57,0,0,1-.3.51A.68.68,0,0,1,13.08,16ZM3.5,1.43V14.32l4.19-2.64a.58.58,0,0,1,.62,0l4.19,2.64V1.43Z'/>",
  bookmarkAdd:
    "<path d='M13.08,15.84a.59.59,0,0,1-.31-.09L8,12.75l-4.77,3a.59.59,0,0,1-.59,0,.58.58,0,0,1-.3-.51V.74A.58.58,0,0,1,2.92.16H13.08a.58.58,0,0,1,.58.58V15.26a.58.58,0,0,1-.3.51A.68.68,0,0,1,13.08,15.84ZM3.5,1.32V14.21l4.19-2.64a.58.58,0,0,1,.62,0l4.19,2.64V1.32ZM8.64,4.06H7.48V9H8.64Zm1.88,1.88H5.6V7.1h4.92Z'/>",
  bookmarkRemove:
    "<path d='M13.19,15.84a.56.56,0,0,1-.31-.09l-4.77-3-4.77,3a.6.6,0,0,1-.89-.51V.74A.58.58,0,0,1,3,.16H13.19a.58.58,0,0,1,.58.58V15.26a.59.59,0,0,1-.3.51A.72.72,0,0,1,13.19,15.84ZM3.61,1.32V14.21L7.8,11.57a.58.58,0,0,1,.62,0l4.19,2.64V1.32Zm7,4.62H5.71V7.1h4.92Z'/>",
  bookmarkSmall:
    "<path d='M12.55.36H3.45a.73.73,0,0,0-.72.73V14.91a.71.71,0,0,0,.38.64.72.72,0,0,0,.34.09.67.67,0,0,0,.41-.13L8,12.74l4.14,2.77a.72.72,0,0,0,.75,0,.71.71,0,0,0,.38-.64V1.09A.73.73,0,0,0,12.55.36Zm-.73,13.19L8.4,11.26a.72.72,0,0,0-.4-.12.78.78,0,0,0-.41.12L4.18,13.55V1.82h7.64Z'/>",
  bullet: "<path d='M8,.25A7.75,7.75,0,1,1,.25,8,7.75,7.75,0,0,1,8,.25Z'/>",
  calendar:
    "<path d='M14.41,14.3h-13a.93.93,0,0,1-.93-.93V3.5a.93.93,0,0,1,.93-.93H2.58a.93.93,0,0,1,0,1.86h-.2v8h11.1v-8h-.2a.93.93,0,1,1,0-1.86h1.13a.93.93,0,0,1,.93.93v9.87A.93.93,0,0,1,14.41,14.3ZM9.79,3.52a.93.93,0,0,0-.93-.93H7A.93.93,0,0,0,7,4.45H8.86A.93.93,0,0,0,9.79,3.52Zm-4,.88V2.54a.93.93,0,0,0-1.86,0V4.4a.93.93,0,1,0,1.86,0Zm6.2,0V2.54a.93.93,0,0,0-1.86,0V4.4A.93.93,0,0,0,12,4.4Z'/>",
  check:
    "<path d='M.49,9.07l4.3,4.29a1,1,0,0,0,1.41,0l9.31-9.31A1,1,0,1,0,14.1,2.64l-8.6,8.6L1.9,7.65a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42Z'/>",
  checkBox:
    "<path d='M14.19,.33c-.37-.26-.88-.17-1.15,.2l-.61,.87c-.27-.1-.57-.17-.89-.17H2.93C1.57,1.23,.46,2.34,.46,3.69V12.32c0,1.36,1.1,2.47,2.47,2.47H11.56c1.36,0,2.47-1.1,2.47-2.47V3.69c0-.44-.13-.85-.34-1.21l.71-1.01c.26-.37,.17-.89-.2-1.15h0Zm-1.82,11.99c0,.46-.36,.82-.82,.82H2.93c-.46,0-.82-.36-.82-.82V3.69c0-.46,.36-.82,.82-.82H11.4l-4.17,5.92-1.97-2.49c-.28-.35-.79-.42-1.16-.13-.35,.28-.42,.79-.14,1.16l2.65,3.35c.16,.2,.39,.31,.64,.31h.02c.26,0,.5-.14,.65-.35l4.43-6.3v7.98h0Z'/>",
  chevronDown:
    "<path d='M15.36,6,8.52,11.31a.84.84,0,0,1-1,0L.64,6a.85.85,0,0,1,1-1.35L8,9.55l6.32-4.86a.85.85,0,0,1,1,1.35Z'/>",
  chevronDownBold:
    "<path d='M.67,5.43,7,11.78a1.4,1.4,0,0,0,2,0l6.35-6.35a1.39,1.39,0,0,0-2-2L8,8.84,2.63,3.47a1.39,1.39,0,0,0-2,2Z'/>",
  chevronLeft:
    "<path d='M11.45,15.7a.78.78,0,0,1-.57-.24L4,8.57A.82.82,0,0,1,4,7.43L10.88.54A.8.8,0,0,1,12,.54a.81.81,0,0,1,0,1.14L5.7,8,12,14.32a.81.81,0,0,1-.57,1.38Z'/>",
  chevronLeftBold:
    "<path d='M10.15.69,3.82,7a1.41,1.41,0,0,0-.4,1,1.43,1.43,0,0,0,.41,1l6.37,6.33a1.4,1.4,0,0,0,2,0,1.38,1.38,0,0,0,0-2L6.76,8l5.36-5.39a1.4,1.4,0,1,0-2-1.95Z'/>",
  chevronRight:
    "<path d='M4,15.46a.81.81,0,0,1,0-1.14L10.3,8,4,1.68A.81.81,0,0,1,4,.54a.8.8,0,0,1,1.14,0L12,7.43a.82.82,0,0,1,0,1.14l-6.9,6.89a.78.78,0,0,1-.57.24A.81.81,0,0,1,4,15.46Z'/>",
  chevronRightBold:
    "<path d='M5.88.68a1.4,1.4,0,1,0-2,1.95L9.24,8,3.85,13.35a1.38,1.38,0,0,0,0,2,1.4,1.4,0,0,0,2,0L12.17,9a1.43,1.43,0,0,0,.41-1,1.41,1.41,0,0,0-.4-1L5.85.69Z'/>",
  chevronUpBold:
    "<path d='M15.33,9.78,9,3.45a1.4,1.4,0,0,0-1-.4,1.36,1.36,0,0,0-1,.41L.67,9.83a1.38,1.38,0,1,0,2,1.95L8,6.39l5.39,5.36a1.39,1.39,0,0,0,1.95-2Z'/>",
  circle:
    "<path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>",
  clip: "<path d='M14.94,1.88H1.06a.69.69,0,0,0-.69.69V13.43a.69.69,0,0,0,.69.69H14.94a.69.69,0,0,0,.69-.69V2.57A.69.69,0,0,0,14.94,1.88ZM7.31,11.34v1.39H5.22V11.34Zm1.38,0h2.09v1.39H8.69ZM1.75,10V6.14h12.5V10Zm12.5-6.69V4.75H12.16V3.27Zm-3.47,0V4.75H8.69V3.27Zm-3.47,0V4.75H5.22V3.27Zm-3.47,0V4.75H1.75V3.27ZM1.75,11.34H3.84v1.39H1.75Zm10.41,1.39V11.34h2.09v1.39Z'/>",
  clockArrow:
    "<path d='M15,11.31A7,7,0,0,1,1.9,9.53l-.81.78a.59.59,0,0,1-.81-.85L2.11,7.7a.59.59,0,0,1,.8,0l2,1.77a.58.58,0,0,1-.39,1,.62.62,0,0,1-.4-.15l-1-.9a5.82,5.82,0,1,0,.48-4,.59.59,0,0,1-1.05-.54A7,7,0,0,1,6.6,1.45a6.89,6.89,0,0,1,5.34.43A7,7,0,0,1,15,11.31ZM8.2,4.23a.58.58,0,0,0-.59.58v4a.58.58,0,0,0,.59.59h4a.58.58,0,0,0,.58-.59.59.59,0,0,0-.58-.59H8.79V4.81A.59.59,0,0,0,8.2,4.23Z'/>",
  close:
    "<path d='M9.15,8l6.38-6.37A.82.82,0,0,0,14.37.47h0L8,6.85,1.63.47A.82.82,0,0,0,.47,1.63L6.85,8,.47,14.37a.82.82,0,0,0,1.16,1.16L8,9.15l6.37,6.38a.82.82,0,0,0,1.16-1.16Z'/>",
  closeBold:
    "<path d='M14.76,15.76a1,1,0,0,1-.71-.29L8,9.41,2,15.47A1,1,0,1,1,.53,14.05L6.59,8,.53,2A1,1,0,0,1,2,.53L8,6.59,14.05.53A1,1,0,1,1,15.47,2L9.41,8l6.06,6.05a1,1,0,0,1,0,1.42A1,1,0,0,1,14.76,15.76Z'/>",
  closeCircle:
    "<path d='M15.95,8c0-1.08-.21-2.1-.6-3.04-.4-.97-.99-1.85-1.73-2.58s-1.61-1.32-2.58-1.73C10.1,.26,9.08,.05,8,.05s-2.1,.21-3.04,.6c-.97,.4-1.85,.99-2.58,1.73s-1.32,1.61-1.73,2.58C.26,5.9,.05,6.92,.05,8s.21,2.1,.6,3.04c.4,.97,.99,1.85,1.73,2.58s1.61,1.32,2.58,1.73c.94,.39,1.97,.6,3.04,.6s2.1-.21,3.04-.6c.97-.4,1.85-.99,2.58-1.73s1.32-1.61,1.73-2.58c.39-.94,.6-1.97,.6-3.04Zm-1.45,0c0,.88-.18,1.72-.49,2.49-.33,.8-.81,1.51-1.41,2.11s-1.32,1.08-2.11,1.41c-.77,.32-1.61,.49-2.49,.49s-1.72-.18-2.49-.49c-.8-.33-1.51-.81-2.11-1.41-.6-.6-1.08-1.32-1.41-2.11-.32-.77-.49-1.61-.49-2.49s.18-1.72,.49-2.49c.33-.8,.81-1.51,1.41-2.11s1.32-1.08,2.11-1.41c.77-.32,1.61-.49,2.49-.49s1.72,.18,2.49,.49c.8,.33,1.51,.81,2.11,1.41,.6,.6,1.08,1.32,1.41,2.11,.32,.77,.49,1.61,.49,2.49Zm-9.19-1.66l1.66,1.66-1.66,1.66c-.28,.28-.28,.74,0,1.02s.74,.28,1.02,0l1.66-1.66,1.66,1.66c.28,.28,.74,.28,1.02,0s.28-.74,0-1.02l-1.66-1.66,1.66-1.66c.28-.28,.28-.74,0-1.02s-.74-.28-1.02,0l-1.66,1.66-1.66-1.66c-.28-.28-.74-.28-1.02,0s-.28,.74,0,1.02Z'/>",
  closeExtraBold:
    "<path d='M.8,3.55,12.45,15.2a1.94,1.94,0,0,0,2.75-2.75L3.55.8A1.94,1.94,0,0,0,.8,3.55 M12.45.8.8,12.45A1.94,1.94,0,0,0,3.55,15.2L15.2,3.55A1.94,1.94,0,0,0,12.45.8'/>",
  code: "<path d='M15.68,8.59l-3.06,3.06a.57.57,0,1,1-.81-.81l2.65-2.65L11.81,5.53a.57.57,0,0,1,.81-.81l3.06,3.06A.56.56,0,0,1,15.68,8.59ZM4.19,4.72a.58.58,0,0,0-.81,0L.32,7.78a.56.56,0,0,0,0,.81l3.06,3.06a.57.57,0,1,0,.81-.81L1.54,8.19,4.19,5.53A.58.58,0,0,0,4.19,4.72ZM10.59,2a.58.58,0,0,0-.74.34l-4,10.92a.57.57,0,0,0,.34.74.69.69,0,0,0,.2,0,.58.58,0,0,0,.54-.37l4-10.92A.57.57,0,0,0,10.59,2Z'/>",
  danger:
    "<path d='M8,2.69,1.7,13.63H14.3L8,2.69M8,1.08a1,1,0,0,1,.84.48L15.7,13.47a1,1,0,0,1-.84,1.45H1.14A1,1,0,0,1,.3,13.47L7.16,1.56A1,1,0,0,1,8,1.08Zm0,9.49a.63.63,0,0,1-.64-.64V5.74a.64.64,0,0,1,1.28,0V9.93A.63.63,0,0,1,8,10.57Zm0,1.94a.64.64,0,0,1-.45-.19.62.62,0,0,1-.19-.46.49.49,0,0,1,0-.12l0-.13.07-.11a.32.32,0,0,1,.08-.09.65.65,0,0,1,.9,0,.6.6,0,0,1,.19.45.62.62,0,0,1-.19.46A.64.64,0,0,1,8,12.51Z'/>",
  download:
    "<path d='M4.09,7.65c-.26-.26-.26-.68,0-.94s.68-.26,.94,0l2.39,2.39V2.37c0-.37,.3-.66,.66-.66s.66,.3,.66,.66v6.74l2.39-2.39c.26-.26,.68-.26,.94,0s.26,.68,0,.94l-3.52,3.52c-.13,.13-.3,.19-.47,.19s-.34-.06-.47-.19h0l-3.52-3.52Zm10.85,.85c-.37,0-.66,.3-.66,.66v5.21H1.72v-5.21c0-.37-.3-.66-.66-.66s-.66,.3-.66,.66v5.87c0,.37,.3,.66,.66,.66H14.95c.37,0,.66-.3,.66-.66v-5.87c0-.37-.3-.66-.66-.66Z'/>",
  dragAndDropSimple:
    "<path d='M.59,5.46A1.05,1.05,0,0,1,1.65,4.4h12.7a1.06,1.06,0,1,1,0,2.12H1.65A1.06,1.06,0,0,1,.59,5.46Zm13.76,4H1.65a1.06,1.06,0,1,0,0,2.12h12.7a1.06,1.06,0,0,0,0-2.12Z'/>",
  dragAndDrop:
    "<path d='M13.53,9.62c0,.3-.24,.54-.54,.54H3.01c-.3,0-.54-.24-.54-.54s.24-.54,.54-.54H12.99c.3,0,.54,.24,.54,.54ZM3.01,6.91H12.99c.3,0,.54-.24,.54-.54s-.24-.54-.54-.54H3.01c-.3,0-.54,.24-.54,.54s.24,.54,.54,.54Zm6.45,6.07l-1.45,1.45-1.45-1.45c-.21-.21-.55-.21-.77,0-.21,.21-.21,.55,0,.77l1.84,1.84c.11,.11,.24,.16,.38,.16s.28-.05,.38-.16l1.84-1.84c.21-.21,.21-.55,0-.77-.21-.21-.55-.21-.77,0ZM6.55,3.01l1.45-1.45,1.45,1.45c.11,.11,.24,.16,.38,.16s.28-.05,.38-.16c.21-.21,.21-.55,0-.77l-1.84-1.84c-.2-.2-.56-.2-.77,0l-1.84,1.84c-.21,.21-.21,.55,0,.77,.21,.21,.55,.21,.77,0Z'/>",
  episodic:
    "<path d='m12.04,13.27H3.96c-.69,0-1.25-.56-1.25-1.25v-7.52c0-.69.56-1.25,1.25-1.25h8.08c.69,0,1.25.56,1.25,1.25v7.52c0,.69-.56,1.25-1.25,1.25ZM4.36,4.3c-.31,0-.57.25-.57.57v6.77c0,.31.25.57.57.57h7.33c.31,0,.57-.25.57-.57v-6.77c0-.31-.25-.57-.57-.57h-7.33Zm5.38,3.73c.07.05.11.12.11.21s-.04.16-.11.21l-2.38,1.68s-.1.05-.15.05c-.04,0-.08,0-.12-.03-.08-.05-.14-.13-.14-.22v-3.37c0-.09.06-.19.14-.22.08-.05.2-.04.27.02M1.31,13.34l.74-.19c.24-.06.35-.24.28-.42-.08-.17-.33-.27-.55-.21l-.74.19V3.77l.74.19c.24.06.48-.04.55-.21.08-.17-.05-.36-.28-.42l-.74-.19c-.27-.07-.56-.03-.8.09s-.36.32-.36.54v8.94c0,.21.14.41.36.54.15.08.33.12.52.12.09,0,.19,0,.28-.03ZM14.64,3.16l-.74.19c-.23.06-.35.24-.28.42.08.17.33.27.55.21l.74-.19.05,8.94-.74-.18c-.24-.06-.48.04-.55.21-.08.17.05.36.28.42l.74.18c.27.07.56.03.8-.09s.36-.32.36-.54l-.05-8.94c0-.21-.14-.41-.37-.54-.15-.08-.33-.12-.52-.12-.09,0-.19,0-.28.03h.01Z'/>",
  exclamationCircle:
    "<path d='M8,.19A7.81,7.81,0,1,0,15.81,8,7.81,7.81,0,0,0,8,.19Zm0,14.2A6.39,6.39,0,1,1,14.39,8,6.4,6.4,0,0,1,8,14.39Zm.89-9.76V9.24a.71.71,0,1,1-1.42,0V4.63a.71.71,0,1,1,1.42,0Zm0,6.6a.78.78,0,0,1,0,.14.71.71,0,0,1-.21.5.71.71,0,0,1-1.21-.5.69.69,0,0,1,.21-.5.74.74,0,0,1,1,0l.09.11a.67.67,0,0,1,.06.12A.93.93,0,0,1,8.87,11.23Z'/>",
  exclamationCircleFill:
    "<path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'/>",
  exclamationTriangle:
    "<path d='M8,2.69,1.7,13.63H14.3L8,2.69M8,1.08a1,1,0,0,1,.84.48L15.7,13.47a1,1,0,0,1-.84,1.45H1.14A1,1,0,0,1,.3,13.47L7.16,1.56A1,1,0,0,1,8,1.08Zm0,9.49a.63.63,0,0,1-.64-.64V5.74a.64.64,0,0,1,1.28,0V9.93A.63.63,0,0,1,8,10.57Zm0,1.94a.64.64,0,0,1-.45-.19.62.62,0,0,1-.19-.46.49.49,0,0,1,0-.12l0-.13.07-.11a.32.32,0,0,1,.08-.09.65.65,0,0,1,.9,0,.6.6,0,0,1,.19.45.62.62,0,0,1-.19.46A.64.64,0,0,1,8,12.51Z'/>",
  exclamationTriangleFill:
    "<path d='M15.6,12,10,1.78a2.27,2.27,0,0,0-4,0L.4,12a2.33,2.33,0,0,0-.28,1.09A2.27,2.27,0,0,0,2.39,15.4H13.61a2.33,2.33,0,0,0,1.09-.28A2.27,2.27,0,0,0,15.6,12Zm-6.68.68a1.23,1.23,0,0,1-.25.36,1,1,0,0,1-.37.25,1.22,1.22,0,0,1-.43.08,1.26,1.26,0,0,1-.43-.08,1.15,1.15,0,0,1-.37-.25,1.13,1.13,0,0,1,0-1.6,1.16,1.16,0,0,1,1.6,0,1.1,1.1,0,0,1,.34.8A1.12,1.12,0,0,1,8.92,12.72ZM9,8.63a1.14,1.14,0,0,1-2.27,0v-3A1.14,1.14,0,1,1,9,5.61Z'/>",

  eyeFill:
    "<path d='M10.5,8c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5,1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5ZM0,8S3,2.5,8,2.5s8,5.5,8,5.5c0,0-3,5.5-8,5.5S0,8,0,8Zm8,3.5c1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5-3.5,1.57-3.5,3.5,1.57,3.5,3.5,3.5Z'/>",

  eyeSlashFill:
    "<path d='M5.53,7.95s0,0,0,0h0s0,0,0,0Zm2.12,2.83c.23,.03,.47,.03,.71,0l-2.83-2.83c-.2,1.37,.75,2.63,2.12,2.83ZM1.65,2.66L13.65,14.66l.71-.71L2.35,1.95l-.71,.71Zm6,3.17l2.83,2.83h0c.03-.24,.03-.47,0-.71-.2-1.37-1.46-2.32-2.83-2.12Zm1.53-.82c1.82,.65,2.77,2.65,2.12,4.47l2.06,2.06c1.7-1.52,2.64-3.24,2.64-3.24,0,0-3-5.5-8-5.5-.96,0-1.91,.2-2.79,.59h0s1.61,1.61,1.61,1.61c.76-.27,1.59-.27,2.36,0Zm-4.48,4.48c-.27-.76-.27-1.59,0-2.36l-2.06-2.06c-1.7,1.52-2.64,3.24-2.64,3.24,0,0,3,5.5,8,5.5,.96,0,1.91-.2,2.79-.59l-1.61-1.61c-1.82,.65-3.82-.3-4.47-2.12Z'/>",
  file: "<path d='M8.91,.22c-.09-.04-.18-.05-.27-.05H3.65c-.29,0-.56,.06-.82,.16-.26,.11-.5,.27-.69,.46-.2,.2-.36,.43-.46,.69-.1,.25-.16,.53-.16,.82V13.7c0,.29,.06,.56,.16,.82,.11,.26,.27,.5,.46,.69s.43,.36,.69,.46c.25,.1,.53,.16,.82,.16H12.19c.29,0,.56-.06,.82-.16,.26-.11,.5-.27,.69-.46s.36-.43,.46-.69c.1-.25,.16-.53,.16-.82V5.86c0-.2-.08-.37-.21-.5L9.13,.38s0,0,0,0c-.07-.07-.15-.12-.23-.15h0Zm2.99,4.93h-2.55V2.6l2.55,2.55ZM7.92,1.59V5.86c0,.39,.32,.71,.71,.71h4.27v7.12c0,.1-.02,.19-.05,.27-.04,.09-.09,.17-.16,.23s-.14,.12-.23,.16c-.08,.03-.17,.05-.27,.05H3.65c-.1,0-.19-.02-.27-.05-.09-.04-.17-.09-.23-.16s-.12-.14-.16-.23c-.03-.08-.05-.17-.05-.27V2.3c0-.1,.02-.19,.05-.27,.04-.09,.09-.17,.16-.23s.14-.12,.23-.16c.08-.03,.17-.05,.27-.05H7.92Z'/>",
  film: "<path d='m9.44,9.33c.07.05.11.12.11.21s-.04.16-.11.21l-2.4,1.7s-.11.05-.15.05c-.04,0-.09,0-.12-.03-.09-.05-.14-.13-.14-.23v-3.39c0-.1.06-.19.14-.23.09-.05.2-.04.28.02m7.28-2.68H3.63s10.6-1.48,10.6-1.48c.45-.07.77-.49.71-.94l-.24-1.65c-.03-.22-.14-.41-.32-.55s-.4-.19-.61-.16L1.42,1.91c-.45.07-.77.49-.71.94l.24,1.65c.04.27.22.49.45.61-.22.15-.38.39-.38.67v7.48c0,.46.38.83.83.83h12.47c.46,0,.83-.38.83-.83v-7.48c0-.46-.38-.83-.83-.83Zm-.44-3.96l.24,1.65-2.56.36h0l1.32-1.86s1.01-.14,1.01-.14Zm-1.86.26l-1.32,1.86-2.82.4h0l1.32-1.86s2.82-.4,2.82-.4Zm-3.67.52h0l-1.32,1.86-2.83.4h0l1.32-1.86,2.83-.4ZM1.76,4.37l-.24-1.65,3.15-.44-1.32,1.86s-1.59.23-1.59.23Zm12.55,8.9H1.85v-7.48h12.47v7.48Z'/>",
  filter:
    "<path d='M.77,4.12h1.7a2,2,0,0,1-.05-.24,2.58,2.58,0,1,0,2.83-3A1.33,1.33,0,0,0,5,.89c-.13,0-.26,0-.38,0A2.59,2.59,0,0,0,2.43,3c0-.06,0-.12,0-.17H.77a.65.65,0,1,0,0,1.29ZM5,2.18a1.3,1.3,0,1,1-1.3,1.29A1.29,1.29,0,0,1,5,2.18ZM7.55,3.47a2.67,2.67,0,0,0-.09-.64h7.78a.65.65,0,0,1,0,1.29H7.46A2.76,2.76,0,0,0,7.55,3.47ZM4,12.53a2.25,2.25,0,0,0,.1.64H.76a.65.65,0,0,1,0-1.29H4.11A2.32,2.32,0,0,0,4,12.53Zm11-.65H9.1a2.33,2.33,0,0,1,.08.59,2.59,2.59,0,1,0-3.1,2.59,2.24,2.24,0,0,0,.52,0,2.24,2.24,0,0,0,.52,0A2.59,2.59,0,0,0,9.17,12.7a2.42,2.42,0,0,1-.07.47H15a.65.65,0,1,0,0-1.29ZM6.6,13.82a1.3,1.3,0,1,1,1.29-1.29A1.29,1.29,0,0,1,6.6,13.82ZM15.88,8a.64.64,0,0,1-.64.65H13.88A2.75,2.75,0,0,0,14,7.84a2.63,2.63,0,0,0,0-.49h1.27A.64.64,0,0,1,15.88,8ZM7.55,3.47a2.58,2.58,0,0,1-5.13.41,2,2,0,0,0,.05.24,2.57,2.57,0,0,0,5,0,2.76,2.76,0,0,0,.09-.65,2.67,2.67,0,0,0-.09-.64A2.55,2.55,0,0,0,5.25.92,2.57,2.57,0,0,1,7.55,3.47ZM4.59.92A2.6,2.6,0,0,0,2.47,2.83s0,.11,0,.17A2.59,2.59,0,0,1,4.59.92ZM4,12.53a2.59,2.59,0,0,1,5.17-.06,2.33,2.33,0,0,0-.08-.59,2.57,2.57,0,0,0-5,0,2.32,2.32,0,0,0-.1.65,2.25,2.25,0,0,0,.1.64,2.57,2.57,0,0,0,2,1.89A2.58,2.58,0,0,1,4,12.53Zm5.16.17a2.42,2.42,0,0,1-.07.47,2.6,2.6,0,0,1-2,1.89A2.59,2.59,0,0,0,9.17,12.7ZM8.89,8.27a2.36,2.36,0,0,0,.1.38,2.55,2.55,0,0,0,1.92,1.72A2.58,2.58,0,0,1,8.89,8.27ZM14,7.35a2.58,2.58,0,0,0-2.25-2.07,2.58,2.58,0,0,1,.22,5.09,2.57,2.57,0,0,0,1.94-1.72A2.75,2.75,0,0,0,14,7.84,2.63,2.63,0,0,0,14,7.35Zm-5.08,0h0a2.59,2.59,0,0,1,2.17-2.06A2.59,2.59,0,0,0,8.89,7.36ZM14,7.84a2.59,2.59,0,0,0-2.3-2.56,2.85,2.85,0,0,0-.29,0,2.21,2.21,0,0,0-.37,0A2.59,2.59,0,0,0,8.89,7.36H.77a.65.65,0,0,0,0,1.3H9a2.36,2.36,0,0,1-.1-.38,2.58,2.58,0,0,0,2,2.1,2.24,2.24,0,0,0,.52.06,2.1,2.1,0,0,0,.51-.06A2.59,2.59,0,0,0,14,7.84ZM11.43,9.13a1.3,1.3,0,1,1,1.3-1.29A1.29,1.29,0,0,1,11.43,9.13Z' />",
  filter2:
    "<path d='M8.52,11.99H1.31c-.43,0-.79-.35-.79-.79s.35-.79,.79-.79h7.21c-.08,.25-.13,.51-.13,.79s.05,.54,.13,.79Zm6.17-1.57h-.91c.08,.25,.13,.51,.13,.79s-.05,.54-.13,.79h.91c.43,0,.79-.35,.79-.79s-.35-.79-.79-.79Zm-.79,.79c0-1.52-1.24-2.75-2.75-2.75s-2.75,1.24-2.75,2.75,1.24,2.75,2.75,2.75,2.75-1.24,2.75-2.75Zm-1.57,0c0,.65-.53,1.18-1.18,1.18s-1.18-.53-1.18-1.18,.53-1.18,1.18-1.18,1.18,.53,1.18,1.18ZM7.61,4.8c0,.28-.05,.54-.13,.79h7.21c.43,0,.79-.35,.79-.79s-.35-.79-.79-.79H7.48c.08,.25,.13,.51,.13,.79Zm-6.29,.79h.91c-.08-.25-.13-.51-.13-.79s.05-.54,.13-.79h-.91c-.43,0-.79,.35-.79,.79s.35,.79,.79,.79Zm.79-.79c0,1.52,1.24,2.75,2.75,2.75s2.75-1.24,2.75-2.75-1.24-2.75-2.75-2.75-2.75,1.24-2.75,2.75Zm1.57,0c0-.65,.53-1.18,1.18-1.18s1.18,.53,1.18,1.18-.53,1.18-1.18,1.18-1.18-.53-1.18-1.18Z'/>",
  gear: "<path d='M15.84,7.56A.83.83,0,0,0,15,6.75l-1.37,0a5.8,5.8,0,0,0-.76-1.87l1.07-1.07a.82.82,0,0,0,0-1.17l-.6-.61a.83.83,0,0,0-1.17,0L11.11,3.11a6,6,0,0,0-1.86-.79V1A.83.83,0,0,0,8.42.15H7.57A.83.83,0,0,0,6.74,1V2.3a5.76,5.76,0,0,0-1.9.77l-1-1a.83.83,0,0,0-1.17,0l-.6.61a.83.83,0,0,0,0,1.17l1,1a5.76,5.76,0,0,0-.79,1.91H1a.83.83,0,0,0-.83.82v.86A.83.83,0,0,0,1,9.25H2.27a5.94,5.94,0,0,0,.8,1.9l-1,1a.83.83,0,0,0,0,1.17l.6.61a.83.83,0,0,0,1.17,0l1-1a5.64,5.64,0,0,0,1.88.76V15a.83.83,0,0,0,.83.83h.85A.83.83,0,0,0,9.25,15V13.65a5.68,5.68,0,0,0,1.84-.78L12.17,14a.83.83,0,0,0,1.17,0l.6-.61a.82.82,0,0,0,0-1.17l-1.08-1.08a5.78,5.78,0,0,0,.75-1.81l1.43,0a.81.81,0,0,0,.81-.84Zm-7.9,3.75A3.33,3.33,0,1,1,11.27,8,3.33,3.33,0,0,1,7.94,11.31Z'/>",
  grid: "<path d='M1.06,2.77H6.23a.9.9,0,0,1,.9.9V6.23a.9.9,0,0,1-.9.9H1.06a.9.9,0,0,1-.9-.9V3.67A.9.9,0,0,1,1.06,2.77Zm8.71,0h5.17a.9.9,0,0,1,.9.9V6.23a.9.9,0,0,1-.9.9H9.77a.9.9,0,0,1-.9-.9V3.67A.9.9,0,0,1,9.77,2.77ZM1.06,8.87H6.23a.9.9,0,0,1,.9.9v2.56a.9.9,0,0,1-.9.9H1.06a.9.9,0,0,1-.9-.9V9.77A.9.9,0,0,1,1.06,8.87Zm8.71,0h5.17a.9.9,0,0,1,.9.9v2.56a.9.9,0,0,1-.9.9H9.77a.9.9,0,0,1-.9-.9V9.77A.9.9,0,0,1,9.77,8.87Z'/>",

  geoFill:
    "<path d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z'/>",
  home: "<path d='M15.91,5.56V15.91H.09V5.56L8,.09Z'/>",
  info: "<path d='M8,15.81A7.81,7.81,0,1,0,.19,8,7.81,7.81,0,0,0,8,15.81ZM8,1.61A6.39,6.39,0,1,1,1.61,8,6.4,6.4,0,0,1,8,1.61Zm-.89,9.76V6.76a.71.71,0,0,1,1.42,0v4.61a.71.71,0,0,1-1.42,0Zm0-6.6a.78.78,0,0,1,0-.14.71.71,0,0,1,.21-.5.71.71,0,0,1,1.21.5.69.69,0,0,1-.21.5.74.74,0,0,1-1,0L7.23,5a.67.67,0,0,1-.06-.12A.93.93,0,0,1,7.13,4.77Z'/>",
  key: "<path d='M8,.06A7.94,7.94,0,0,0,8,15.94H8A7.94,7.94,0,0,0,8,.06ZM8,14.94A6.94,6.94,0,1,1,14.94,8,6.94,6.94,0,0,1,8,14.94ZM9.91,5.81A2.27,2.27,0,1,0,7.12,8v4.48a.52.52,0,0,0,.52.52H9.3V12H8.16v-.56H9.3v-1H8.16V9.85H9.3v-1H8.16V8A2.27,2.27,0,0,0,9.91,5.81ZM7.82,7a.39.39,0,0,0-.18,0,.41.41,0,0,0-.19,0,1.23,1.23,0,1,1,.37,0Z'/>",
  link: "<path d='M14.77,6.16,11.35,9.58a3.55,3.55,0,0,1-3.79.78A3.19,3.19,0,0,1,6,9.05L6,9a.83.83,0,0,1,.24-1.09.82.82,0,0,1,1.16.19l0,.06a1.79,1.79,0,0,0,1.24.78,1.86,1.86,0,0,0,1.6-.57L13.6,5a1.82,1.82,0,0,0-.13-2.68,1.91,1.91,0,0,0-2.54.2L8.4,5h0A3.79,3.79,0,0,0,6,5.12a2.8,2.8,0,0,1,.39-.46L9.84,1.24a3.48,3.48,0,1,1,4.93,4.92ZM7.63,11h0L5,13.59A1.82,1.82,0,0,1,2.4,11L5.83,7.6a1.82,1.82,0,0,1,2.57,0,1.71,1.71,0,0,1,.21.26l0,.06a.82.82,0,0,0,1.16.19A.83.83,0,0,0,10.05,7L10,7A3.19,3.19,0,0,0,8.44,5.64a3.55,3.55,0,0,0-3.79.78L1.23,9.84a3.48,3.48,0,1,0,4.93,4.92l3.41-3.42a2.8,2.8,0,0,0,.39-.46A3.79,3.79,0,0,1,7.63,11Z'/>",
  linkExt:
    "<path d='M14.84.12H10.63a.75.75,0,0,0,0,1.5H13.1L6.73,8A.75.75,0,0,0,7.79,9.05l6.3-6.3v2.4a.75.75,0,1,0,1.5,0V.87A.75.75,0,0,0,14.84.12Z M12.64,13.66h0a.72.72,0,0,1-.72.72H2.59a.71.71,0,0,1-.71-.72V4.32a.72.72,0,0,1,.71-.72H7.76V2.17H2.59A2.15,2.15,0,0,0,.44,4.32v9.34a2.15,2.15,0,0,0,2.15,2.15h9.33a2.15,2.15,0,0,0,2.15-2.15V8.09H12.64Z'/>",
  list: "<path d='M6.36,4.75A1.25,1.25,0,0,1,7.61,3.5h7a1.25,1.25,0,0,1,0,2.5h-7A1.25,1.25,0,0,1,6.36,4.75ZM14.58,10h-7a1.25,1.25,0,1,0,0,2.5h7a1.25,1.25,0,0,0,0-2.5ZM3.89,2.42H1.1a.93.93,0,0,0-.93.93V6.14a.93.93,0,0,0,.93.93H3.89a.93.93,0,0,0,.93-.93V3.35A.93.93,0,0,0,3.89,2.42Zm0,6.51H1.1a.93.93,0,0,0-.93.93v2.79a.93.93,0,0,0,.93.93H3.89a.93.93,0,0,0,.93-.93V9.86A.93.93,0,0,0,3.89,8.93Z'/>",
  listClose:
    "<path d='M12.21,1.2h0a1.63,1.63,0,0,1,1.63,1.63v4h0v-4A1.63,1.63,0,0,0,12.21,1.2ZM1.86,12.08h0a.54.54,0,0,1-.53-.55V2.81a.54.54,0,0,1,.55-.52H12.21a.55.55,0,0,1,.55.54V6.32l.33.11.27.15a4.76,4.76,0,0,1,.45.24l0,0v-4A1.63,1.63,0,0,0,12.21,1.2H1.88A1.63,1.63,0,0,0,.25,2.83v8.7h0a1.63,1.63,0,0,0,1.63,1.63H8a4.18,4.18,0,0,1-.66-1.09Zm8.72-7.62H6.22A.54.54,0,0,0,5.68,5h0a.55.55,0,0,0,.54.56h4.36A.55.55,0,0,0,11.12,5,.54.54,0,0,0,10.58,4.46Zm-4.9,4.9a.54.54,0,0,0,.54.54h.85a4.35,4.35,0,0,1,.27-1.09H6.22A.55.55,0,0,0,5.68,9.36Zm0-2.17a.54.54,0,0,0,.54.54H8A4.51,4.51,0,0,1,9.28,6.64H6.22A.55.55,0,0,0,5.68,7.19Zm-1.9-3A.81.81,0,1,0,4.59,5,.81.81,0,0,0,3.78,4.2Zm0,2.18a.81.81,0,1,0,.81.81A.81.81,0,0,0,3.78,6.38Zm0,2.17a.81.81,0,1,0,.81.81A.81.81,0,0,0,3.78,8.55Zm10-1.73c.13.09.25.19.37.29a2.91,2.91,0,0,0-.33-.26h0Zm.42.32a4.37,4.37,0,0,1,1.51,3.28h0a4.33,4.33,0,0,1-1.18,3,4.33,4.33,0,0,0-.33-6.28Zm1.51,3.29a4.33,4.33,0,0,1-1.18,3l-.08.09a.73.73,0,0,0-.09.08,4.36,4.36,0,0,1-3,1.21h0A4.31,4.31,0,0,1,8,13.17a4.28,4.28,0,0,1-1-2.71,5.29,5.29,0,0,1,.05-.56,4.35,4.35,0,0,1,.27-1.09A4.37,4.37,0,0,1,8,7.73,4.51,4.51,0,0,1,9.28,6.64a4.31,4.31,0,0,1,2.08-.56,4.62,4.62,0,0,1,1.4.24l.33.11.27.15a4.76,4.76,0,0,1,.45.24c.13.09.25.19.37.29l.05,0a4.37,4.37,0,0,1,1.51,3.28Zm-1.08,0a3.26,3.26,0,0,0-3.25-3.27h0a3.27,3.27,0,1,0,3.26,3.28ZM11.78,9a.57.57,0,0,0-.13-.08l.12.08a.54.54,0,0,1,0,.76l-.37.37,1.23,1.24a.44.44,0,0,0,.37.16.48.48,0,0,0,.41-.15.56.56,0,0,0,0-.76Zm0,0-.12-.08A.53.53,0,0,0,11,9L9.38,10.61a.57.57,0,0,0-.16.39.54.54,0,0,0,.55.53.47.47,0,0,0,.37-.14l1.26-1.26.37-.37A.54.54,0,0,0,11.77,9Z'/>",
  listOpen:
    "<path d='M15.75,10.44a4.31,4.31,0,0,1-1.2,3,4.28,4.28,0,0,0,1.19-3h0a4.37,4.37,0,0,0-1.51-3.28A4.34,4.34,0,0,1,15.75,10.44ZM13.84,2.83v4l0,0,.37.28a4.08,4.08,0,0,0-.33-.25v-4A1.63,1.63,0,0,0,12.22,1.2h0A1.63,1.63,0,0,1,13.84,2.83ZM10.58,5.56h0A.55.55,0,0,0,11.12,5a.54.54,0,0,0-.54-.54H6.22A.54.54,0,0,0,5.68,5h0a.55.55,0,0,0,.54.56ZM3.78,4.2A.81.81,0,1,0,4.59,5,.81.81,0,0,0,3.78,4.2Zm0,2.18a.81.81,0,1,0,.81.81A.81.81,0,0,0,3.78,6.38Zm0,2.17a.81.81,0,1,0,.81.81A.81.81,0,0,0,3.78,8.55Zm12,1.88a4.28,4.28,0,0,1-1.19,3,.31.31,0,0,1-.07.09l-.1.08a4.32,4.32,0,0,1-3,1.21h0A4.31,4.31,0,0,1,8,13.17H1.88A1.63,1.63,0,0,1,.25,11.54h0V2.83A1.63,1.63,0,0,1,1.88,1.2H12.21a1.63,1.63,0,0,1,1.63,1.63v4l0,0,.37.28.05,0a4.37,4.37,0,0,1,1.51,3.28ZM5.68,7.19a.55.55,0,0,1,.54-.55H9.28a4.31,4.31,0,0,1,2.08-.56,4.62,4.62,0,0,1,1.4.24V2.83a.55.55,0,0,0-.55-.54H1.88a.54.54,0,0,0-.55.52v8.72a.54.54,0,0,0,.53.55H7.35A4.19,4.19,0,0,1,7,10.46a5.29,5.29,0,0,1,.05-.56H6.22a.54.54,0,0,1-.54-.54.55.55,0,0,1,.54-.55H7.34A4.37,4.37,0,0,1,8,7.73H6.22A.54.54,0,0,1,5.68,7.19Zm9,3.27a3.26,3.26,0,0,0-3.25-3.27h0a3.27,3.27,0,1,0,3.26,3.28ZM13,9.36a.55.55,0,0,0-.36.15l-1.25,1.25L10.15,9.52a.53.53,0,0,0-.76,0,.54.54,0,0,0,0,.76L11,11.91a.56.56,0,0,0,.76,0l0,0,1.59-1.59a.57.57,0,0,0,.16-.39A.55.55,0,0,0,13,9.36Z'/>",
  lock: "<path d='M13,7.2h-.86v-1.66c.2-2.25-1.42-4.24-3.62-4.45-.17-.02-.34-.02-.51-.01-2.21-.09-4.08,1.67-4.16,3.93,0,.18,0,.35,.01,.53v1.66h-.86c-.55,0-1,.46-1,1.02v6.12c0,.56,.45,1.02,1,1.02H13c.55,0,1-.46,1-1.02v-6.12c0-.56-.45-1.02-1-1.02Zm-7.19-1.4c0-1.86,.9-2.76,2.16-2.76s2.16,.9,2.16,2.76v1.4H5.82v-1.4Z'/>",
  logIn:
    "<path d='M8.34,11.34l2.71-2.72A.82.82,0,0,0,11.29,8a.8.8,0,0,0-.24-.57L8.33,4.75a.82.82,0,0,0-1.15,0,.8.8,0,0,0-.24.57.82.82,0,0,0,.24.58L9.32,8.05,7.19,10.19a.81.81,0,1,0,1.15,1.15ZM10.21,8a.81.81,0,0,0-.81-.81H2.63a.82.82,0,0,0,0,1.63H9.4A.81.81,0,0,0,10.21,8ZM6.93,1.25a.82.82,0,0,0,.82.82h5.07V14H7.75a.82.82,0,0,0,0,1.64h5.89a.82.82,0,0,0,.81-.82V1.25a.81.81,0,0,0-.81-.81H7.75A.82.82,0,0,0,6.93,1.25Z'/>",
  logOut:
    "<path d='M13.56,8,10.85,5.29,13.56,8l-2.71,2.72Zm-2.14,3.29,2.72-2.71a.82.82,0,0,0,0-1.16L11.42,4.71a.81.81,0,0,0-1.15,0,.81.81,0,0,0,0,1.15L12.41,8l-2.14,2.14a.82.82,0,0,0-.24.58.8.8,0,0,0,.24.57.81.81,0,0,0,1.15,0ZM13.3,8a.82.82,0,0,0-.82-.82H5.72a.82.82,0,0,0,0,1.64h6.76A.82.82,0,0,0,13.3,8Zm-4,6.8A.82.82,0,0,0,8.45,14H3.37V2H8.45a.82.82,0,0,0,0-1.64H2.56a.82.82,0,0,0-.82.82V14.8a.82.82,0,0,0,.82.82H8.45A.82.82,0,0,0,9.26,14.8Z'/>",
  menu: "<path d='M.69,4h14.6a.6.6,0,0,0,.6-.6.6.6,0,0,0-.6-.6H.69a.6.6,0,0,0-.6.6A.6.6,0,0,0,.69,4Z M15.29,7.58H.69a.61.61,0,0,0,0,1.21h14.6a.61.61,0,0,0,0-1.21Z M11.42,12.39H.69a.61.61,0,0,0,0,1.21H11.42a.61.61,0,1,0,0-1.21Z'/>",
  pauseCircle:
    "<path d='M15.93,8c0-1.07-.21-2.1-.6-3.03-.4-.97-.99-1.84-1.72-2.57s-1.6-1.32-2.57-1.72c-.94-.39-1.96-.6-3.03-.6s-2.1,.21-3.03,.6c-.97,.4-1.84,.99-2.57,1.72s-1.32,1.6-1.72,2.57C.29,5.9,.07,6.93,.07,8s.21,2.1,.6,3.03c.4,.97,.99,1.84,1.72,2.57s1.6,1.32,2.57,1.72c.94,.39,1.96,.6,3.03,.6s2.1-.21,3.03-.6c.97-.4,1.84-.99,2.57-1.72s1.32-1.6,1.72-2.57c.39-.94,.6-1.96,.6-3.03Zm-1.44,0c0,.88-.18,1.72-.49,2.48-.33,.79-.81,1.51-1.41,2.11s-1.31,1.08-2.11,1.41c-.76,.32-1.6,.49-2.48,.49s-1.72-.18-2.48-.49c-.79-.33-1.51-.81-2.11-1.41-.6-.6-1.08-1.31-1.41-2.11-.32-.76-.49-1.6-.49-2.48s.18-1.72,.49-2.48c.33-.79,.81-1.51,1.41-2.11s1.31-1.08,2.11-1.41c.76-.32,1.6-.49,2.48-.49s1.72,.18,2.48,.49c.79,.33,1.51,.81,2.11,1.41,.6,.6,1.08,1.31,1.41,2.11,.32,.76,.49,1.6,.49,2.48Zm-7.21,2.16V5.84c0-.4-.32-.72-.72-.72s-.72,.32-.72,.72v4.32c0,.4,.32,.72,.72,.72s.72-.32,.72-.72Zm2.88,0V5.84c0-.4-.32-.72-.72-.72s-.72,.32-.72,.72v4.32c0,.4,.32,.72,.72,.72s.72-.32,.72-.72Z'/>",
  pencil:
    "<path d='M14.82,2.9,13.11,1.2a1.36,1.36,0,0,0-1.9,0L1,11.45A.71.71,0,0,0,.82,12v2.51a.72.72,0,0,0,.72.72H4.05A.71.71,0,0,0,4.56,15L14.83,4.8A1.34,1.34,0,0,0,14.82,2.9ZM12.11,5.45,10.57,3.91l1.59-1.6,1.56,1.54ZM2.27,13.74V12.26L9.55,4.94l1.53,1.53L3.75,13.74Z'/>",
  play: "<path d='M2.95,.31c-.12-.08-.27-.12-.42-.12-.43,0-.78,.35-.78,.78V15.03c0,.14,.04,.29,.12,.42,.23,.36,.72,.47,1.08,.23l10.94-7.03c.09-.06,.17-.14,.23-.23,.23-.36,.13-.85-.23-1.08L2.95,.31Zm.36,2.09L12.03,8,3.31,13.6V2.4Z'/>",

  playCircle:
    "<path d='M15.91,8c0-1.07-.21-2.09-.6-3.02-.4-.97-.99-1.84-1.72-2.57s-1.6-1.32-2.57-1.72c-.93-.39-1.95-.6-3.02-.6s-2.09,.21-3.02,.6c-.97,.4-1.84,.99-2.57,1.72s-1.32,1.6-1.72,2.57c-.39,.93-.6,1.95-.6,3.02s.21,2.09,.6,3.02c.4,.97,.99,1.84,1.72,2.57s1.6,1.32,2.57,1.72c.93,.39,1.95,.6,3.02,.6s2.09-.21,3.02-.6c.97-.4,1.84-.99,2.57-1.72s1.32-1.6,1.72-2.57c.39-.93,.6-1.95,.6-3.02Zm-1.44,0c0,.88-.17,1.71-.49,2.47-.33,.79-.81,1.5-1.41,2.1s-1.31,1.08-2.1,1.41c-.76,.31-1.6,.49-2.47,.49s-1.71-.17-2.47-.49c-.79-.33-1.5-.81-2.1-1.41-.6-.6-1.08-1.31-1.41-2.1-.31-.76-.49-1.6-.49-2.47s.17-1.71,.49-2.47c.33-.79,.81-1.5,1.41-2.1s1.31-1.08,2.1-1.41c.76-.31,1.6-.49,2.47-.49s1.71,.17,2.47,.49c.79,.33,1.5,.81,2.1,1.41,.6,.6,1.08,1.31,1.41,2.1,.31,.76,.49,1.6,.49,2.47Zm-7.51-3.47c-.11-.08-.25-.12-.4-.12-.4,0-.72,.32-.72,.72v5.75c0,.13,.04,.28,.12,.4,.22,.33,.67,.42,1,.2l4.31-2.88c.07-.05,.14-.12,.2-.2,.22-.33,.13-.78-.2-1l-4.31-2.87Zm.32,1.94l2.3,1.53-2.3,1.53v-3.06Z'/>",
  playFill:
    "<path d='M14.43,6.93a1.24,1.24,0,0,1,.45,1.69,1.21,1.21,0,0,1-.45.45L3.27,15.58a1.24,1.24,0,0,1-1.86-1.07v-13A1.25,1.25,0,0,1,2.65.25a1.23,1.23,0,0,1,.62.17Z'/>",

  playlist:
    "<path d='M13,2.67H1A.82.82,0,0,1,1,1H13a.82.82,0,1,1,0,1.64ZM7.82,6A.82.82,0,0,0,7,5.13H1A.82.82,0,0,0,1,6.77H7A.82.82,0,0,0,7.82,6Zm0,4.1A.82.82,0,0,0,7,9.23H1a.82.82,0,0,0,0,1.64H7A.82.82,0,0,0,7.82,10.05Zm0,4.1A.82.82,0,0,0,7,13.33H1A.82.82,0,0,0,1,15H7A.82.82,0,0,0,7.82,14.15Zm3.8-8.59a.84.84,0,0,0-.46-.13.82.82,0,0,0-.82.82V11.4a.8.8,0,0,0,.14.45.82.82,0,0,0,1.14.23L15.46,9.5a.8.8,0,0,0,.23-.22.82.82,0,0,0-.23-1.14Z'/>",

  playlistAdd:
    "<path d='M12.71,3.2H.9a.74.74,0,1,1,0-1.47H12.71a.74.74,0,1,1,0,1.47ZM6.31,6a.74.74,0,0,0-.74-.74H.9a.74.74,0,0,0,0,1.48H5.57A.74.74,0,0,0,6.31,6Zm0,7.08a.74.74,0,0,0-.74-.74H.9a.74.74,0,0,0,0,1.48H5.57A.74.74,0,0,0,6.31,13.09ZM5.42,9.55a.74.74,0,0,0-.73-.74H.9a.74.74,0,1,0,0,1.48H4.69A.74.74,0,0,0,5.42,9.55ZM11.68,7H10.55v4.82h1.13Zm1.84,1.84H8.71V10h4.81Zm2.26.67a4.66,4.66,0,1,0-4.66,4.66A4.66,4.66,0,0,0,15.78,9.55Zm-1.2,0a3.46,3.46,0,1,1-3.46-3.46A3.47,3.47,0,0,1,14.58,9.55Z'/>",
  playlistManual:
    "<path d='M2.89,6.63c0-.31,.25-.57,.57-.57h6.46c.31,0,.57,.25,.57,.57s-.25,.57-.57,.57H3.46c-.31,0-.57-.25-.57-.57Zm2.46,4.89h-1.89c-.31,0-.57,.25-.57,.57s.25,.57,.57,.57h1.89c.31,0,.57-.25,.57-.57s-.25-.57-.57-.57Zm-1.89-7.05H12.56c.31,0,.57-.25,.57-.57s-.25-.57-.57-.57H3.46c-.31,0-.57,.25-.57,.57s.25,.57,.57,.57Zm10.72,3.62l-4.34,4.39c-.09,.09-.2,.14-.32,.14h-1.06c-.25,0-.46-.2-.46-.46v-1.08c0-.12,.05-.23,.13-.32l4.3-4.42c.13-.14,.31-.21,.5-.22,.18,0,.37,.07,.51,.2l.73,.74c.28,.29,.28,.73,0,1.02Zm-1.99,.72l-.45-.45-2.83,2.9v.44h.41l2.86-2.9Zm1.21-1.23l-.45-.46-.57,.58,.45,.45,.57-.58Zm-6.17,1.2H3.46c-.31,0-.57,.25-.57,.57s.25,.57,.57,.57h3.78c.31,0,.57-.25,.57-.57s-.25-.57-.57-.57Z'/>",
  playlistSmart:
    "<path d='M12.57,4.47H3.46c-.31,0-.57-.25-.57-.57s.25-.57,.57-.57H12.57c.31,0,.57,.25,.57,.57s-.25,.57-.57,.57Zm-4.93,2.16c0-.31-.25-.57-.57-.57H3.46c-.31,0-.57,.25-.57,.57s.25,.57,.57,.57h3.61c.31,0,.57-.25,.57-.57Zm0,5.46c0-.31-.25-.57-.57-.57H3.46c-.31,0-.57,.25-.57,.57s.25,.57,.57,.57h3.61c.31,0,.57-.25,.57-.57Zm-.68-2.73c0-.31-.25-.57-.57-.57H3.46c-.31,0-.57,.25-.57,.57s.25,.57,.57,.57h2.92c.31,0,.57-.25,.57-.57Zm4.67-1.16c-.63,0-1.15,.52-1.15,1.15s.52,1.15,1.15,1.15,1.15-.52,1.15-1.15-.52-1.15-1.15-1.15Zm3.67,1.38c0,.25-.2,.46-.45,.46h-.81s0,.01,0,.01c-.05,.17-.12,.34-.21,.5h0s.52,.52,.52,.52c.18,.18,.18,.47,0,.64l-.33,.33c-.18,.18-.47,.18-.64,0l-.52-.52h0c-.16,.09-.34,.17-.52,.22h0v.78c0,.25-.2,.46-.46,.46h-.47c-.25,0-.46-.2-.46-.46v-.77h0c-.21-.06-.4-.14-.58-.24h0s-.52,.52-.52,.52c-.18,.18-.47,.18-.64,0l-.33-.33c-.18-.18-.18-.47,0-.64l.53-.53h0c-.04-.07-.07-.14-.1-.21-.02-.04-.03-.09-.05-.13-.02-.05-.04-.1-.05-.16h-.74c-.25,0-.46-.2-.46-.46v-.47c0-.25,.2-.46,.46-.46h.73c.06-.2,.13-.38,.23-.56l-.55-.55c-.18-.18-.18-.47,0-.64l.33-.33c.18-.18,.47-.18,.64,0l.56,.56c.17-.09,.35-.17,.55-.22v-.75c0-.25,.2-.46,.46-.46h.47c.25,0,.46,.2,.46,.46v.76h0c.16,.05,.31,.11,.45,.18h0s.54-.54,.54-.54c.18-.18,.47-.18,.64,0l.33,.33c.18,.18,.18,.47,0,.64l-.51,.51h0c.11,.19,.2,.39,.26,.61h.77c.25-.02,.46,.18,.46,.43v.47Z'/>",
  playlistStack:
    "<path d='M15.05.62H5.91a.62.62,0,0,0-.62.62V2.48H3.43a.62.62,0,0,0-.62.62V4.34H1A.62.62,0,0,0,.33,5v9.13a.62.62,0,0,0,.62.62h9.14a.62.62,0,0,0,.62-.62V12.85h1.86a.62.62,0,0,0,.62-.62V11h1.86a.62.62,0,0,0,.62-.62V1.24A.62.62,0,0,0,15.05.62ZM9.47,13.47H1.57V5.58h7.9ZM12,11.61H10.71V5a.62.62,0,0,0-.62-.62h-6V3.72H12Zm2.48-1.86H13.19V3.1a.62.62,0,0,0-.62-.62h-6V1.86h7.9ZM4.13,11.42a.33.33,0,0,1,0-.17V7.8a.31.31,0,0,1,.31-.31.32.32,0,0,1,.16,0L7.32,9.26a.31.31,0,0,1,.1.43.32.32,0,0,1-.1.1L4.56,11.51A.31.31,0,0,1,4.13,11.42Z'/>",
  playMonitor:
    "<path d='M14.35,2.3H1.63A1.34,1.34,0,0,0,.29,3.64v8.7a1.34,1.34,0,0,0,1.34,1.34H14.35a1.34,1.34,0,0,0,1.34-1.34V3.64A1.34,1.34,0,0,0,14.35,2.3Zm-2.7,6.05a.73.73,0,0,1-.22.21l-4.76,3a.67.67,0,0,1-.92-.22.6.6,0,0,1-.1-.35V5a.67.67,0,0,1,.67-.66.64.64,0,0,1,.35.1l4.76,3A.69.69,0,0,1,11.65,8.35Z'/>",

  plus: "<path d='M6.51,1.72V14.34a1.49,1.49,0,0,0,3,0V1.72a1.49,1.49,0,0,0-3,0 M14.31,6.54H1.69a1.49,1.49,0,0,0,0,3H14.31a1.49,1.49,0,1,0,0-3'/>",
  plusCircle:
    "<path d='M8,.2A7.8,7.8,0,1,0,15.8,8,7.81,7.81,0,0,0,8,.2ZM8,14.39A6.39,6.39,0,1,1,14.39,8,6.4,6.4,0,0,1,8,14.39Zm3.9-6.31a.71.71,0,0,1-.71.71H8.71v2.48a.71.71,0,0,1-1.42,0V8.79H4.8a.71.71,0,1,1,0-1.42H7.29V4.89a.71.71,0,1,1,1.42,0V7.37h2.48A.71.71,0,0,1,11.9,8.08Z'/>",
  plusThin:
    "<path d='M8.6,8.6h6.61a.6.6,0,0,0,0-1.2H8.6V.79a.6.6,0,0,0-1.2,0V7.4H.79a.6.6,0,0,0,0,1.2H7.4v6.61a.6.6,0,0,0,1.2,0Z'/>",
  proxy:
    "<path d='M4.47,6.9a1,1,0,1,1-1-1A1,1,0,0,1,4.47,6.9Zm6.91,4.27A8.21,8.21,0,0,1,6.1,15.25,8.21,8.21,0,0,1,.81,11.17C-.11,9.56,0,8.59,0,1.88A24.51,24.51,0,0,0,6.1,0a24.56,24.56,0,0,0,6.09,1.88C12.19,8.59,12.3,9.56,11.38,11.17ZM9.75,6.9a.49.49,0,0,0-.49-.5H5.39a2,2,0,1,0,0,1h1.1v1.4h1V7.39H8.76v1.4h1Z'/>",
  recorder:
    "<path d='M15.86,7.05a.45.45,0,0,0-.11-.19L13,4.21a2,2,0,0,0-1.4-.55H4.71a2.07,2.07,0,0,0-1.33.48L.27,6.84a.49.49,0,0,0-.17.38v0h0v3.17A1.91,1.91,0,0,0,2,12.34H14a1.92,1.92,0,0,0,1.91-1.92V7.25A.45.45,0,0,0,15.86,7.05ZM4.05,4.91a1,1,0,0,1,.66-.23H11.6a1,1,0,0,1,.69.26l1.87,1.8H1.94Zm10.84,5.51a.9.9,0,0,1-.9.9H2a.9.9,0,0,1-.9-.9V7.76H14.89ZM9.64,9.51a.51.51,0,1,1,.51.51A.51.51,0,0,1,9.64,9.51Zm1.53,0a.51.51,0,1,1,.51.51A.51.51,0,0,1,11.17,9.51Zm1.52,0a.51.51,0,1,1,.51.51A.51.51,0,0,1,12.69,9.51ZM4.05,10.78A1.27,1.27,0,1,0,2.77,9.51,1.27,1.27,0,0,0,4.05,10.78ZM4.05,9a.51.51,0,1,1-.51.51A.5.5,0,0,1,4.05,9Z'/>",
  refresh:
    "<path d='M15.69,9.55c-.27,.28-.71,.29-.98,.02l-.7-.67c-.26,1.73-1.2,3.22-2.64,4.18-1.03,.69-2.2,1.01-3.36,1.01-1.97,0-3.91-.96-5.08-2.72-.21-.32-.13-.75,.19-.96,.32-.21,.75-.12,.96,.19,1.43,2.16,4.35,2.75,6.51,1.32,1.06-.7,1.77-1.78,2.01-3.01l-.76,.69c-.13,.12-.3,.18-.47,.18-.19,0-.38-.08-.52-.23-.26-.29-.23-.73,.05-.98l1.97-1.77c.27-.24,.68-.24,.95,.02l1.84,1.77c.28,.27,.29,.71,.02,.98ZM5.08,7.45c.29-.26,.31-.7,.05-.98-.26-.29-.7-.31-.98-.05l-.76,.69c.24-1.23,.95-2.31,2.01-3.01,2.16-1.43,5.08-.84,6.51,1.32,.21,.32,.64,.41,.96,.19,.32-.21,.41-.64,.19-.96-1.86-2.79-5.64-3.56-8.44-1.71-1.44,.95-2.38,2.44-2.64,4.18l-.7-.67c-.28-.27-.72-.26-.98,.02-.27,.28-.26,.72,.02,.98l1.84,1.77c.13,.13,.31,.19,.48,.19s.33-.06,.46-.18l1.97-1.77Z'/>",
  remove:
    "<path d='M15.77,8A7.78,7.78,0,0,0,8,.23H7.93A7.77,7.77,0,0,0,6.86,15.68,8.49,8.49,0,0,0,8,15.77c.32,0,.63,0,.94-.06A7.78,7.78,0,0,0,15.77,8ZM8,14.22A6.22,6.22,0,1,1,14.21,8,6.22,6.22,0,0,1,8,14.22Zm-3.25-7h6.5V8.78H4.75Z'/>",
  rename:
    "<path d='M11.84,5.34a.66.66,0,0,1-.41.83.65.65,0,0,1-.82-.42l-.1-.29H8.65v5.29l.5.12A.65.65,0,0,1,9,12.15a.47.47,0,0,1-.16,0L8,11.93l-.83.2a.65.65,0,0,1-.32-1.26l.5-.12V5.46H5.49l-.1.29a.64.64,0,0,1-.61.45.76.76,0,0,1-.21,0,.66.66,0,0,1-.41-.83l.25-.74A.63.63,0,0,1,5,4.15h6a.63.63,0,0,1,.61.45Zm3.32-2.55V13.21h.65v2.6h-2.6v-.65H2.79v.65H.19v-2.6H.84V2.79H.19V.19h2.6V.84H13.21V.19h2.6v2.6Zm-1.3,0h-.65V2.14H2.79v.65H2.14V13.21h.65v.65H13.21v-.65h.65Z'/>",
  scissor:
    "<path d='M13.11,4.19a2.66,2.66,0,0,0-2.67,2.66v0l-1.76.4L9,5.5A2.66,2.66,0,1,0,7.9,5.3L7.49,7.55.67,9.1a.56.56,0,0,0-.43.67.57.57,0,0,0,.55.44H.91L7.27,8.75,6.08,15.16a.56.56,0,0,0,.45.65h.1a.57.57,0,0,0,.56-.46L8.46,8.48,10.7,8a2.66,2.66,0,1,0,2.41-3.79ZM7.39,2.84A1.54,1.54,0,1,1,8.93,4.39,1.54,1.54,0,0,1,7.39,2.84ZM13.11,8.4a1.55,1.55,0,1,1,0-3.09,1.55,1.55,0,0,1,0,3.09Z'/>",
  scissorBold:
    "<path d='M15.52,7.05a2.84,2.84,0,0,0-5.59.22.23.23,0,0,1-.16.17l-1.34.31.29-1.52a.21.21,0,0,1,.19-.16A2.86,2.86,0,0,0,11.57,2.8,2.76,2.76,0,0,0,10.13.72a2.89,2.89,0,0,0-3,.14,2.79,2.79,0,0,0-1.2,1.82A2.84,2.84,0,0,0,7.21,5.62a.19.19,0,0,1,.09.21L6.89,8a.2.2,0,0,1-.16.16L.84,9.5A.73.73,0,0,0,1,11a.47.47,0,0,0,.17,0L6.24,9.76a.21.21,0,0,1,.25.25l-.94,4.82a.75.75,0,0,0,.59.86h.14A.74.74,0,0,0,7,15.11L8.09,9.46a.22.22,0,0,1,.16-.16L10,8.9a.22.22,0,0,1,.23.11,2.85,2.85,0,0,0,2.53,1.54,3.58,3.58,0,0,0,.64-.07,2.92,2.92,0,0,0,1.78-1.27A2.88,2.88,0,0,0,15.52,7.05ZM7.38,3A1.4,1.4,0,0,1,8,2.07a1.45,1.45,0,0,1,.78-.23,1.1,1.1,0,0,1,.26,0H9a1.39,1.39,0,0,1,1.1,1.63A1.39,1.39,0,0,1,7.38,3Zm6.53,5.47a1.35,1.35,0,0,1-.86.61,1.39,1.39,0,1,1-.63-2.7,1.73,1.73,0,0,1,.32,0,1.35,1.35,0,0,1,.73.21,1.36,1.36,0,0,1,.62.86h0A1.39,1.39,0,0,1,13.91,8.43Z'/>",
  search:
    "<path d='M15.51,14.25l-2.9-2.9a6.73,6.73,0,0,0,1.44-4.18A6.84,6.84,0,1,0,7.22,14a6.69,6.69,0,0,0,4.18-1.44l2.9,2.9a.85.85,0,0,0,1.21,0h0a.84.84,0,0,0,0-1.2ZM2.09,7.17a5.13,5.13,0,1,1,5.13,5.12A5.13,5.13,0,0,1,2.09,7.17Z'/>",
  share:
    "<path d='M15.88,5.67a.49.49,0,0,1-.17.38l-5.28,4.58a.51.51,0,0,1-.54.08.52.52,0,0,1-.29-.46s0-1.15,0-2c-6.81.18-8.25,4.13-8.49,6.51a.48.48,0,0,1-.31.41h0l-.18,0a.48.48,0,0,1-.29-.09.46.46,0,0,1-.21-.42C.45,4.77,5.26,3.17,9.6,3.07V1.25a.5.5,0,0,1,.82-.39l5.29,4.43A.49.49,0,0,1,15.88,5.67Z'/>",
  scheduleAdd:
    "<path d='M5.38,3.14a.87.87,0,0,1,.87-.87H8A.87.87,0,1,1,8,4H6.25A.87.87,0,0,1,5.38,3.14ZM4.22,4.83A.87.87,0,0,0,5.09,4V2.22a.87.87,0,1,0-1.74,0V4A.87.87,0,0,0,4.22,4.83Zm5.8,0A.87.87,0,0,0,10.89,4V2.22a.87.87,0,1,0-1.74,0V4A.87.87,0,0,0,10,4.83ZM15.81,10a4.63,4.63,0,0,1-8,3.21H1.06a.86.86,0,0,1-.87-.86V3.12a.87.87,0,0,1,.87-.87h1A.87.87,0,1,1,2.11,4H1.93v7.5H6.78A4.83,4.83,0,0,1,6.54,10a4.64,4.64,0,0,1,4.63-4.63,4.75,4.75,0,0,1,1.14.14V4h-.19a.87.87,0,0,1,0-1.74h1.06a.87.87,0,0,1,.87.87V6.37l-.18-.12A4.63,4.63,0,0,1,15.81,10Zm-2.2-3.94.08,0Zm1,3.94a3.48,3.48,0,1,0-3.48,3.48A3.48,3.48,0,0,0,14.65,10Zm-2.87-.44V7.69H10.62V9.57H8.74v1.16h1.88v1.88h1.16V10.73h1.88V9.57Z'/>",
  sort: "<path d='M9.38,4.55H1A.76.76,0,0,1,1,3H9.38a.76.76,0,0,1,0,1.51ZM5.33,12.2a.76.76,0,0,0-.75-.75H1A.76.76,0,0,0,1,13H4.58A.76.76,0,0,0,5.33,12.2ZM7.73,8A.76.76,0,0,0,7,7.25H1a.75.75,0,0,0,0,1.5H7A.76.76,0,0,0,7.73,8ZM10,9.61l2.6,2.59m.42.43a.59.59,0,0,0,0-.85l-2.59-2.6a.61.61,0,0,0-.85,0,.59.59,0,0,0,0,.85l2.6,2.6a.58.58,0,0,0,.84,0Zm2.18-3-2.6,2.59m.42.43L15.6,10a.59.59,0,0,0,0-.85.61.61,0,0,0-.85,0l-2.59,2.6a.59.59,0,0,0,0,.85.58.58,0,0,0,.84,0Zm.18-1.33V3.8a.6.6,0,1,0-1.2,0v7.5a.6.6,0,1,0,1.2,0Z'/>",

  success:
    "<path d='M8,.19A7.81,7.81,0,0,0,7.58,15.8H8.4A7.81,7.81,0,0,0,8,.19Zm0,14.2A6.39,6.39,0,1,1,14.39,8,6.4,6.4,0,0,1,8,14.39ZM6.81,11.13a.7.7,0,0,1-.5-.21L4.44,9.05a.71.71,0,0,1,1-1L6.81,9.42l3.55-3.55a.71.71,0,0,1,1,0,.7.7,0,0,1,0,1L7.31,10.92A.7.7,0,0,1,6.81,11.13Z'/>",
  star: "<path d='M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z'/>",
  starFill:
    "<path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z'/>",
  trash:
    "<path d='M8,12.81a.38.38,0,0,1-.37-.37V5.78A.38.38,0,0,1,8,5.41a.37.37,0,0,1,.37.37v6.66A.37.37,0,0,1,8,12.81Zm-1.81,0a.37.37,0,0,0,.33-.41L5.78,5.74a.36.36,0,0,0-.41-.32.35.35,0,0,0-.32.4l.74,6.66a.37.37,0,0,0,.36.33Zm4-.32L11,5.82a.37.37,0,0,0-.33-.4.36.36,0,0,0-.41.32l-.74,6.65a.37.37,0,0,0,.33.41h0A.37.37,0,0,0,10.22,12.48Zm2.36,1.79L13.65,3.69a.74.74,0,0,0-1.47-.15L11.11,14.12a.18.18,0,0,1-.18.16H5.2A.19.19,0,0,1,5,14.12L3.83,3.54A.75.75,0,0,0,3,2.88a.74.74,0,0,0-.65.82L3.54,14.28a1.66,1.66,0,0,0,1.65,1.48h5.74A1.66,1.66,0,0,0,12.58,14.27ZM14.27,3.42a.74.74,0,0,0-.73-.74H2.47a.74.74,0,0,0,0,1.48H13.54A.74.74,0,0,0,14.27,3.42ZM6.14,2.81V1.89a.52.52,0,0,1,0-.17H9.94a.34.34,0,0,1,0,.17v.92a.74.74,0,1,0,1.48,0V1.89A1.52,1.52,0,0,0,10.05.24h-4A1.53,1.53,0,0,0,4.66,1.89v.92a.74.74,0,1,0,1.48,0Z'/>",
  unlock:
    "<path d='M15.88,8.22v6.12c0,.56-.44,1.02-.98,1.02H5.15c-.54,0-.98-.46-.98-1.02v-6.12c0-.56,.44-1.02,.98-1.02h1.11v-1.4c0-1.86-.87-2.76-2.11-2.76s-2.11,.9-2.11,2.76v1.63H.14v-1.9c-.02-.18-.02-.35-.01-.53C.21,2.75,2.02,.99,4.18,1.08c.17,0,.33,0,.5,.01,2.15,.21,3.73,2.2,3.53,4.45v1.66h6.69c.54,0,.98,.46,.98,1.02Z'/>",
  userCircle:
    "<path d='M8,.16A7.84,7.84,0,0,0,8,15.84H8A7.84,7.84,0,0,0,8,.16Zm0,1A6.87,6.87,0,0,1,14.85,8a6.78,6.78,0,0,1-1.52,4.29A3.45,3.45,0,0,0,10.9,9.87h0a3,3,0,0,1-1.07-.59h0a.37.37,0,0,0-.22-.1l.23-.25a4.41,4.41,0,0,0,.74-1.22.61.61,0,0,0,.2-.19h0a1.58,1.58,0,0,0,.31-.94A1.49,1.49,0,0,0,11,6.1h0a.89.89,0,0,0-.22-.29,5,5,0,0,0-.12-1h0a2,2,0,0,0-.47-1,2.49,2.49,0,0,0-2-.75,2.49,2.49,0,0,0-2,.75,1.92,1.92,0,0,0-.46,1h0a4.24,4.24,0,0,0-.11.94.8.8,0,0,0-.23.29h0a1.5,1.5,0,0,0-.08.47,1.58,1.58,0,0,0,.31.94h0a.69.69,0,0,0,.19.19,4.68,4.68,0,0,0,.74,1.22l.23.24-.1,0a.43.43,0,0,0-.32.14h0a2.83,2.83,0,0,1-1.07.63h0a3.44,3.44,0,0,0-2.47,2.56A6.85,6.85,0,0,1,8,1.15ZM6.39,7a1.13,1.13,0,0,1-.16-.48h0a.47.47,0,0,0,.31-.43V6h0V5.91a3.55,3.55,0,0,1,.1-.86h0V5h0a.87.87,0,0,1,.22-.47A1.69,1.69,0,0,1,8.2,4a1.76,1.76,0,0,1,1.32.44A.85.85,0,0,1,9.75,5h0V5h0a3.55,3.55,0,0,1,.1.86V6h0v0a.45.45,0,0,0,.31.42v0A1.08,1.08,0,0,1,10,7a.6.6,0,0,0-.27.32h0a3.28,3.28,0,0,1-.51.92,1.41,1.41,0,0,1-1,.65,1.41,1.41,0,0,1-1-.65,3.28,3.28,0,0,1-.51-.92h0A.59.59,0,0,0,6.39,7ZM4.13,11.8a3,3,0,0,1,1.45-1h0A3.61,3.61,0,0,0,7,10H7a.5.5,0,0,0,.13-.34.54.54,0,0,0-.05-.21,1.81,1.81,0,0,0,2,.15.09.09,0,0,0,0,.05.46.46,0,0,0,.13.33h0a3.44,3.44,0,0,0,1.43.83h0a2.9,2.9,0,0,1,1.27.75,2.66,2.66,0,0,1,.62,1.6,6.83,6.83,0,0,1-8.85.16A2.55,2.55,0,0,1,4.13,11.8Z'/>",

  moreVertical:
    "<path d='M9.778 8c0-0.239-0.048-0.469-0.135-0.68-0.091-0.219-0.222-0.413-0.386-0.577s-0.359-0.295-0.577-0.386c-0.211-0.087-0.441-0.135-0.68-0.135s-0.469 0.048-0.68 0.135c-0.219 0.091-0.413 0.222-0.577 0.386s-0.295 0.359-0.386 0.577c-0.087 0.211-0.135 0.441-0.135 0.68s0.048 0.469 0.135 0.68c0.091 0.219 0.222 0.413 0.386 0.577s0.359 0.295 0.577 0.386c0.211 0.087 0.441 0.135 0.68 0.135s0.469-0.048 0.68-0.135c0.219-0.091 0.413-0.222 0.577-0.386s0.295-0.359 0.386-0.577c0.087-0.211 0.135-0.441 0.135-0.68zM9.778 1.778c0-0.239-0.048-0.469-0.135-0.68-0.091-0.219-0.222-0.413-0.386-0.577s-0.359-0.295-0.577-0.386c-0.211-0.087-0.441-0.135-0.68-0.135s-0.469 0.048-0.68 0.135c-0.219 0.091-0.413 0.222-0.577 0.386s-0.295 0.359-0.386 0.577c-0.087 0.211-0.135 0.441-0.135 0.68s0.048 0.469 0.135 0.68c0.091 0.219 0.222 0.413 0.386 0.577s0.359 0.295 0.577 0.386c0.211 0.087 0.441 0.135 0.68 0.135s0.469-0.048 0.68-0.135c0.219-0.091 0.413-0.222 0.577-0.386s0.295-0.359 0.386-0.577c0.087-0.211 0.135-0.441 0.135-0.68zM9.778 14.222c0-0.239-0.048-0.469-0.135-0.68-0.091-0.219-0.222-0.413-0.386-0.577s-0.359-0.295-0.577-0.386c-0.211-0.087-0.441-0.135-0.68-0.135s-0.469 0.048-0.68 0.135c-0.219 0.091-0.413 0.222-0.577 0.386s-0.295 0.359-0.386 0.577c-0.087 0.211-0.135 0.441-0.135 0.68s0.048 0.469 0.135 0.68c0.091 0.219 0.222 0.413 0.386 0.577s0.359 0.295 0.577 0.386c0.211 0.087 0.441 0.135 0.68 0.135s0.469-0.048 0.68-0.135c0.219-0.091 0.413-0.222 0.577-0.386s0.295-0.359 0.386-0.577c0.087-0.211 0.135-0.441 0.135-0.68z'/>",

  warning:
    "<path d='M8,.19A7.81,7.81,0,1,0,15.81,8,7.81,7.81,0,0,0,8,.19Zm0,14.2A6.39,6.39,0,1,1,14.39,8,6.4,6.4,0,0,1,8,14.39Zm.89-9.76V9.24a.71.71,0,1,1-1.42,0V4.63a.71.71,0,1,1,1.42,0Zm0,6.6a.78.78,0,0,1,0,.14.71.71,0,0,1-.21.5.71.71,0,0,1-1.21-.5.69.69,0,0,1,.21-.5.74.74,0,0,1,1,0l.09.11a.67.67,0,0,1,.06.12A.93.93,0,0,1,8.87,11.23Z'/>",
  warningFill:
    "<path d='M15.6,12,10,1.78a2.27,2.27,0,0,0-4,0L.4,12a2.33,2.33,0,0,0-.28,1.09A2.27,2.27,0,0,0,2.39,15.4H13.61a2.33,2.33,0,0,0,1.09-.28A2.27,2.27,0,0,0,15.6,12Zm-6.68.68a1.23,1.23,0,0,1-.25.36,1,1,0,0,1-.37.25,1.22,1.22,0,0,1-.43.08,1.26,1.26,0,0,1-.43-.08,1.15,1.15,0,0,1-.37-.25,1.13,1.13,0,0,1,0-1.6,1.16,1.16,0,0,1,1.6,0,1.1,1.1,0,0,1,.34.8A1.12,1.12,0,0,1,8.92,12.72ZM9,8.63a1.14,1.14,0,0,1-2.27,0v-3A1.14,1.14,0,1,1,9,5.61Z'/>",
  watch:
    "<path d='M7.36,6.07v1.93c0,.18,.07,.34,.19,.46l.97,.97c.25,.25,.66,.25,.91,0s.25-.66,0-.91l-.78-.78v-1.67c0-.36-.29-.64-.64-.64s-.64,.29-.64,.64Zm2.79,6.62l-.11,1.17c0,.09-.03,.16-.07,.23-.04,.07-.09,.14-.15,.19-.06,.05-.13,.09-.2,.12-.07,.03-.15,.04-.23,.04h-2.79c-.09,0-.17-.02-.24-.04-.08-.03-.15-.07-.2-.13-.06-.05-.1-.12-.14-.19-.03-.07-.06-.14-.06-.23l-.11-1.17c.06,.03,.12,.05,.18,.08,.61,.25,1.27,.39,1.97,.39s1.36-.14,1.97-.39c.06-.02,.12-.05,.17-.08h0ZM5.55,5.02c.29-.24,.62-.44,.98-.59,.45-.19,.95-.29,1.48-.29s1.02,.1,1.48,.29c.47,.2,.9,.48,1.26,.84s.64,.78,.84,1.26c.19,.45,.29,.95,.29,1.48s-.1,1.02-.29,1.48c-.2,.47-.48,.9-.84,1.26-.07,.07-.14,.13-.21,.19-.02,.02-.05,.04-.07,.06-.29,.24-.62,.44-.98,.59-.45,.19-.95,.29-1.48,.29s-1.02-.1-1.48-.29c-.47-.2-.9-.48-1.26-.84s-.64-.78-.84-1.26c-.19-.45-.29-.95-.29-1.48s.1-1.02,.29-1.48c.2-.47,.48-.9,.84-1.26,.07-.07,.14-.13,.21-.19,.02-.02,.05-.04,.07-.06Zm5.99-.76l-.2-2.23c-.02-.24-.09-.47-.19-.67-.1-.21-.25-.4-.42-.56-.18-.17-.38-.3-.61-.39-.22-.09-.46-.13-.71-.13h-2.81c-.24,0-.47,.05-.68,.13-.22,.08-.42,.21-.59,.36-.18,.16-.33,.35-.44,.57-.11,.21-.18,.45-.2,.7l-.2,2.21s-.08,.08-.12,.12c-.47,.47-.86,1.04-1.12,1.67-.25,.61-.39,1.27-.39,1.97s.14,1.36,.39,1.97c.26,.63,.64,1.2,1.12,1.67,.04,.04,.08,.07,.11,.11l.2,2.22c.02,.24,.09,.47,.19,.67,.1,.21,.25,.4,.42,.56,.18,.17,.38,.3,.61,.39,.22,.09,.46,.13,.71,.13h2.79c.24,0,.47-.04,.69-.12,.22-.08,.42-.21,.6-.36,.18-.16,.33-.35,.44-.57,.11-.21,.18-.45,.2-.7l.2-2.21s.08-.08,.12-.12c.47-.47,.86-1.04,1.12-1.67,.25-.61,.39-1.27,.39-1.97s-.14-1.36-.39-1.97c-.26-.63-.64-1.2-1.12-1.67-.03-.03-.07-.07-.11-.1h0Zm-5.68-.94l.11-1.17c0-.09,.03-.16,.07-.23,.04-.07,.09-.14,.15-.19,.06-.05,.13-.09,.2-.12,.07-.03,.15-.04,.23-.04h2.8c.09,0,.17,.02,.24,.04,.08,.03,.14,.07,.2,.13,.06,.05,.1,.12,.14,.19,.03,.07,.06,.14,.06,.23l.11,1.17c-.06-.03-.12-.06-.19-.08-.61-.25-1.27-.39-1.97-.39s-1.36,.14-1.97,.39c-.06,.02-.12,.05-.17,.08h0Z'/>"
};
