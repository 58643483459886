const routes = [
  {
    path: 'get-started',
    alias: 'commencer',
    name: 'subscribers.started',
    component: () => import('#/pages/subscribers/GetStarted.vue'),
    meta: {
      title: 'pageMeta.home.title',
      alias: [null, null, 'commencer'],
      layout: 'skinny'
    }
  },
  {
    path: 'confirm-institution',
    alias: 'confirmer-etablissement',
    name: 'subscribers.orphan',
    component: () => import('#/pages/subscribers/GetStarted.vue'),
    meta: {
      title: 'pageMeta.home.title',
      alias: [null, null, 'confirmer-etablissement'],
      layout: 'skinny'
    }
  }
]

export default routes
