<template>
  <div
    :id="id"
    class="rc-modal modal fade"
    tabindex="-1"
    :aria-labelledby="`${id}_label`"
  >
    <div :class="`modal-dialog modal-${size}`">
      <div :class="`modal-content ${contentClass}`">
        <div :class="`modal-header ${headerClass}`">
          <h2 v-if="!hideTitle" class="modal-title" :id="`${id}_label`">
            <slot name="modal-title">title</slot>
          </h2>

          <button
            class="btn btn-link rc-btn-icon rc-btn-close"
            data-bs-dismiss="modal"
          >
            <span class="visually-hidden">{{ $t("common.close") }}</span>
            <RcIcon name="close"></RcIcon>
          </button>
        </div>
        <div class="modal-body">
          <slot :ok="hide" :cancel="hide" name="modal-body">body</slot>
        </div>
        <div v-if="!hideFooter" :class="`modal-footer ${footerClass}`">
          <slot :ok="hide" :cancel="hide" name="modal-footer">footer</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Modal } from "bootstrap";
import RcIcon from "@/libvue/components/icons/Base.vue";

const props = defineProps({
  id: {
    type: String,
    default: "",
    required: true
  },

  headerClass: {
    type: String,
    required: false,
    default: ""
  },
  contentClass: {
    type: String,
    required: false,
    default: ""
  },
  footerClass: {
    type: String,
    required: false,
    default: ""
  },
  hideTitle: {
    type: Boolean,
    required: false,
    default: false
  },
  hideFooter: {
    type: Boolean,
    required: false,
    default: false
  },

  size: {
    type: String,
    required: false,
    default: "md"
  }
});

const target = ref(null);
const modal = ref(null);
const emit = defineEmits(["show", "shown", "hide", "hidden", "init"]);

onMounted(() => {
  const id = `#${props.id}`;
  modal.value = new Modal(id, {});
  const el = modal.value._element;

  emit("init", modal.value);

  el.addEventListener("show.bs.modal", (event) => {
    emit("show", event, modal.value);
  });
  el.addEventListener("shown.bs.modal", (event) => {
    emit("shown", event, modal.value);
  });
  el.addEventListener("hide.bs.modal", (event) => {
    emit("hide", event, modal.value);
  });
  el.addEventListener("hidden.bs.modal", (event) => {
    emit("hidden", event, modal.value);
  });
});

function show() {
  modal.value.show();
}
function hide() {
  modal.value.hide();
}
</script>
