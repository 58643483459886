export const HelpScoutBeacon = {
  install(app, options) {
    const loadBeacon = (e, t, n) => {
      function a() {
        var e = t.getElementsByTagName("script")[0],
          n = t.createElement("script");
        (n.type = "text/javascript"),
          (n.async = !0),
          (n.src = "https://beacon-v2.helpscout.net"),
          e.parentNode.insertBefore(n, e);
      }
      if (
        ((e.Beacon = n =
          function (t, n, a) {
            e.Beacon.readyQueue.push({ method: t, options: n, data: a });
          }),
        (n.readyQueue = []),
        "complete" === t.readyState)
      )
        return a();
      e.attachEvent
        ? e.attachEvent("onload", a)
        : e.addEventListener("load", a, !1);
    };

    const switchBeacon = function (locale) {
      loadBeacon(window, document, () => {});
      window.Beacon("init", options.localeIds[locale]);
    };

    options.router.afterEach((to, from) => {
      if (!options.stores.getters.isAuthenticated && window.Beacon) {
        window.Beacon("destroy");
        window.Beacon = undefined;
      } else if (
        options.stores.getters.isAuthenticated &&
        window.Beacon == undefined
      ) {
        loadBeacon(window, document, () => {});
        window.Beacon("init", options.localeIds[to.params.locale]);
      } else if (
        options.stores.getters.isAuthenticated &&
        from.params.locale &&
        from.params.locale !== to.params.locale
      ) {
        loadBeacon(window, document, () => {});
        window.Beacon("init", options.localeIds[to.params.locale]);
      }
    });

    app.config.globalProperties.$switchBeacon = switchBeacon;
    app.provide("switchBeacon", switchBeacon);
  }
};
