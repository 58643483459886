import axios from "@/libvue/api/axios.js";
import * as constants from "./actions";

function getDefaultState() {
  return {
    active: {
      data: { results: [] },
      status: "",
      errors: {}
    },
    preview: {
      data: {},
      status: "",
      errors: {}
    }
  };
}

const getters = {};

const actions = {
  [constants.GET_ACTIVE_HOMEPAGES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_ACTIVE_HOMEPAGES);
      axios
        .get(`homepages`)
        .then((response) => {
          commit(constants.GET_ACTIVE_HOMEPAGES_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_ACTIVE_HOMEPAGES_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_PREVIEW_HOMEPAGE]: ({ commit }, homepage_uid) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_PREVIEW_HOMEPAGE);
      axios
        .get(`homepages/${homepage_uid}`)
        .then((response) => {
          commit(constants.GET_PREVIEW_HOMEPAGE_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_PREVIEW_HOMEPAGE_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.GET_ACTIVE_HOMEPAGES]: (state) => {
    state.active.status = "fetching";
    state.active.errors = {};
  },
  [constants.GET_ACTIVE_HOMEPAGES_SUCCESS]: (state, { data }) => {
    state.active.status = "success";
    state.active.errors = {};
    state.active.data = data;
  },
  [constants.GET_ACTIVE_HOMEPAGES_ERROR]: (state, err) => {
    state.active.status = "error";
    state.active.errors = err;
  },
  [constants.GET_PREVIEW_HOMEPAGE]: (state) => {
    state.preview.status = "fetching";
    state.preview.errors = {};
  },
  [constants.GET_PREVIEW_HOMEPAGE_SUCCESS]: (state, { data }) => {
    state.preview.status = "success";
    state.preview.errors = {};
    state.preview.data = data;
  },
  [constants.GET_PREVIEW_HOMEPAGE_ERROR]: (state, err) => {
    state.preview.status = "error";
    state.preview.errors = err;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
