<template>
  <div class="rc-l-public rc-l-public--home">
    <!-- section 1 Intro-->
    <div class="rc-home-intro rc-section">
      <div class="container">
        <div class="row">
          <div class="col-md-7 rc-col-text rc-col-max-width">
            <div
              class="rc-sur-title"
              v-html="$t('statics.home.section1.surTitle')"
            ></div>
            <h1 v-html="$t('statics.home.section1.title')"></h1>
            <p
              class="rc-intro--xl"
              v-html="$t('statics.home.section1.text')"
            ></p>

            <div class="rc-home-intro--cta">
              <router-link :to="toLogin" class="btn btn-primary btn-lg d-block">
                {{ $t("auth.log.in_now") }}
              </router-link>
              <router-link :to="toGetStarted" class="d-inline-block">
                {{ $t("auth.get_started.link") }}
              </router-link>
            </div>
          </div>
          <div class="col-md-5">
            <img
              src="/src/assets/img/illustrations/horse_combined.svg"
              class="rc-horse-combined"
              alt=""
              rel="preload"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- section 2-->
    <div class="rc-section-icons rc-section rc-bg-wave-red-1">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col">
            <img
              rel="preload"
              src="/src/assets/img/illustrations/ic_partner.svg"
              alt=""
            />
            <h3 v-html="$t('statics.home.section2.bloc.a.title')"></h3>
            <p
              class="rc-intro"
              v-html="$t('statics.home.section2.bloc.a.text')"
            ></p>
          </div>
          <div class="col">
            <img
              src="/src/assets/img/illustrations/ic_accessibility.svg"
              alt=""
            />
            <h3 v-html="$t('statics.home.section2.bloc.b.title')"></h3>
            <p
              class="rc-intro"
              v-html="$t('statics.home.section2.bloc.b.text')"
            ></p>
          </div>
          <div class="col">
            <img
              rel="preload"
              src="/src/assets/img/illustrations/ic_languages.svg"
              alt=""
            />
            <h3 v-html="$t('statics.home.section2.bloc.c.title')"></h3>
            <p
              class="rc-intro"
              v-html="$t('statics.home.section2.bloc.c.text')"
            ></p>
          </div>
        </div>
      </div>
    </div>

    <!-- section 3 : Quality content in quantity-->
    <section>
      <div class="container">
        <div class="row">
          <div class="col-lg-5 d-none d-lg-flex align-self-start">
            <img
              src="/src/assets/img/illustrations/toucan_combined.svg"
              class="rc-toucan-combined"
              alt=""
            />
          </div>
          <div class="col-lg-7 rc-col-text rc-col-max-width">
            <h2 v-html="$t('statics.home.section3.title')"></h2>
            <p
              class="rc-intro--xl"
              v-html="$t('statics.home.section3.text')"
            ></p>
            <h3 v-html="$t('statics.home.section3.list.title')"></h3>
            <ul class="rc-list-bullet rc-list-column-2">
              <li v-html="$t('statics.home.section3.list.1')"></li>
              <li v-html="$t('statics.home.section3.list.2')"></li>
              <li v-html="$t('statics.home.section3.list.3')"></li>
              <li v-html="$t('statics.home.section3.list.4')"></li>
              <li v-html="$t('statics.home.section3.list.5')"></li>
            </ul>
            <h3 v-html="$t('statics.home.section3.bloc.title')"></h3>
            <p
              class="rc-intro"
              v-html="$t('statics.home.section3.bloc.text')"
            ></p>

            <router-link :to="toMarketContent" class="btn btn-dark btn-lg">{{
              $t("statics.home.section3.cta")
            }}</router-link>
          </div>
        </div>
      </div>
    </section>

    <!-- section 4 : User-friendly and highly effective tools-->
    <section class="bg-yellow rc-section-icons">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <h2 v-html="$t('statics.home.section4.title')"></h2>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col">
            <img
              rel="preload"
              src="/src/assets/img/illustrations/ic_access.svg"
              alt=""
            />
            <h3 v-html="$t('statics.home.section4.bloc.a.title')"></h3>
            <p
              class="rc-intro"
              v-html="$t('statics.home.section4.bloc.a.text')"
            ></p>
          </div>
          <div class="col">
            <img
              src="/src/assets/img/illustrations/ic_personalized.svg"
              alt=""
            />
            <h3 v-html="$t('statics.home.section4.bloc.b.title')"></h3>
            <p
              class="rc-intro"
              v-html="$t('statics.home.section4.bloc.b.text')"
            ></p>
          </div>
          <div class="col">
            <img
              rel="preload"
              src="/src/assets/img/illustrations/ic_share.svg"
              alt=""
            />
            <h3 v-html="$t('statics.home.section4.bloc.c.title')"></h3>
            <p
              class="rc-intro"
              v-html="$t('statics.home.section4.bloc.c.text')"
            ></p>
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <router-link :to="toMarketExperience" class="btn btn-dark btn-lg">{{
              $t("statics.home.section4.cta")
            }}</router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- section 5 : A single subscription for your entire institution-->
    <section class="rc-bg-wave-plum-1">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-5 rc-col-text rc-col-max-width">
            <h2 v-html="$t('statics.home.section5.title')"></h2>

            <p
              class="rc-intro--xl"
              v-html="$t('statics.home.section5.text')"
            ></p>

            <router-link
              :to="toMarketSubscription"
              class="btn btn-dark btn-lg"
              >{{ $t("statics.home.section5.cta") }}</router-link
            >
          </div>
          <div class="col-md-6 d-none d-md-flex text-end">
            <img
              src="/src/assets/img/illustrations/bicycle_combined.svg"
              class="rc-bicycle-combined"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    <!-- section 6 : Get more with our channels-->
    <section class="rc-section-follow-by-same-color">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-5 align-self-start">
            <img
              src="/src/assets/img/illustrations/antelope_combined.svg"
              class="rc-antelope-combined"
              alt=""
            />
          </div>
          <div class="col-md-6 offset-md-1 rc-col-text rc-col-max-width">
            <h2 v-html="$t('statics.home.section6.title')"></h2>

            <p
              class="rc-intro--xl"
              v-html="$t('statics.home.section6.text')"
            ></p>

            <router-link :to="toMarketChannels" class="btn btn-dark btn-lg">{{
              $t("statics.home.section6.cta")
            }}</router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- section 7 : Other content sources (logos)-->
    <section class="rc-section-last">
      <div class="container">
        <div class="row">
          <div class="col rc-col-text">
            <h2 v-html="$t('statics.home.section7.title')"></h2>
            <ul class="rc-list-logos">
              <li>
                <img
                  src="/src/assets/img/logos/lg_natgeo.png"
                  alt="National Geographic"
                  class="rc-lg-natgeo"
                />
              </li>
              <li>
                <img
                  src="/src/assets/img/logos/lg_bbc.png"
                  alt="BBC"
                  class="rc-lg-bbc"
                />
              </li>
              <li>
                <img
                  src="/src/assets/img/logos/lg_canadian_space_agency.svg"
                  alt="Canadian Space Agency - Agence spaciale canadienne"
                  class="rc-lg-csa"
                />
              </li>
              <li>
                <img
                  src="/src/assets/img/logos/lg_canadian_museum_of_nature.svg"
                  alt="Canadian Museum of Nature - Musée canadien de la nature"
                  class="rc-lg-cmn"
                />
              </li>
              <li>
                <img
                  src="/src/assets/img/logos/lg_canadian_race_relations.svg"
                  alt="Canadian Race Relations Foundation - Fondation canadienne des relations raciales"
                  class="rc-lg-crrf"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { navMixin } from "#/routes/mixins";

export default {
  name: "RcSiteHomePublic",
  mixins: [navMixin]
};
</script>

<style lang="scss" scoped></style>
