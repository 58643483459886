export const GET_CURRICULAS = "GET_CURRICULAS";
export const GET_CURRICULAS_SUCCESS = "GET_CURRICULAS_SUCCESS";
export const GET_CURRICULAS_ERROR = "GET_CURRICULAS_ERROR";

export const GET_CURRICULA = "GET_CURRICULA";
export const GET_CURRICULA_SUCCESS = "GET_CURRICULA_SUCCESS";
export const GET_CURRICULA_ERROR = "GET_CURRICULA_ERROR";

export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_ERROR = "GET_COURSE_ERROR";

export const GET_COURSES = "GET_COURSES";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_ERROR = "GET_COURSES_ERROR";
