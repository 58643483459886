<template>
  <rc-btn class="rc-skip-nav-trigger" v-bind="$attrs" @click="handleClick">{{
    t('nav.skip_nav')
  }}</rc-btn>
</template>

<script>
import { mapState } from 'vuex'
import { useI18n } from 'vue-i18n'
export default {
  name: 'SkipNav',
  setup() {
    const { t } = useI18n()
    return { t }
  },
  inheritAttrs: false,
  computed: {
    ...mapState({
      isMobileMenuVisible: (state) => state.root.isMobileMenuVisible
    })
  },
  methods: {
    handleClick() {
      var target = document.getElementById('skip-nav-target')
      target.setAttribute('tabindex', '-1')
      target.focus()
      target.addEventListener('blur', function () {
        this.removeAttribute('tabindex')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.rc-skip-nav-trigger {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%) translateY(-120px);
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 1000;

  &:focus {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}
</style>
