import { createApp } from "vue";
import App from "./App.vue";
import config from "#/config";
import routes from "#/routes";
import store from "#/stores";
import { i18n } from "#/locales";
// plugins
import { toaster } from "@/libvue/plugins/toaster";
import { HelpScoutBeacon } from "@/libvue/plugins/helpscoutBeacon";
import { MetrieGTM } from "@/libvue/plugins/metrieGTM";
import { rcPageStats } from "@/libvue/plugins/statistics/pageStats";

import { registerComponent } from "@/libvue/components/button";
import * as directives from "@/libvue/directives";
import { passiveSupport } from "passive-events-support/src/utils";
import "./main.scss";

passiveSupport({
  events: ["touchstart", "touchmove"]
});

const app = createApp(App);

// Register all custom directives
Object.keys(directives).forEach((d) => {
  app.directive(d, directives[d]);
});

registerComponent(app);
app.config.globalProperties.t = i18n.global.t;
app.config.globalProperties.KalturaPlayer = window.KalturaPlayer;

// production plugins
if (config.ENVIRONMENT == "production") {
  app
    .use(MetrieGTM, {
      router: routes
    })
    .use(rcPageStats, { router: routes, stores: store });
}

app
  .use(store)
  .use(routes)
  .use(toaster)
  .use(i18n)
  .use(HelpScoutBeacon, {
    router: routes,
    stores: store,
    localeIds: config.helpScout.localeIds
  })
  .mount("#app");
