import axios from "@/libvue/api/axios.js";
import Config from "#/config";

import * as constants from "./actions";

import {
  AUTH_REQUEST_CREATE_ORGANISATION,
  AUTH_REFRESH
} from "@/libvue/apps/auth/actions";

function getDefaultState() {
  return {
    current: {
      organisation: { teams: [] },
      team: {}
    },
    errors: {},
    profile: {
      memberships: [],
      user_requests: []
    },
    manage: {
      memberships: []
    },
    status: "",

    organisation: {
      teams: {
        data: { results: [] },
        status: null,
        errors: null
      },
      team: {
        data: {
          contacts: {}
        }
      }
    },
    info: {
      data: {},
      status: "",
      error: null
    }
  };
}

const getters = {
  getApprovedMembers: (state) => {
    return state.manage.memberships.filter(
      (membership) => membership.status === "approved"
    );
  },
  getRequestedMembers: (state) => {
    return state.manage.memberships.filter(
      (membership) => membership.status !== "approved"
    );
  }
};

const actions = {
  [constants.USER_TEAMS_REQUEST]: ({ commit, rootState }) => {
    const user_uid = rootState.auth.user.user_uid;
    if (!user_uid) return;

    return new Promise((resolve, reject) => {
      let current = rootState.auth.user.team_uid;
      let currentTeam;
      commit(constants.USER_TEAMS_REQUEST);
      axios
        .get(`users/${user_uid}/teams`)
        .then((response) => {
          currentTeam = response.data.results.find(
            (membership) => membership.team.uid === current
          );

          commit(constants.USER_TEAMS_REQUEST_SUCCESS, {
            response,
            currentTeam
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.USER_TEAMS_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.TEAM_JOIN_REQUEST]: ({ commit, rootState, dispatch }, payload) =>
    new Promise((resolve, reject) => {
      commit(constants.TEAM_JOIN_REQUEST);
      const organisation_uid = rootState.auth.user.organisation_uid;

      axios
        .post(`organisations/${organisation_uid}/teams/join`, payload)
        .then((response) => {
          commit(constants.TEAM_JOIN_SUCCESS, response);
          dispatch(constants.TEAM_SWITCH, { team: { uid: payload.team } }).then(
            () => {
              resolve(response);
            }
          );
        })
        .catch((error) => {
          commit(constants.TEAM_JOIN_ERROR, error);
          reject(error);
        });
    }),
  [constants.TEAM_JOIN_CREATE_FROM_ACCOUNT_REQUEST]: (
    { commit, dispatch, rootState, state },
    data
  ) =>
    new Promise((resolve, reject) => {
      commit(constants.TEAM_JOIN_CREATE_FROM_ACCOUNT_REQUEST);
      const user_uid = rootState.auth.user.user_uid;

      axios
        .post(`users/${user_uid}/organisation/teams`, data)
        .then((response) => {
          commit(constants.TEAM_JOIN_CREATE_FROM_ACCOUNT_SUCCESS, response);
          commit(AUTH_REQUEST_CREATE_ORGANISATION, {
            response: { data: state.current.organisation },
            data
          });
          dispatch(constants.USER_TEAMS_REQUEST);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_JOIN_CREATE_FROM_ACCOUNT_ERROR, error);
          reject(error);
        });
    }),
  [constants.USER_ORGANISATION_GET_REQUEST]: ({ commit, rootState }) =>
    new Promise((resolve, reject) => {
      commit(constants.USER_ORGANISATION_GET_REQUEST);
      let user_uid = rootState.auth.user.user_uid;
      axios
        .get(`users/${user_uid}/organisation`)
        .then((response) => {
          commit(constants.USER_ORGANISATION_GET_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.USER_ORGANISATION_GET_ERROR, error);
          reject(error);
        });
    }),
  [constants.TEAM_MEMBERSHIP_DELETE_REQUEST]: (
    { commit, rootState },
    membership
  ) =>
    new Promise((resolve, reject) => {
      commit(constants.TEAM_MEMBERSHIP_DELETE_REQUEST);

      const team_uid = rootState.auth.user.team_uid;
      axios
        .delete(`/teams/${team_uid}/members/${membership.uid}`)
        .then((response) => {
          commit(constants.TEAM_MEMBERSHIP_DELETE_SUCCESS, membership.uid);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_MEMBERSHIP_DELETE_ERROR, error);
          reject(error);
        });
    }),
  [constants.TEAM_MEMBER_INVITE]: ({ commit, state }, data) =>
    new Promise((resolve, reject) => {
      const team_uid = state.current.team.team.uid;
      commit(constants.TEAM_MEMBER_INVITE);
      axios
        .post(`teams/${team_uid}/members/invite`, data)
        .then((response) => {
          commit(constants.TEAM_MEMBER_INVITE_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_MEMBER_INVITE_ERROR, error);
          reject(error);
        });
    }),
  [constants.TEAM_USER_SET_STATUS_REQUEST]: ({ commit, rootState }, params) =>
    new Promise((resolve, reject) => {
      const team_uid = rootState.auth.user.team_uid;
      commit(constants.TEAM_USER_SET_STATUS_REQUEST);

      axios
        .patch(`teams/${team_uid}/members/${params.uid}/set-status`, params)
        .then((response) => {
          const payload = { response, membership: params };
          commit(constants.TEAM_USER_SET_STATUS_SUCCESS, payload);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_USER_SET_STATUS_ERROR, error);
          reject(error);
        });
    }),
  [constants.TEAM_ROLE_UPDATE_REQUEST]: ({ commit, state }, data) =>
    new Promise((resolve, reject) => {
      commit(constants.TEAM_ROLE_UPDATE_REQUEST);
      const team_uid = state.current.team.team.uid;
      const membership_uid = data.uid;
      axios
        .patch(`teams/${team_uid}/members/${membership_uid}/set-role`, data)
        .then((response) => {
          const payload = { response: response.data, membership: data };
          commit(constants.TEAM_ROLE_UPDATE_SUCCESS, payload);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_ROLE_UPDATE_ERROR, error);
          reject(error);
        });
    }),
  [constants.TEAM_SWITCH]: ({ commit, dispatch }, params) =>
    new Promise((resolve) => {
      commit(constants.TEAM_SWITCH, params);
      dispatch(AUTH_REFRESH, params).then(() => {
        resolve(params);
        localStorage.setItem("local-default-params-uid", params.team.uid);
      });
    }),
  [constants.TEAM_MEMBERSHIP_LIST_REQUEST]: ({ commit }, team_uid) =>
    new Promise((resolve, reject) => {
      commit(constants.TEAM_MEMBERSHIP_LIST_REQUEST);

      axios
        .get(`teams/${team_uid}/members`)
        .then((response) => {
          commit(constants.TEAM_MEMBERSHIP_LIST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_MEMBERSHIP_LIST_ERROR, error);
          reject(error);
        });
    }),
  [constants.GET_ORG_TEAMS]: ({ commit, rootState }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_ORG_TEAMS);
      let organisation_uid = rootState.auth.user.organisation_uid;

      axios
        .get(`organisations/${organisation_uid}/teams`, { params })
        .then((response) => {
          commit(constants.GET_ORG_TEAMS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_ORG_TEAMS_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_ORG_TEAMS_APPEND_NEXT]: ({ commit, rootState }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_ORG_TEAMS_APPEND_NEXT);
      let organisation_uid = rootState.auth.user.organisation_uid;
      axios
        .get(`organisations/${organisation_uid}/teams`, { params })
        .then((response) => {
          commit(constants.GET_ORG_TEAMS_APPEND_NEXT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_ORG_TEAMS_APPEND_NEXT_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_TEAM_INFO]: ({ commit }, team_uid) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_TEAM_INFO);
      axios
        .get(`teams/${team_uid}/info`)
        .then((response) => {
          commit(constants.GET_TEAM_INFO_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_TEAM_INFO_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.TEAM_MEMBERSHIP_UPDATE_REQUEST]: ({ commit, rootState }, data) =>
    new Promise((resolve, reject) => {
      commit(constants.TEAM_MEMBERSHIP_UPDATE_REQUEST);

      let membership_uid = data.uid;
      let user_uid = rootState.user.profile.user_uid;
      const payload = { uid: data.uid, default: data.default };

      axios
        .patch(`users/${user_uid}/teams/${membership_uid}`, payload)
        .then((response) => {
          commit(constants.TEAM_MEMBERSHIP_UPDATE_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_MEMBERSHIP_UPDATE_ERROR, error);
          reject(error);
        });
    }),
  [constants.TEAM_MEMBERSHIP_MAKE_DEFAULT_REQUEST]: (
    { commit, dispatch },
    data
  ) =>
    new Promise((resolve, reject) => {
      commit(constants.TEAM_MEMBERSHIP_MAKE_DEFAULT_REQUEST);
      dispatch(constants.TEAM_MEMBERSHIP_UPDATE_REQUEST, data)
        .then((response) => {
          commit(constants.TEAM_MEMBERSHIP_MAKE_DEFAULT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_MEMBERSHIP_MAKE_DEFAULT_ERROR, error);
          reject(error);
        });
    }),
  [constants.TEAM_USER_INVITE_APPROVE_REQUEST]: ({ commit }, data) =>
    new Promise((resolve, reject) => {
      commit(constants.TEAM_USER_INVITE_APPROVE_REQUEST);

      const localData = {
        ...data,
        approved: true
      };

      axios
        .patch(`team/team_users_invites/${localData.uid}`, localData)
        .then((response) => {
          commit(constants.TEAM_USER_INVITE_APPROVE_SUCCESS, {
            response,
            uid: localData.uid
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_USER_INVITE_APPROVE_ERROR, error);
          reject(error);
        });
    }),
  [constants.TEAM_USER_INVITE_DELETE_REQUEST]: ({ commit }, { uid }) =>
    new Promise((resolve, reject) => {
      commit(constants.TEAM_USER_INVITE_DELETE_REQUEST);
      axios
        .delete(`team/team_users_invites/${uid}`)
        .then((response) => {
          commit(constants.TEAM_USER_INVITE_DELETE_SUCCESS, uid);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_USER_INVITE_DELETE_ERROR, error);
          reject(error);
        });
    })
};

const mutations = {
  [constants.USER_TEAMS_REQUEST]: (state) => {
    state.status = "user teams request";
    state.errors = {};
  },
  [constants.USER_TEAMS_REQUEST_SUCCESS]: (
    state,
    { response: { data }, currentTeam }
  ) => {
    state.profile.memberships = data.results;
    state.current.team = currentTeam;
  },
  [constants.USER_TEAMS_REQUEST_ERROR]: (state, resp) => {
    state.status = "error";
    state.errors = resp.response.data;
  },
  [constants.TEAM_JOIN_REQUEST]: (state) => {
    state.status = "join";
    state.errors = {};
  },
  [constants.TEAM_JOIN_SUCCESS]: (state, { data }) => {
    state.status = "success";
    state.errors = {};
    state.profile.memberships.push(data);
  },
  [constants.TEAM_JOIN_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },
  [constants.TEAM_JOIN_CREATE_FROM_ACCOUNT_REQUEST]: (state) => {
    state.status = "joincreatefromaccount";
    state.errors = {};
  },
  [constants.TEAM_JOIN_CREATE_FROM_ACCOUNT_SUCCESS]: (state, { data }) => {
    state.status = "success";
    state.errors = {};
    state.current.organisation.teams.push(data);
  },
  [constants.TEAM_JOIN_CREATE_FROM_ACCOUNT_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },
  [constants.USER_ORGANISATION_GET_REQUEST]: (state) => {
    state.status = "organisation";
    state.errors = {};
  },
  [constants.USER_ORGANISATION_GET_SUCCESS]: (state, { data }) => {
    state.status = "success";
    state.errors = {};
    state.current.organisation = { ...state.current.organisation, ...data };
  },
  [constants.USER_ORGANISATION_GET_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },
  [constants.TEAM_MEMBERSHIP_DELETE_REQUEST]: (state) => {
    state.status = "membership";
    state.errors = {};
  },
  [constants.TEAM_MEMBERSHIP_DELETE_SUCCESS]: (state, uid) => {
    state.status = "success";
    state.errors = {};

    const index = state.profile.memberships.findIndex((m) => m.uid === uid);
    state.profile.memberships.splice(index, 1);
  },
  [constants.TEAM_MEMBERSHIP_DELETE_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },
  [constants.TEAM_MEMBER_INVITE]: (state) => {
    state.status = "teamusersinvites";
    state.errors = {};
  },
  [constants.TEAM_MEMBER_INVITE_SUCCESS]: (state, { data }) => {
    state.manage.memberships.push(data);
    state.status = "success";
    state.errors = {};
  },
  [constants.TEAM_MEMBER_INVITE_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },
  [constants.TEAM_USER_SET_STATUS_REQUEST]: (state) => {
    state.status = "teamuserrequest";
    state.errors = {};
  },
  [constants.TEAM_USER_SET_STATUS_SUCCESS]: (
    state,
    { response, membership }
  ) => {
    state.status = "success";
    state.errors = {};
    state.manage.memberships.filter((m) => {
      if (m.uid == membership.uid) {
        m.status = response.data.status;
      }
    });
  },
  [constants.TEAM_USER_SET_STATUS_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },
  [constants.TEAM_ROLE_UPDATE_REQUEST]: (state) => {
    state.status = "role";
    state.errors = {};
  },
  [constants.TEAM_ROLE_UPDATE_SUCCESS]: (state, { response, membership }) => {
    state.status = "success";
    state.errors = {};

    state.manage.memberships.filter((m) => {
      if (m.uid == membership.uid) {
        m.role = response.role;
      }
    });
  },
  [constants.TEAM_ROLE_UPDATE_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },
  [constants.TEAM_SWITCH]: (state, membership) => {
    state.current.team = membership;
  },
  [constants.TEAM_RESET]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  },
  [constants.TEAM_MEMBERSHIP_LIST_REQUEST]: (state) => {
    state.status = "team memberships requested";
    state.errors = {};
  },
  [constants.TEAM_MEMBERSHIP_LIST_SUCCESS]: (state, { data }) => {
    state.manage.memberships = data.results;
    state.status = "success";
    state.errors = {};
  },
  [constants.TEAM_MEMBERSHIP_LIST_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },
  [constants.GET_ORG_TEAMS]: (state) => {
    state.organisation.teams.status = "fetching";
    state.organisation.teams.errors = {};
  },
  [constants.GET_ORG_TEAMS_SUCCESS]: (state, { data }) => {
    state.organisation.teams.status = "success";
    state.organisation.teams.errors = {};
    state.organisation.teams.data = data;
  },
  [constants.GET_ORG_TEAMS_ERROR]: (state, err) => {
    state.organisation.teams.status = "error";
    state.organisation.teams.errors = err;
  },
  [constants.GET_ORG_TEAMS_APPEND_NEXT]: (state) => {
    state.organisation.teams.status = "fetching";
    state.organisation.teams.errors = {};
  },
  [constants.GET_ORG_TEAMS_APPEND_NEXT_SUCCESS]: (state, { data }) => {
    state.organisation.teams.status = "success";
    state.organisation.teams.errors = {};
    state.organisation.teams.data.results = [
      ...state.organisation.teams.data.results,
      ...data.results
    ];
    state.organisation.teams.data.count = data.count;
    state.organisation.teams.data.next = data.next;
    state.organisation.teams.data.current = data.current;
    state.organisation.teams.data.next_page_number = data.next_page_number;
  },
  [constants.GET_ORG_TEAMS_APPEND_NEXT_ERROR]: (state, err) => {
    state.organisation.teams.status = "error";
    state.organisation.teams.errors = err;
  },
  [constants.SET_ORG_TEAM]: (state, team) => {
    state.organisation.team.data = team;
  },
  [constants.GET_TEAM_INFO]: (state) => {
    state.info.status = "fetching";
    state.info.errors = {};
    state.info.data = {};
  },
  [constants.GET_TEAM_INFO_SUCCESS]: (state, { data }) => {
    state.info.status = "success";
    state.info.errors = {};
    state.info.data = data;
  },
  [constants.GET_TEAM_INFO_ERROR]: (state, err) => {
    state.info.status = "error";
    state.info.errors = err;
  },
  [constants.TEAM_MEMBERSHIP_UPDATE_REQUEST]: (state) => {
    state.status = "membership";
    state.errors = {};
  },
  [constants.TEAM_MEMBERSHIP_UPDATE_SUCCESS]: (state, { data }) => {
    state.status = "success";
    state.errors = {};

    state.profile.memberships = state.profile.memberships.map((membership) => {
      if (membership.uid !== data.uid) {
        return membership;
      }
      return {
        ...membership,
        ...data
      };
    });
  },
  [constants.TEAM_MEMBERSHIP_UPDATE_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },
  [constants.TEAM_MEMBERSHIP_MAKE_DEFAULT_REQUEST]: (state) => {
    state.status = "membership";
    state.errors = {};
  },
  [constants.TEAM_MEMBERSHIP_MAKE_DEFAULT_SUCCESS]: (state, { data }) => {
    state.status = "success";
    state.errors = {};

    state.profile.memberships = state.profile.memberships.map((membership) => {
      if (membership.uid !== data.uid) {
        return {
          ...membership,
          default: false
        };
      }

      return membership;
    });
  },
  [constants.TEAM_MEMBERSHIP_MAKE_DEFAULT_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },
  [constants.TEAM_USER_INVITE_APPROVE_REQUEST]: (state) => {
    state.status = "teamuserinvite";
    state.errors = {};
  },
  [constants.TEAM_USER_INVITE_APPROVE_SUCCESS]: (
    state,
    { response: { data }, uid }
  ) => {
    state.status = "success";
    state.errors = {};

    state.profile.memberships.push(data);
    state.profile.user_invites = state.profile.user_invites.map((invite) => {
      if (invite.uid !== uid) {
        return invite;
      }

      return {
        ...invite,
        approved: true
      };
    });
  },
  [constants.TEAM_USER_INVITE_APPROVE_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },
  [constants.TEAM_USER_INVITE_DELETE_REQUEST]: (state) => {
    state.status = "teamuserinvite";
    state.errors = {};
  },
  [constants.TEAM_USER_INVITE_DELETE_SUCCESS]: (state, uid) => {
    state.status = "success";
    state.errors = {};

    const index = state.profile.user_invites.findIndex((ui) => ui.uid === uid);
    state.profile.user_invites.splice(index, 1);
  },
  [constants.TEAM_USER_INVITE_DELETE_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },

  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
