import { ifUserKnown } from "#/stores/auth";
import i18n from "#/locales";

import moment from "moment";
import Home from "#/pages/market/Home.vue";

const routes = [
  {
    path: "",
    name: "home",
    component: Home,
    beforeEnter: ifUserKnown,
    meta: {
      title: "pageMeta.home.title",
      bannerCTA: {
        type: "getStarted",
        cssClass: "bg-teal"
      }
    }
  },
  {
    path: "subscription",
    alias: "abonnement",
    name: "market.subscription",
    component: () => import("#/pages/market/Subscription.vue"),
    meta: {
      title: "pageMeta.subscription.title",
      alias: [null, null, "abonnement"],
      bannerCTA: {
        type: "subscription",
        cssClass: "bg-teal"
      }
    }
  },
  {
    path: "content",
    alias: "contenu",
    name: "market.content",
    component: () => import("#/pages/market/Content.vue"),
    meta: {
      title: "pageMeta.content.title",
      alias: [null, null, "contenu"],
      bannerCTA: {
        type: "getStarted",
        cssClass: "bg-plum"
      }
    }
  },
  {
    path: "experience",
    name: "market.experience",
    component: () => import("#/pages/market/Experience.vue"),
    meta: {
      title: "pageMeta.experience.title",
      bannerCTA: {
        type: "getStarted",
        cssClass: "bg-plum"
      }
    }
  },
  {
    path: "about",
    alias: "a-propos",
    name: "market.about",
    component: () => import("#/pages/market/About.vue"),
    meta: {
      title: "pageMeta.about.title",
      alias: [null, null, "a-propos"],
      bannerCTA: {
        type: "getStarted",
        cssClass: "bg-yellow"
      }
    }
  },

  {
    path: "reportingforcurio",
    alias: "enreportagepourcurio",
    name: "market.contest",
    beforeEnter: (to, from, next) => {
      const contestEnded = moment("2024-02-23").isBefore(moment());
      return contestEnded
        ? next({ name: "home", params: { locale: i18n.global.locale } })
        : next();
    },
    component: () => import("#/pages/market/Contest.vue"),
    meta: {
      title: "pageMeta.contestReporting.title",
      alias: [null, null, "enreportagepourcurio"],
      bannerCTA: {
        type: "getStarted",
        cssClass: "bg-yellow"
      },
      redirectAfterLogin: true
    }
  },

  {
    path: ":code([1-5]\\d\\d)",
    name: "market.status.code",
    component: () => import("#/pages/market/StatusCode.vue")
  },
  {
    path: "channels",
    alias: "chaines",
    name: "market.channels",
    component: () => import("#/pages/market/Channels.vue"),
    meta: {
      title: "pageMeta.channels.title",
      alias: [null, null, "chaines"],
      bannerCTA: {
        type: "subscription",
        cssClass: "bg-teal"
      }
    }
  },
  {
    path: "not-found",
    name: "not.found",
    alias: "page-non-trouve",
    component: () => import("#/pages/market/NotFound.vue"),
    meta: {
      alias: [null, null, "page-non-trouve"],
      title: "pageMeta.notfound.title"
    }
  },
  {
    path: "create-profile",
    name: "market.createProfile",
    alias: "creer-profil",
    component: () => import("#/pages/market/CreateProfile.vue"),
    meta: {
      alias: [null, null, "creer-profil"],
      title: "pageMeta.createProfile.title",
      layout: "skinny"
    }
  }
  // ,

  // {
  //   path: "ds",
  //   component: () => import("#/pages/market/DesignSystem.vue"),
  //   name: "market.designSystem",
  //   meta: {
  //     title: "Design System"
  //   }
  // }
];
export default routes;
