<template>
  <!-- Footer : Public and Authentificated-->
  <footer role="contentinfo" :aria-hidden="isMobileMenuVisible">
    <RcSiteBannerCTA />
    <div class="rc-footer bg-dark text-white">
      <div class="container">
        <h2 class="visually-hidden">{{ $t("a11y.nav.footer") }}</h2>
        <!-- Authentificated (and Guest)-->
        <template v-if="isAuthenticated">
          <ul class="row">
            <li class="col-lg-4">
              <div class="rc-footer-about">
                <h3 class="mb-3">{{ $t("footer.about.title") }}</h3>
                <p class="pe-4">
                  {{ $t("footer.about.text") }}
                </p>
                <router-link :to="toAbout" class="rc-link-arrow-right"
                  >{{ $t("footer.about.cta")
                  }}<rc-icon name="arrowRight" scale=".9"></rc-icon
                ></router-link>
              </div>
            </li>
            <li class="col-md-3 col-lg-2 col-12">
              <div class="row">
                <div class="col-sm-4 col-md-12 col-5">
                  <h3 class="h4">
                    {{ $t("nav.browse") }}
                  </h3>
                </div>
                <div class="col-sm-8 col-md-12 col-7">
                  <ul>
                    <li
                      v-if="canRecommend || canRecommendCategories"
                      @click="onLatestClick"
                    >
                      <router-link :to="toRecommendedDetail">{{
                        $t("common.recommended")
                      }}</router-link>
                    </li>
                    <li @click="onLatestClick">
                      <router-link :to="toCatalogList">{{
                        $t("common.latest_additions")
                      }}</router-link>
                    </li>

                    <li>
                      <router-link :to="toCategoriesList">{{
                        $t("common.categories")
                      }}</router-link>
                    </li>
                    <li v-if="getTeamTypeAudience == 'level'">
                      <router-link :to="toCurriculaList">{{
                        $t("common.curriculum")
                      }}</router-link>
                    </li>

                    <li v-if="teamAgeGroups.data.length > 1">
                      <router-link :to="toAgeGroupList">
                        {{
                          $tc("catalog.audience_type." + getTeamTypeAudience, 2)
                        }}
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="d-md-none col-12">
                  <div class="rc-separator"></div>
                </div>
              </div>
            </li>
            <li class="col-md-3 col-lg-2 col-12">
              <div class="row">
                <div class="col-sm-4 col-md-12 col-5">
                  <h3 class="h4">
                    {{ $t("nav.discover") }}
                  </h3>
                </div>
                <div class="col-sm-8 col-md-12 col-7">
                  <ul>
                    <li>
                      <router-link :to="toFeaturedDetail">{{
                        $t("common.team_picks")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="toThemesList">{{
                        $t("common.themes")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="toChannelsList">{{
                        $t("common.channels")
                      }}</router-link>
                    </li>
                  </ul>
                </div>
                <div class="d-md-none col-12">
                  <div class="rc-separator"></div>
                </div>
              </div>
            </li>
            <li class="col-md-3 col-lg-2 col-12">
              <div class="row">
                <div class="col-sm-4 col-md-12 col-5">
                  <h3 class="h4">
                    {{ $t("common.resources") }}
                  </h3>
                </div>
                <div class="col-sm-8 col-md-12 col-7">
                  <ul>
                    <li>
                      <a :href="$t('nav.url.help_desk')">{{
                        $t("nav.help_desk")
                      }}</a>
                    </li>
                    <li>
                      <a :href="$t('nav.url.terms_of_use')">{{
                        $t("nav.terms_of_use")
                      }}</a>
                    </li>
                    <li>
                      <a :href="$t('nav.url.privacy_policy')">{{
                        $t("nav.privacy_policy")
                      }}</a>
                    </li>
                    <li>
                      <a :href="$t('nav.url.cookies')">{{
                        $t("nav.cookies")
                      }}</a>
                    </li>
                    <li>
                      <a :href="$t('nav.url.editorial_policy')">{{
                        $t("nav.editorial_policy")
                      }}</a>
                    </li>
                    <li>
                      <a
                        :href="$t('nav.url.accessibility_feedback')"
                        @click.prevent=""
                        data-bs-toggle="modal"
                        data-bs-target="#externalLinkModal"
                        class="rc-link-ext"
                        >{{ $t("nav.accessibility_feedback") }}</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="d-md-none col-12">
                  <div class="rc-separator"></div>
                </div>
              </div>
            </li>
            <li class="col-md-3 col-lg-2 col-12">
              <div class="row">
                <div class="col-sm-4 col-md-12 col-5">
                  <h3 class="h4">{{ $t("nav.follow_us") }}</h3>
                </div>
                <div class="col-sm-8 col-md-12 col-7">
                  <ul>
                    <li>
                      <a
                        :href="$t('nav.url.facebook')"
                        data-bs-toggle="modal"
                        data-bs-target="#externalLinkModal"
                        class="rc-link-ext"
                        >Facebook</a
                      >
                    </li>
                    <li>
                      <a
                        :href="$t('nav.url.twitter')"
                        @click.prevent=""
                        data-bs-toggle="modal"
                        data-bs-target="#externalLinkModal"
                        class="rc-link-ext"
                        >X (Twitter)</a
                      >
                    </li>
                    <li>
                      <a
                        :href="newsletter.signup_url"
                        @click.prevent=""
                        data-bs-toggle="modal"
                        data-bs-target="#externalLinkModal"
                        class="rc-link-ext"
                        >{{ $t("common.newsletter") }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </template>

        <!-- Public -->
        <template v-else>
          <ul class="row rc-list-with-box">
            <li class="col-lg-5">
              <div class="rc-box-content">
                <h3 class="mb-3">{{ $t("footer.about.title") }}</h3>
                <p class="pe-4">
                  {{ $t("footer.about.text") }}
                </p>
                <router-link :to="toAbout" class="rc-link-arrow-right"
                  >{{ $t("footer.about.cta")
                  }}<rc-icon name="arrowRight" scale=".9"></rc-icon
                ></router-link>
              </div>
            </li>
            <li class="col-md-4 col-lg-2 offset-lg-1 col-12">
              <div class="row">
                <div class="col-sm-4 col-md-12 col-5">
                  <h3 class="h4">
                    {{ $t("common.learn_more") }}
                  </h3>
                </div>
                <div class="col-sm-8 col-md-12 col-7">
                  <ul>
                    <li>
                      <router-link :to="toMarketContent">{{
                        $t("nav.content")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="toMarketSubscription">{{
                        $t("nav.subscription")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="toAbout">{{
                        $t("nav.about_brand")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="toMarketExperience">{{
                        $t("nav.experience")
                      }}</router-link>
                    </li>
                  </ul>
                </div>
                <div class="col-12 d-md-none">
                  <div class="rc-separator"></div>
                </div>
              </div>
            </li>
            <li class="col-md-4 col-lg-2 col-12">
              <div class="row">
                <div class="col-sm-4 col-md-12 col-5">
                  <h3 class="h4">
                    {{ $t("common.resources") }}
                  </h3>
                </div>
                <div class="col-sm-8 col-md-12 col-7">
                  <ul>
                    <li>
                      <a :href="$t('nav.url.help_desk')">{{
                        $t("nav.help_desk")
                      }}</a>
                    </li>
                    <li>
                      <a :href="$t('nav.url.terms_of_use')">{{
                        $t("nav.terms_of_use")
                      }}</a>
                    </li>
                    <li>
                      <a :href="$t('nav.url.privacy_policy')">{{
                        $t("nav.privacy_policy")
                      }}</a>
                    </li>
                    <li>
                      <a :href="$t('nav.url.cookies')">{{
                        $t("nav.cookies")
                      }}</a>
                    </li>
                    <li>
                      <a :href="$t('nav.url.editorial_policy')">{{
                        $t("nav.editorial_policy")
                      }}</a>
                    </li>
                    <li>
                      <a
                        :href="$t('nav.url.accessibility_feedback')"
                        @click.prevent=""
                        data-bs-toggle="modal"
                        data-bs-target="#externalLinkModal"
                        class="rc-link-ext"
                        >{{ $t("nav.accessibility_feedback") }}</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="col-12 d-md-none">
                  <div class="rc-separator"></div>
                </div>
              </div>
            </li>
            <li class="col-md-4 col-lg-2 col-12">
              <div class="row">
                <div class="col-sm-4 col-md-12 col-5">
                  <h3 class="h4">{{ $t("nav.follow_us") }}</h3>
                </div>
                <div class="col-sm-8 col-md-12 col-7">
                  <ul>
                    <li>
                      <a
                        :href="$t('nav.url.facebook')"
                        @click.prevent=""
                        data-bs-toggle="modal"
                        data-bs-target="#externalLinkModal"
                        class="rc-link-ext"
                        >Facebook</a
                      >
                    </li>
                    <li>
                      <a
                        :href="$t('nav.url.twitter')"
                        @click.prevent=""
                        data-bs-toggle="modal"
                        data-bs-target="#externalLinkModal"
                        class="rc-link-ext"
                        >X (Twitter)</a
                      >
                    </li>
                    <li>
                      <a
                        :href="newsletter.signup_url"
                        @click.prevent=""
                        data-bs-toggle="modal"
                        data-bs-target="#externalLinkModal"
                        class="rc-link-ext"
                        >{{ $t("common.newsletter") }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </template>
        <div class="rc-footer-bottom">
          {{ $t("common.all_rights_reserved") }}
          <span>&copy; CBC/Société Radio‑Canada {{ year }}.</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import RcIcon from "@/libvue/components/icons/Base.vue";
import { mapState, mapGetters } from "vuex";
import { navMixin } from "#/routes/mixins";

import { GET_NEWS_LETTER } from "@/libvue/apps/newsletter/actions";
import RcSiteBannerCTA from "#/layout/components/SiteBannerCTA.vue";

import {
  ITEM_SET_FILTER_PARAMS,
  ITEM_SET_USER_FILTER,
  ITEM_GET_LIST
} from "@/libvue/apps/catalog/items/actions";

export default {
  name: "RcSiteFooter",
  components: {
    RcIcon,
    RcSiteBannerCTA
  },
  mixins: [navMixin],
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "roleIsTeacher",
      "roleIsStudent",
      "getTeamTypeAudience",
      "canRecommend",
      "canRecommendCategories"
    ]),
    ...mapState({
      newsletter: (state) => state.newsletter.detail.data,
      teamAgeGroups: (state) => state.items.teamAgeGroups,
      isMobileMenuVisible: (state) => state.root.isMobileMenuVisible
    }),

    year() {
      const today = new Date();
      return today.getFullYear();
    }
  },
  mounted() {
    if (!this.newsletter.signup_url) {
      this.$store.dispatch(GET_NEWS_LETTER);
    }
  },
  methods: {
    onLatestClick() {
      if (this.$route.name == "catalog.item.list") {
        this.$store.commit(ITEM_SET_USER_FILTER, { reset: true });
        this.$store.commit(ITEM_SET_FILTER_PARAMS, { reset: true });
        this.$store.dispatch(ITEM_GET_LIST);
      }
    }
  }
};
</script>
