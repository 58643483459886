<template>
  <div id="theToaster" aria-live="polite" aria-atomic="true" class="fixed-top">
    <div class="toast-container top-0 end-0 p-3">
      <TransitionGroup name="fade">
        <RcToast
          v-for="msg in messages"
          :key="msg.uid"
          :config="msg"
          @hide="notify.removeItem(msg)"
        ></RcToast>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup>
import RcToast from "@/libvue/plugins/toaster/Toast.vue";
import { computed, inject } from "vue";

const notify = inject("notify");

const messages = computed(() => {
  return notify.getItems();
});
</script>

<style lang="scss" scoped>
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1) !important;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0 !important;
  transform: scaleY(0.01) translate(30px, 0) !important;
}

.fade-leave-active {
  position: absolute !important;
}
</style>
