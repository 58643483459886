<template>
  <main
    class="rc-content-root"
    v-bind:class="contentClass"
    id="skip-nav-target"
    :aria-hidden="isMobileMenuVisible"
  >
    <router-view v-slot="{ Component, route }">
      <transition name="fade" mode="out-in" appear>
        <component :is="Component" />
      </transition>
    </router-view>
  </main>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "RcSiteContent",
  props: ["contentClass"],

  computed: {
    ...mapState({
      isMobileMenuVisible: (state) => state.root.isMobileMenuVisible
    })
  }
};
</script>
