<template>
  <header
    role="banner"
    class="rc-header"
    :class="breadcrumbs && isAuthenticated ? 'rc-header-with-breadcrumbs' : ''"
  >
    <!-- Top Nav -->

    <nav
      v-if="isAuthenticated && profile.team_name"
      class="navbar rc-nav-top d-lg-block navbar-dark bg-dark navbar-expand"
      :aria-label="$t(`a11y.nav.utility`)"
      :aria-hidden="isMobileMenuVisible"
    >
      <div class="container">
        <ul class="navbar-nav">
          <li
            class="nav-item dropdown rc-team-trigger"
            v-if="memberships.length > 1"
          >
            <button
              id="rcBtnMenuTeams"
              class="btn btn-md btn-link nav-link dropdown-toggle dropdown-toggle-no-caret"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              aria-controls="rcListMenuTeams"
            >
              <span>{{ profile.team_name }}</span>
              <RcIcon name="chevronUpBold" scale=".55"></RcIcon>
              <RcIcon name="chevronDownBold" scale=".55"></RcIcon>
            </button>

            <ul
              id="rcListMenuTeams"
              class="dropdown-menu dropdown-menu-start"
              aria-labelledby="rcBtnMenuTeams"
            >
              <RcJoinedTeams />
            </ul>
          </li>
          <li v-else class="navbar-text">
            {{ profile.team_name }}
          </li>
          <li class="nav-item">
            <router-link :to="toAbout" class="nav-link">{{
              $t("nav.about_brand")
            }}</router-link>
          </li>
          <li class="nav-item">
            <a :href="$t('nav.url.help_desk')" class="nav-link">{{
              $t("nav.help_desk")
            }}</a>
          </li>
          <li class="nav-item">
            <RcLanguage />
          </li>

          <!-- Guest (btn sign in)-->
          <template v-if="roleIsGuest">
            <li class="nav-item">
              <router-link :to="toLoginRedirect" class="nav-link">{{
                $t(`auth.log.in`)
              }}</router-link>
            </li>
          </template>
        </ul>
      </div>
    </nav>

    <!-- Main Nav -->
    <nav
      class="navbar rc-main-navigation navbar-light navbar-expand-lg"
      :aria-label="$t(`a11y.nav.main`)"
    >
      <div class="container">
        <div
          class="rc-toggle-nav-mobile d-lg-none"
          :aria-hidden="isMobileMenuVisible"
        >
          <button
            class="navbar-toggler btn rc-btn-icon"
            data-bs-toggle="offcanvas"
            data-bs-target="#rcNavSidebarMobile"
            aria-controls="rcNavSidebarMobile"
          >
            <rc-icon
              name="menu"
              scale="1.6"
              :title="$t(`a11y.nav.main_open`)"
            ></rc-icon>
          </button>
        </div>

        <!--LEFT : Logo-->
        <div class="rc-nav-brand" :aria-hidden="isMobileMenuVisible">
           <router-link :to="toHome" class="nav-brand">
            <img
              src="/src/assets/img/logos/lg_curio.svg"
              :alt="$t(`nav.logo_go_to_home`)"
          /></router-link> 
         
        </div>
        <!--CENTER : Content/Subscription/About Curio (public) || Browse / Discover / Search (authentified)-->
        <div class="collapse navbar-collapse rc-nav-central">
          <!-- Authentified -->
          <ul v-if="isAuthenticated" class="navbar-nav mx-auto">
            <!-- desktop browse -->
            <li class="nav-item">
              <div class="btn-group rc-has-megamenu">
                <button
                  id="rcBtnMenuBrowse"
                  class="btn btn-md btn-white dropdown-toggle dropdown-toggle-no-caret"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  aria-controls="rcListMenuBrowse"
                >
                  <span
                    ><span>{{ $t("nav.browse") }}</span>
                    <rc-icon name="chevronUpBold" scale=".47"></rc-icon>
                    <rc-icon name="chevronDownBold" scale=".47"></rc-icon>
                  </span>
                </button>

                <ul
                  id="rcListMenuBrowse"
                  class="dropdown-menu w-100 rc-megamenu"
                  aria-labelledby="rcBtnMenuBrowse"
                >
                  <li role="presentation">
                    <ul class="row" role="presentation">
                      <!-- intro -->
                      <li
                        class="rc-megamenu--intro col-lg-4 col-xl-3 offset-xl-1"
                        role="presentation"
                      >
                        <h2>{{ $t("nav.browse_intro.title") }}</h2>
                        <p v-html="$t('nav.browse_intro.text')"></p>
                        <p @click="onLatestClick">
                          <template
                            v-if="canRecommend || canRecommendCategories"
                          >
                            <router-link
                              :to="toRecommendedDetail"
                              class="rc-link-arrow-right"
                              >{{ $t("common.recommended")
                              }}<rc-icon name="arrowRight" scale=".9"></rc-icon
                            ></router-link>
                          </template>
                          <template v-else>
                            <router-link
                              :to="toCatalogList"
                              class="rc-link-arrow-right"
                              >{{ $t("common.latest_additions")
                              }}<rc-icon name="arrowRight" scale=".9"></rc-icon
                            ></router-link>
                          </template>
                        </p>
                      </li>

                      <!-- audiences -->
                      <li class="col-lg-2" v-if="teamAgeGroups.data.length > 1">
                        <h3 class="h4">
                          <router-link :to="toAgeGroupList">
                            {{
                              $tc(
                                "catalog.audience_type." + getTeamTypeAudience,
                                2
                              )
                            }}
                          </router-link>
                        </h3>
                        <ul>
                          <li
                            v-for="group in teamAgeGroups.data"
                            :key="group.uid"
                          >
                            <router-link
                              :to="{
                                name: 'age.group.detail',
                                params: { uid: group.uid, locale: $i18n.locale }
                              }"
                              >{{ group.name }}</router-link
                            >
                          </li>
                        </ul>
                      </li>

                      <!-- categories -->

                      <li
                        :class="
                          teamAgeGroups.data.length > 1
                            ? 'col-lg-6 col-xl-5'
                            : 'col-lg-8 col-xl-7'
                        "
                      >
                        <h3 class="h4">
                          <router-link :to="toCategoriesList">{{
                            $t("common.categories")
                          }}</router-link>
                        </h3>
                        <ul class="rc-multi-columns rc-multi-columns--3">
                          <li
                            v-for="cat in categories.data.results"
                            :key="cat.uid"
                          >
                            <router-link
                              :to="{
                                name: 'catalog.category.detail',
                                params: {
                                  uid: cat.uid,
                                  locale: $i18n.locale
                                }
                              }"
                              >{{ cat.name }}</router-link
                            >
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <!-- desktop discover -->

            <li class="nav-item">
              <div class="btn-group rc-has-megamenu">
                <button
                  id="rcBtnMenuDiscover"
                  class="btn btn-md btn-white dropdown-toggle dropdown-toggle-no-caret"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  aria-controls="rcListMenuDiscover"
                >
                  <span
                    ><span>{{ $t("nav.discover") }}</span>
                    <rc-icon name="chevronUpBold" scale=".47"></rc-icon>
                    <rc-icon name="chevronDownBold" scale=".47"></rc-icon>
                  </span>
                </button>

                <ul
                  id="rcListMenuDiscover"
                  class="dropdown-menu w-100 rc-megamenu"
                  aria-labelledby="rcBtnMenuDiscover"
                >
                  <li role="presentation" class="container">
                    <ul class="row" role="presentation">
                      <!-- intro -->
                      <li
                        class="rc-megamenu--intro col-lg-4 col-xl-3 offset-xl-1"
                      >
                        <h2>{{ $t("nav.discover_intro.title") }}</h2>
                        <p v-html="$t('nav.discover_intro.text')"></p>
                        <p>
                          <router-link
                            :to="toFeaturedDetail"
                            class="rc-link-arrow-right"
                            >{{ $t("common.team_picks")
                            }}<rc-icon name="arrowRight" scale=".9"></rc-icon
                          ></router-link>
                        </p>
                      </li>

                      <!-- themes -->

                      <li
                        :class="
                          countChannels
                            ? 'col-lg-6 col-xl-5'
                            : 'col-lg-8 col-xl-7'
                        "
                      >
                        <h3 class="h4">
                          <router-link :to="toThemesList">{{
                            $t("common.themes")
                          }}</router-link>
                        </h3>
                        <ul class="rc-multi-columns rc-multi-columns--2">
                          <li v-for="theme in navThemes" :key="theme.uid">
                            <router-link
                              :to="{
                                name: 'catalog.themes.detail',
                                params: {
                                  uid: theme.uid,
                                  locale: $i18n.locale
                                }
                              }"
                              >{{ theme.name }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="toThemesList"
                              class="rc-link-secondary rc-text-underline"
                              >{{ $t("nav.all_themes") }}</router-link
                            >
                          </li>
                        </ul>
                      </li>

                      <!-- channels -->
                      <li class="col-lg-2" v-if="countChannels">
                        <h3 class="h4">
                          <router-link :to="toChannelsList">{{
                            $t("common.channels")
                          }}</router-link>
                        </h3>
                        <ul>
                          <li
                            v-for="channel in unlockChannels"
                            :key="channel.slug"
                          >
                            <router-link
                              :to="{
                                name: 'catalog.channels.detail',
                                params: {
                                  uid: channel.uid,
                                  locale: $i18n.locale
                                }
                              }"
                              >{{ channel.name }}</router-link
                            >
                          </li>
                          <li
                            v-for="channel in lockedChannels"
                            :key="channel.slug"
                          >
                            <router-link
                              :to="{
                                name: 'catalog.channels.subscribe.detail',
                                params: {
                                  uid: channel.uid,
                                  locale: $i18n.locale
                                }
                              }"
                              class="rc-link-lock"
                              ><rc-icon name="lock" scale=".9"></rc-icon
                              >{{ channel.name }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li v-if="$route.name == 'catalog.item.search'" class="nav-item">
              <a href="#" target="_self" class="nav-link" aria-current="page">
                <span> {{ $t(`common.search.title`) }}</span></a
              >
            </li>
            <!-- Search-->
            <li v-else class="nav-item rc-nav-item-search">
              <form @submit.stop.prevent role="search">
                <label for="input__search" class="d-block">{{
                  $t(`common.search.title`)
                }}</label>
                <div
                  :class="[
                    { 'rc-search-open': isSearchOpen },
                    'rc-search-wrapper'
                  ]"
                >
                  <input
                    v-model="auto"
                    v-bind="inputProps"
                    autocomplete="off"
                  />
                  <button
                    class="btn btn-link rc-btn-icon rc-btn-search"
                    @click="onSearch"
                  >
                    <RcIcon name="search" scale=".9"></RcIcon>
                    <span class="visually-hidden">{{
                      $t("common.search.cta")
                    }}</span>
                  </button>
                </div>
              </form>
            </li>
          </ul>

          <!-- Public -->
          <ul v-else class="navbar-nav mx-auto rc-nav-public">
            <li class="nav-item">
              <router-link :to="toMarketContent" class="nav-link"
                ><span>{{ $t("nav.content") }}</span></router-link
              >
            </li>
            <li class="nav-item">
              <router-link :to="toMarketSubscription" class="nav-link"
                ><span>{{ $t("nav.subscription") }}</span></router-link
              >
            </li>
            <li class="nav-item">
              <router-link :to="toAbout" class="nav-link"
                ><span>{{ $t("nav.about_brand") }}</span></router-link
              >
            </li>
          </ul>
        </div>

        <!--RIGHT : Btn User account || Btn Sign in || Language-->
        <ul class="navbar-nav rc-nav-right" :aria-hidden="isMobileMenuVisible">
          <template v-if="isAuthenticated">
            <!-- Guest (btn sign in)-->
            <template v-if="roleIsGuest">
              <!--KEY-->
              <!-- Question : Library Public (revoir les conditions) il me semble que ce n'est pas possible de créer un profil si library public.-->
              <RcAccessKey
                :expireAt="ipTokenExpAt || verifyTokenExpAt"
                v-if="allowRemoteAccess"
              />

              <!--SIGN IN-->
              <!--desktop-->
              <li class="nav-item" v-if="!orgIsPublic">
                <router-link
                  :to="toMarketCreateProfile"
                  class="nav-link d-none d-lg-inline"
                  :class="cssClassLogin"
                  >{{ $t(`nav.create_profile`) }}</router-link
                >
              </li>

              <!--mobile (***ms-auto***)-->

              <li class="nav-item dropdown rc-menu-user d-lg-none">
                <button
                  id="rcBtnMenuAccountGuest"
                  class="btn btn-md btn-link rc-btn-icon-dd nav-link dropdown-toggle dropdown-toggle-no-caret"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  :title="$t('nav.my_account')"
                  aria-controls="rcMenuAccountGuest"
                >
                  <div class="rc-btn-dropdown-my-account">
                    <span class="visually-hidden">{{
                      $t("nav.my_account")
                    }}</span>
                    <rc-icon name="userCircle" scale="1.53"> </rc-icon>
                  </div>
                </button>

                <ul
                  id="rcMenuAccountGuest"
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="rcBtnMenuAccountGuest"
                >
                  <li v-if="!orgIsPublic">
                    <router-link
                      :to="toMarketCreateProfile"
                      class="dropdown-item btn-light btn"
                    >
                      <span>{{ $t("nav.create_profile") }}</span></router-link
                    >
                  </li>
                  <li role="presentation">
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <router-link :to="toLoginRedirect" class="dropdown-item">
                      <span class="rc-item-icon-text">{{
                        $t("auth.log.in")
                      }}</span
                      ><rc-icon name="logIn" scale="1"></rc-icon
                    ></router-link>
                  </li>
                </ul>
              </li>
            </template>

            <!-- Signed In (user account) -->
            <template v-else>
              <li class="nav-item dropdown ms-auto rc-menu-user">
                <button
                  class="btn btn-md btn-link rc-btn-icon-dd nav-link dropdown-toggle dropdown-toggle-no-caret"
                  id="rcBtnMenuAccount"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  aria-controls="rcMenuAccount"
                  :title="$t('nav.my_account')"
                >
                  <div class="rc-btn-dropdown-my-account">
                    <span class="visually-hidden">{{
                      $t("nav.my_account")
                    }}</span>
                    <rc-icon name="userCircle" scale="1.53"> </rc-icon>
                  </div>
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  id="rcMenuAccount"
                  aria-labelledby="rcBtnMenuAccount"
                >
                  <li v-if="profile.user_uid">
                    <router-link
                      :to="{
                        name: 'user.favorites',
                        params: { locale: $i18n.locale }
                      }"
                      class="dropdown-item"
                    >
                      <span class="rc-item-icon-text"
                        ><rc-icon name="bookmarkSmall" scale="1.125"></rc-icon
                        >{{ $t("catalog.favorites.my") }}</span
                      ></router-link
                    >
                  </li>
                  <li v-if="roleIsTeacher || roleIsLibraryUser || roleIsAdmin">
                    <router-link
                      :to="{
                        name: 'user.playlists',
                        params: { locale: $i18n.locale }
                      }"
                      class="dropdown-item"
                    >
                      <span class="rc-item-icon-text"
                        ><rc-icon name="playlist" scale="1"></rc-icon
                        >{{ $t("catalog.playlists_my") }}</span
                      ></router-link
                    >
                  </li>

                  <li role="presentation">
                    <hr class="dropdown-divider" />
                  </li>

                  <li v-if="profile.user_uid">
                    <router-link
                      :to="{
                        name: 'user.account.summary',
                        params: { locale: $i18n.locale }
                      }"
                      class="dropdown-item"
                    >
                      {{ $t("nav.my_profile") }}</router-link
                    >
                  </li>

                  <li v-if="roleIsAdmin || roleIsLibraryUser">
                    <router-link :to="toReports" class="dropdown-item">
                      <span v-if="roleIsAdmin">{{ $t("common.reports") }}</span>
                      <span v-else>{{
                        $t("catalog.metadata")
                      }}</span></router-link
                    >
                  </li>

                  <li role="presentation">
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <button
                      type="button"
                      class="dropdown-item"
                      @click="logoutHandler()"
                    >
                      <span class="rc-item-icon-text">{{
                        $t("auth.log.out")
                      }}</span
                      ><rc-icon name="logOut" scale="1"></rc-icon>
                    </button>
                  </li>
                </ul>
              </li>
            </template>
          </template>
          <template v-else>
            <!-- Language-->

            <li class="nav-item d-none d-lg-inline">
              <RcLanguage />
            </li>
            <!--KEY only when expired-->
            <RcAccessKey v-if="verifyTokenStatus == 'error'" />

            <li class="nav-item" v-else>
              <router-link
                :to="toLoginRedirect"
                class="nav-link"
                :class="cssClassLogin"
                >{{ $t(`auth.log.in`) }}</router-link
              >
            </li>
          </template>
        </ul>

        <!-- Menu mobile-->
        <RcOffcanvas
          id="rcNavSidebarMobile"
          @shown="onSidebarShown()"
          @hidden="onSidebarHidden()"
          hideHeader
          class="d-lg-none"
        >
          <template #offcanvas-body="{ hide }">
            <span id="rcNavSidebarMobile_label" class="visually-hidden"
              >Navigation</span
            >
            <div class="rc-header-sidebar-mobile">
              <button
                class="btn btn-link rc-btn-icon"
                data-bs-dismiss="offcanvas"
              >
                <rc-icon
                  name="close"
                  :title="$t(`a11y.nav.main_close`)"
                ></rc-icon>
              </button>
              <div class="rc-lang-mobile">
                <RcLanguage />
              </div>
            </div>

            <ul class="navbar-nav">
              <template v-if="isAuthenticated">
                <!-- Search Mobile-->

                <li
                  v-if="$route.name == 'catalog.item.search'"
                  class="nav-item"
                  data-bs-dismiss="offcanvas"
                >
                  <a
                    href="#"
                    target="_self"
                    class="nav-link"
                    aria-current="page"
                  >
                    <span> {{ $t(`common.search.title`) }}</span></a
                  >
                </li>
                <form v-else @submit.stop.prevent role="search">
                  <label for="input__search_mobile" class="d-block mb-2">{{
                    $t(`common.search.title`)
                  }}</label>
                  <div class="rc-search-wrapper-mobile">
                    <input
                      v-model="auto"
                      v-bind="inputPropsMobile"
                      autocomplete="off"
                    />

                    <button
                      class="btn btn-link rc-btn-icon rc-btn-search"
                      @click="onSearch(hide)"
                    >
                      <span class="visually-hidden">{{
                        $t("common.search.cta")
                      }}</span>
                      <RcIcon name="search" scale=".9"></RcIcon>
                    </button>
                  </div>
                </form>
                <!-- Home -->
                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="toHome" class="nav-link">{{
                    $t("nav.home")
                  }}</router-link>
                </li>

                <li>
                  <div class="accordion accordion-flush" id="accordionMenu">
                    <!-- Browse -->
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#rcMenuBrowse"
                          aria-expanded="false"
                          aria-controls="rcMenuBrowse"
                        >
                          <span>{{ $t("nav.browse") }}</span>
                        </button>
                      </h2>
                      <div
                        id="rcMenuBrowse"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionMenu"
                      >
                        <div class="accordion-body">
                          <ul class="navbar-nav">
                            <li
                              v-if="canRecommend || canRecommendCategories"
                              class="nav-item"
                              @click="onLatestClick(hide)"
                            >
                              <router-link :to="toRecommendedDetail">
                                {{ $t("common.recommended") }}
                              </router-link>
                            </li>
                            <li
                              v-else
                              class="nav-item"
                              @click="onLatestClick(hide)"
                            >
                              <router-link :to="toCatalogList">
                                {{ $t("common.latest_additions") }}
                              </router-link>
                            </li>

                            <li
                              v-if="teamAgeGroups.data.length > 1"
                              class="nav-item"
                              data-bs-dismiss="offcanvas"
                            >
                              <router-link :to="toAgeGroupList">
                                {{
                                  $tc(
                                    "catalog.audience_type." +
                                      getTeamTypeAudience,
                                    2
                                  )
                                }}
                              </router-link>
                            </li>
                            <li class="nav-item" data-bs-dismiss="offcanvas">
                              <router-link :to="toCategoriesList">
                                {{ $t("common.categories") }}
                              </router-link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Discover -->
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#rcMenuDiscover"
                          aria-expanded="false"
                          aria-controls="rcMenuDiscover"
                        >
                          {{ $t("nav.discover") }}
                        </button>
                      </h2>
                      <div
                        id="rcMenuDiscover"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionMenu"
                      >
                        <div class="accordion-body">
                          <ul class="navbar-nav">
                            <li class="nav-item" data-bs-dismiss="offcanvas">
                              <router-link :to="toFeaturedDetail">
                                {{ $t("common.team_picks") }}
                              </router-link>
                            </li>

                            <li class="nav-item" data-bs-dismiss="offcanvas">
                              <router-link :to="toThemesList">
                                {{ $t("common.themes") }}
                              </router-link>
                            </li>

                            <li
                              v-if="countChannels"
                              class="nav-item"
                              data-bs-dismiss="offcanvas"
                            >
                              <router-link :to="toChannelsList">
                                {{ $t("common.channels") }}
                              </router-link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </template>

              <template v-else>
                <!-- Home -->
                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="toHome" class="nav-link">{{
                    $t("nav.home")
                  }}</router-link>
                </li>

                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="toMarketContent" class="nav-link">{{
                    $t("nav.content")
                  }}</router-link>
                </li>

                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="toMarketSubscription" class="nav-link">{{
                    $t("nav.subscription")
                  }}</router-link>
                </li>

                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="toAbout" class="nav-link">{{
                    $t("nav.about_brand")
                  }}</router-link>
                </li>
              </template>
            </ul>
            <ul class="navbar-nav rc-nav-utility-links">
              <li class="nav-item">
                <a :href="$t('nav.url.help_desk')" class="nav-link">{{
                  $t("nav.help_desk")
                }}</a>
              </li>
              <li
                v-if="isAuthenticated"
                class="nav-item"
                data-bs-dismiss="offcanvas"
              >
                <router-link :to="toAbout" class="nav-link">{{
                  $t("nav.about_brand")
                }}</router-link>
              </li>
            </ul>
          </template></RcOffcanvas
        >
      </div>
    </nav>

    <!-- Back and Breadcrumb -->
    <div
      v-if="breadcrumbs && isAuthenticated"
      class="container rc-back-breadcrumb"
      :class="breadcrumbs.class || 'light'"
      :aria-hidden="isMobileMenuVisible"
    >
      <button
        v-if="breadcrumbs.back"
        @click="$router.go(-1)"
        class="btn btn-link btn-md rc-back"
      >
        <rc-icon name="arrowLeft" scale=".7" :title="$t(`nav.back`)"></rc-icon>
        <span class="d-none d-lg-inline" aria-hidden="true">{{
          $t("nav.back")
        }}</span>
      </button>

      <nav v-if="crumbs" :aria-label="$t(`a11y.nav.breadcrumb`)">
        <ol class="breadcrumb">
          <li
            v-for="(crumb, index) in crumbs"
            class="breadcrumb-item"
            :key="index"
            :class="crumb.active ? 'active' : ''"
          >
            <template v-if="crumb.to || crumb.fnTo">
              <router-link
                :to="crumb.fnTo ? crumb.fnTo($route) : crumb.to"
                :class="crumb.disabled ? 'disabled' : ''"
              >
                {{ crumb.text($route, $store) }}
              </router-link></template
            >
            <template v-else>
              <span :aria-current="crumb.active ? 'location' : null">
                {{ crumb.text($route, $store) }}
              </span>
            </template>
          </li>
        </ol>
      </nav>
    </div>

    <!-- To avoid a jump when we change to a page without breadcrumb -->

    <div v-else class="mb-0 invisible" style="height: 62.5px"></div>
  </header>
</template>

<script>
import config from "#/config";
import moment from "moment";

import { ROOT_SHOW_MOBILE_MENU, ROOT_HIDE_MOBILE_MENU } from "#/stores/root";

import { mapState, mapGetters } from "vuex";
import { debounce } from "lodash";

import { navMixin } from "#/routes/mixins";

import RcLanguage from "@/libvue/components/i18n/LocaleSwitch.vue";
import RcIcon from "@/libvue/components/icons/Base.vue";
import RcJoinedTeams from "@/libvue/apps/team/components/JoinedTeams.vue";
import RcAccessKey from "#/pages/auth/components/accessKey.vue";
import RcOffcanvas from "@/libvue/components/offcanvas/Offcanvas.vue";

import {
  ITEM_SET_FILTER_PARAMS,
  ITEM_GET_LIST,
  SET_AUTOCOMPLETE_SELECTED,
  SET_AUTOCOMPLETE_QUERY,
  ITEM_SET_USER_FILTER
} from "@/libvue/apps/catalog/items/actions";

import { AUTH_LOGOUT } from "@/libvue/apps/auth/actions";

export default {
  name: "RcSiteHeader",
  components: {
    RcLanguage,
    RcIcon,
    RcJoinedTeams,
    RcAccessKey,
    RcOffcanvas
  },

  data() {
    return {
      now: moment().format(),
      searchQ: null,
      inputSearchVisible: false,
      config: config,
      isSearchOpen: false
    };
  },

  mixins: [navMixin],

  computed: {
    ...mapGetters([
      "isAuthenticated",
      "getFilterParams",
      "getTeamTypeAudience",
      "roleIsTeacher",
      "roleIsLibraryUser",
      "roleIsAdmin",
      "roleIsGuest",
      "orgIsPublic",
      "canRecommend",
      "canRecommendCategories"
    ]),
    ...mapState({
      categories: (state) => state.categories.list,
      teamAgeGroups: (state) => state.items.teamAgeGroups,
      memberships: (state) => state.team.profile.memberships,
      ipToken: (state) => state.auth.ipToken.data,
      verifyToken: (state) => state.auth.verify,
      profile: (state) => state.auth.user,

      // curricula: (state) => state.courses.curriculas.data,
      autoQuery: (state) => state.items.auto.q,
      autoSuggest: (state) => state.items.auto.data,
      channels: (state) => state.items.channels.data.results,
      themes: (state) => state.items.themes.data.results,
      navThemes: (state) => state.lineups.nav.data.lineup_themes,

      isMobileMenuVisible: (state) => state.root.isMobileMenuVisible
    }),
    breadcrumbs() {
      return this.$route.meta.breadcrumb;
    },
    crumbs() {
      return this.breadcrumbs.crumbs.filter((crumb) => {
        if (crumb.text(this.$route, this.$store)) {
          return crumb;
        }
      });
    },
    pageName() {
      return this.$route.name;
    },
    cssClassLogin() {
      if (this.pageName?.includes("home") || this.roleIsGuest) {
        return "btn-light btn rc-btn-sign-in";
      } else {
        return "btn-primary btn rc-btn-sign-in";
      }
    },
    allowRemoteAccess() {
      return (
        this.ipToken.auth.remote_key_access ||
        this.verifyToken.data.auth.remote_key_access
      );
    },
    ipTokenExpAt() {
      return this.ipToken.expire_at;
    },
    ipTokenIsExp() {
      return moment(this.expireAt).isBefore(this.now);
    },
    verifyTokenStatus() {
      return this.verifyToken.status;
    },
    verifyTokenExpAt() {
      return this.verifyToken.data.expire_at;
    },
    auto: {
      get() {
        return this.autoQuery;
      },
      set: debounce(function (value) {
        this.$store.commit(SET_AUTOCOMPLETE_QUERY, value);
        //If autosuggest comes back
        //this.$store.dispatch(GET_ITEMS_AUTOCOMPLETE, { q: value });
      }, 0)
    },
    searchQuery: {
      get() {
        return this.searchQ;
      },
      set(value) {
        let params = value
          ? { reset: true, fields: { query: value, ordering: null } }
          : { reset: true };
        this.$store.commit(ITEM_SET_FILTER_PARAMS, params);

        localStorage.setItem(
          "basic-list-queries",
          JSON.stringify([
            {
              index: "0",
              field: "*",
              value,
              operator: null
            }
          ])
        );

        if (value && this.$route.name !== "catalog.item.search") {
          this.$router.push({
            name: "catalog.item.search",
            query: { q: value },
            params: { locale: this.$i18n.locale }
          });
        } else if (!value && this.$route.name == "catalog.item.search") {
          this.$router.push(this.toCatalogList);
        } else if (value && this.$route.name == "catalog.item.search") {
          this.$router.push({
            name: "catalog.item.search",
            query: { q: value },
            params: { locale: this.$i18n.locale }
          });
        }
      }
    },
    lockedChannels() {
      return this.roleIsTeacher
        ? this.channels.filter((chan) => chan.is_locked)
        : {};
    },
    unlockChannels() {
      return this.channels.filter((chan) => !chan.is_locked);
    },
    countChannels() {
      return this.roleIsTeacher
        ? this.unlockChannels.length + this.lockedChannels.length
        : this.unlockChannels.length;
    },
    toLoginRedirect() {
      let _toLogin = this.toLogin;

      if (this.$route.meta?.redirectAfterLogin) {
        _toLogin = {
          ...this.toLogin,
          query: { redirect: this.$route.fullPath }
        };
      }

      return _toLogin;
    },
    inputProps() {
      return {
        id: "input__search",
        class: "form-control form-control-lg rc-input-search",
        name: "search",
        type: "search",
        spellcheck: "false",
        placeholder: this.$t("common.search.placeholder"),
        autocomplete: "false"
      };
    },
    inputPropsMobile() {
      return {
        id: "input__search_mobile",
        class: "form-control form-control-lg rc-input-search",
        name: "search",
        type: "search",
        spellcheck: "false",
        placeholder: this.$t("common.search.placeholder"),
        autocomplete: "false"
      };
    }
  },
  methods: {
    onLatestClick(hide) {
      if (hide && typeof hide === "function") hide();
      if (this.$route.name == "catalog.item.list") {
        this.$store.commit(ITEM_SET_USER_FILTER, { reset: true });
        this.$store.commit(ITEM_SET_FILTER_PARAMS, { reset: true });
        this.$store.dispatch(ITEM_GET_LIST);
      }
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.text;
    },

    onSearch(hide) {
      if (this.auto && typeof hide === "function") hide();
      this.searchQuery = this.auto;
      this.isSearchOpen = false;
    },

    logoutHandler() {
      this.$store.dispatch(AUTH_LOGOUT);
    },
    //For accessibility ios mobile with Voice Over
    //Block reading into sidebar
    onSidebarShown() {
      this.$store.commit(ROOT_SHOW_MOBILE_MENU);
      //Hide Beacon
      document.getElementById("beacon-container").style.display = "none";
    },
    onSidebarHidden() {
      this.$store.commit(ROOT_HIDE_MOBILE_MENU);
      //Hide Beacon
      document.getElementById("beacon-container").style.display = "block";
    }
  }
};
</script>
