<template>
  <div
    :id="id"
    :class="`offcanvas offcanvas-${placement}`"
    tabindex="-1"
    :aria-labelledby="`${id}_label`"
  >
    <div v-if="!hideHeader" class="offcanvas-header">
      <h5 class="offcanvas-title" :id="`${id}_label`">
        <slot name="offcanvas-title">title</slot>
      </h5>

      <button class="btn btn-link rc-btn-icon" data-bs-dismiss="offcanvas">
        <rc-icon name="close" :title="$t(`common.close`)"></rc-icon>
      </button>
    </div>
    <div class="offcanvas-body">
      <slot name="offcanvas-body" :hide="hide">Body</slot>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Offcanvas } from "bootstrap";
import RcIcon from "@/libvue/components/icons/Base.vue";

const props = defineProps({
  id: {
    type: String,
    default: "",
    required: true
  },
  placement: {
    type: String,
    required: false,
    default: "start"
  },
  hideHeader: {
    type: Boolean,
    required: false,
    default: false
  }
});

const offcanvas = ref(null);
const emit = defineEmits(["show", "shown", "hide", "hidden", "init"]);

onMounted(() => {
  const id = `#${props.id}`;
  offcanvas.value = new Offcanvas(id, {});
  const el = offcanvas.value._element;

  emit("init", offcanvas.value);

  el.addEventListener("show.bs.offcanvas", (event) => {
    emit("show", event, offcanvas.value);
  });
  el.addEventListener("shown.bs.offcanvas", (event) => {
    emit("shown", event, offcanvas.value);
  });

  el.addEventListener("hide.bs.offcanvas", (event) => {
    emit("hide", event, offcanvas.value);
  });

  el.addEventListener("hidden.bs.offcanvas", (event) => {
    emit("hidden", event, offcanvas.value);
  });
});

function show() {
  offcanvas.value.show();
}
function hide() {
  offcanvas.value.hide();
}
</script>
