<template>
  <div
    class="app-progress bg-transparent progress rounded-0"
    style="height: 4px"
  >
    <div
      class="progress-bar bg-danger"
      role="progressbar"
      aria-label="Default striped example"
      :style="styles"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</template>

<script>
export default {
  name: "RcAppProgess",
  props: {
    progress: {
      type: Number,
      default: 0
    }
  },
  computed: {
    styles() {
      return `width : ${this.progress}%`;
    }
  }
};
</script>

<style lang="scss" scoped>
.app-progress {
  z-index: 1400 !important;
  position: fixed;
  top: 0;
  width: 100%;

  &.progress {
    --bs-progress-bar-transition: width 0.2s ease-in;
  }
}
</style>
