import { getScreenProfile } from './getScreenProfile';
import { getWindowProfile } from './getWindowProfile';

export function getBrowserProfile() {
  return {
    cookies: 'undefined' !== typeof navigator.cookieEnabled ? navigator.cookieEnabled : false,
    codeName: navigator.appCodeName,
    description: getDocumentDescription(),
    language: navigator.language,
    name: navigator.appName,
    online: navigator.onLine,
    platform: navigator.platform,
    useragent: navigator.userAgent,
    version: navigator.appVersion,
    doNotTrack: navigator.doNotTrack,
    screen: getScreenProfile(),
    window: getWindowProfile()
  };
}

function getDocumentDescription() {
  var el;
  if (document && typeof document.querySelector === 'function') {
    el = document.querySelector('meta[name="description"]');
  }
  return el ? el.content : '';
}
